import {useAuth} from "@hooks/AuthHook";
import {App, ConfigProvider, Spin} from "antd";
import React from "react";
import HLayout from "@app/layout/ria/HLayout";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";


const AppRia = ({}) => {
    const {user} = useAuth();

    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    return <Spin spinning={null == user}>
        <ConfigProvider
            theme={{
                token: {
                    colorText: '#4b4b4b',
                    colorPrimary: '#3F8DFA',
                    // colorWarning: '#e0ad60',
                    fontSizeHeading1: 48,
                    fontFamily: 'Red Hat Display W, Helvetica, Arial, sans-serif',
                    fontSize: 16,
                    fontWeightStrong: 450,
                    colorBgBase: '#ffffff',
                    headerBg: 'white',
                    fontSizeXS: 12,
                    colorBgGrey: "rgb(248, 248, 248)",
                    colorBgGreyDark: "rgb(240, 240, 240)",
                    colorBgHoverBlue: "#e6f7ff",
                    colorPrimaryGrey: "rgb(100, 100, 100)",
                    colorPrimaryHoverGrey: "rgb(140, 140, 140)",
                    colorStats: '#e0ad60',
                    colorRadioSelectedBg: '#e0ad60',
                },
                components: {
                    "Typography": {
                        "fontWeightStrong": 450
                    },
                    "Menu": {
                        // fontFamily: 'Montserrat',
                        // fontSize: 14,
                        // fontWeight: 800
                    },
                    "Carousel": {
                        arrowOffset: 0,
                        arrowSize: 24
                    }
                }
            }}>
            <App className={'holistico'}>
                {user ? <HLayout/> : <div style={{height: 600}}></div>}
            </App>
        </ConfigProvider>
    </Spin>
}

export default AppRia

import React from "react";
import {Flex, theme, Tooltip, Typography} from "antd";
import {
    CheckCircleFilled,
    ExclamationCircleFilled,
} from "@ant-design/icons";


const {useToken} = theme;


const RiskResult = ({
                        title,
                        description,
                        success,
                        tooltip = null,
                        children,
                        ...props
                    }) => {
    const {token} = useToken();

    return <div {...props}>
        <Tooltip title={tooltip}>
            <Flex gap={"large"}>
                {success
                    ?
                    <CheckCircleFilled style={{
                        fontSize: 72,
                        color: token.colorStats,
                    }}/>
                    :
                    <ExclamationCircleFilled
                        style={{
                            fontSize: 72,
                            color: "rgba(0,0,0,0.2)"
                        }}/>
                }
                <Flex vertical align={"flex-start"}>
                    <Typography.Text style={{
                        fontSize: token.fontSizeHeading3,
                        lineHeight: 1.2,
                        marginTop: token.marginSM
                    }}>
                        {title}
                    </Typography.Text>
                    <Typography.Text type={"secondary"} style={{
                        marginBottom: token.margin
                    }}>
                        {description}
                    </Typography.Text>
                    {children}
                </Flex>
            </Flex>
        </Tooltip>
    </div>
}

export default RiskResult;

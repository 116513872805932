import React from 'react';
import {Progress} from 'antd';
import ChartStyles from "@global/ChartStyles";


const StatusProgress = ({value, status, steps=10}) => {
    return (
        <Progress
            percent={value}
            steps={steps}
            size="small"
            strokeColor={ChartStyles.get_status_color(status)}
            showInfo={false} status={status}
        />
    );
}

export default StatusProgress;

import React, {useRef, useState} from "react";
import {useSize} from "ahooks";
import {
    Button,
    Card,
    Flex,
    Typography,
    theme,
    Spin,
} from "antd";
import {
    DownOutlined,
    ExperimentFilled,
    MessageOutlined,
    UpOutlined
} from "@ant-design/icons";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import AssetClassPie from "@components/screener/chart/AssetClassPie";
import RatingRadar from "@components/screener/chart/RatingRadar";
import SectorBar from "@components/screener/chart/SectorBar";
import SizeStyleHeat from "@components/screener/chart/SizeStyleHeat";
import CreditRatingBar from "@components/screener/chart/CreditRatingBar";
import MaturityDate from "@components/screener/chart/MaturityDate";
import FISectorBar from "@components/screener/chart/FISectorBar";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import CardHeader from "@components/retail/cards/CardHeader";


const {useToken} = theme;


export const AIComment = ({comment}) => {
    try {
        if (typeof (comment) == 'string') {
            return comment.split('\n').map((par, index) => {
                return <Typography.Paragraph key={index} style={{maxWidth: 600}}>
                    {par}
                </Typography.Paragraph>
            })
        } else if (typeof (comment) == 'object') {
            return Object.keys(comment).map((name, index) => {
                return <Typography.Paragraph key={index} style={{maxWidth: 600}}>
                    <b>{name}</b>: {comment[name]}
                </Typography.Paragraph>
            })
        }
    } catch (e) {
        return "No data."
    }
}


const ChartCard = ({
                       chart,
                       title,
                       aiComment = null,
                       aiCommentLoading = false,
                       vertical = false,
                       children
                   }) => {
    const {token} = useToken();

    return <Flex
        vertical={vertical}
        style={{
            background: "rgba(255, 255, 255, 0)",
            borderTop: "1px rgba(0, 0, 0, 0.1) solid",
            paddingTop: token.padding,
            paddingBottom: token.padding,
        }}
    >
        <div style={{width: 450, maxWidth: 450, minWidth: 450}}>
            {chart}
        </div>
        <div style={{
            marginLeft: vertical ? 0 : 36,
            flexGrow: 1,
            paddingTop: vertical ? token.padding : 0,
        }}>
            <Typography.Title level={5} style={{margin: "0 0 12px 0"}}>
                {title}
            </Typography.Title>
            {aiComment
                &&
                <>
                    <Spin spinning={aiCommentLoading}>
                        <AIComment comment={aiComment}/>
                    </Spin>
                    <div style={{
                        width: "100%",
                        textAlign: "right",
                        fontStyle: "italic",
                        color: token.colorTextDescription
                    }}>
                        — AI comment
                    </div>
                </>
            }
            {children}
        </div>
    </Flex>
}


const AnalyticsCard = ({setAiExpand}) => {
    const {
        proposal,
        loadingAiAnalyticalComments,
        aiAnalyticalComments
    } = useHolisticoProposalContext();
    const ref = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const size = useSize(ref);
    const {token} = useToken();
    const portfolios = proposal?.current_portfolios;
    const cv = proposal?.p_bucket_cur?.calculated_values;
    const vertical = size && size?.width < 800;

    return <Card
        hoverable
        ref={ref}
        style={{
            background: vertical ? "white" : token.colorBgGrey,
            borderColor: "rgb(220, 220, 220)",
            overflow: "hidden",
            cursor: "default"
        }}
    >
        {!vertical
            &&
            <div style={{
                position: "absolute",
                height: "100%",
                width: "calc(100% - 462px - 26px)",
                background: token.colorBgContainer,
                right: 0,
                top: 0,
                borderTopRightRadius: token.borderRadiusLG - 1,
                borderBottomRightRadius: token.borderRadiusLG - 1,
            }}/>
        }
        <Flex
            justify={"space-between"}
            align={"baseline"}
            style={{marginBottom: 12, position: "relative"}}
        >
            <CardHeader title="Analytics" icon={<ExperimentFilled/>}/>
            <Button
                onClick={() => setAiExpand(true)}
                disabled={!portfolios || portfolios.length === 0}
                icon={<MessageOutlined/>}
            >
                Discuss with AI
            </Button>
        </Flex>
        {portfolios && portfolios.length > 0
            ?
            <Flex vertical gap={"middle"} style={{position: "relative"}}>
                <ChartCard
                    title={"Asset allocation"}
                    chart={<AssetClassPie allocation={cv.asset_class}/>}
                    aiComment={aiAnalyticalComments?.asset_class ?? "No asset class information"}
                    aiCommentLoading={loadingAiAnalyticalComments}
                    vertical={vertical}
                />
                <ChartCard
                    title={"Ratings"}
                    chart={<RatingRadar
                        asset={cv?.score_markers && cv?.score_markers.reduce((obj, score) => ({
                            ...obj,
                            [score.label]: score.value
                        }), {})}
                    />}
                    aiComment={aiAnalyticalComments?.score_markers ?? "No ratings information"}
                    aiCommentLoading={loadingAiAnalyticalComments}
                    vertical={vertical}
                />
                <ChartCard
                    title={"Sector"}
                    chart={<SectorBar allocation={cv?.sector}/>}
                    aiComment={aiAnalyticalComments?.sector ?? "No sector information"}
                    aiCommentLoading={loadingAiAnalyticalComments}
                    vertical={vertical}
                />
                {expanded
                    &&
                    <ChartCard
                        title={"Size & style"}
                        chart={<SizeStyleHeat allocation={cv?.size_style}/>}
                        aiComment={aiAnalyticalComments?.size || aiAnalyticalComments?.style
                            ?
                            (aiAnalyticalComments?.size ? aiAnalyticalComments?.size + "\n" : "")
                            + (aiAnalyticalComments?.style ?? "")
                            :
                            "No size-style information"
                        }
                        aiCommentLoading={loadingAiAnalyticalComments}
                        vertical={vertical}
                    />
                }
                {expanded
                    &&
                    <ChartCard
                        title={"Fixed-income sector"}
                        chart={<FISectorBar allocation={cv?.fi_super_sector}/>}
                        aiComment={aiAnalyticalComments?.fi_super_sector
                            ?? "No fixed-income sector information"}
                        aiCommentLoading={loadingAiAnalyticalComments}
                        vertical={vertical}
                    />
                }
                {expanded
                    &&
                    <ChartCard
                        title={"Fixed-income credit rating"}
                        chart={<CreditRatingBar
                            allocation={cv?.fi_credit_rating}/>}
                        aiComment={aiAnalyticalComments?.fi_credit_rating
                            ?? "No credit rating information"}
                        aiCommentLoading={loadingAiAnalyticalComments}
                        vertical={vertical}
                    />
                }
                {expanded
                    &&
                    <ChartCard
                        title={"Fixed-income maturity"}
                        chart={<MaturityDate
                            allocation={cv?.fi_effective_maturity}/>}
                        aiComment={aiAnalyticalComments?.fi_effective_maturity
                            ?? "No maturity date information"}
                        aiCommentLoading={loadingAiAnalyticalComments}
                        vertical={vertical}
                    />
                }

                <div style={{
                    borderTop: "1px rgba(0, 0, 0, 0.1) solid",
                    paddingTop: token.padding,
                }}>
                    <Button
                        onClick={() => setExpanded(prev => !prev)}
                        type={"link"}
                        size={"small"}
                        icon={expanded ? <UpOutlined/> : <DownOutlined/>}
                        style={{
                            paddingLeft: 0,
                            // marginTop: token.padding
                            marginLeft: vertical ? 0 : 486
                        }}
                    >
                        {expanded ? "Show less" : "Show all"}
                    </Button>
                </div>
            </Flex>
            :
            <div style={{
                width: 450,
                maxWidth: 450,
                minWidth: 450
            }}>
                <NoDataPlaceholder description={"No data for analytics"}/>
            </div>
        }
    </Card>
}


export default AnalyticsCard;

import React, {useRef, useState} from "react";
import {useSize} from "ahooks";
import {Card, Divider, theme, Typography} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import CreditRatingChart from "@components/holistico/chart/CreditRatingChart";
import FISectorPie from "@components/holistico/chart/FISectorPie";
import MaturityDateBar from "@components/holistico/chart/MaturityDateBar";
import CardHeader from "@components/retail/cards/CardHeader";
import HRadioGroup from "@holistico/aux/HRadioGroup";
import  {FundamentalsItemJoint} from "@holistico/proposal/view/fundamentals/FundamentalsItem";


const { Paragraph} = Typography;
const {useToken} = theme;


export const creditRatingDescription = <>
    <Paragraph>
        The credit rating of bonds is of utmost importance for several reasons:
    </Paragraph>
    <Paragraph>
        1. Assessment of Default Risk: Credit ratings provide an indication of
        the risk associated with the potential default of the bond. Higher-rated
        bonds (AAA, AA) are perceived to have a lower risk of default, whereas
        lower-rated bonds (B, BB, etc.) carry a higher risk.
    </Paragraph>
    <Paragraph>
        2. Influence on Interest Rates: The credit rating of a bond is directly
        correlated with the interest rate it offers. Higher-rated bonds
        typically have lower interest rates as their perceived default risk is
        lower. Conversely, lower-rated bonds need to offer higher interest rates
        to attract investors due to their higher risk.
    </Paragraph>
    <Paragraph>
        3. Investment Strategy Formulation: Credit ratings are a crucial tool
        for investors when formulating their investment strategies. Conservative
        investors might prefer higher-rated bonds due to their lower risk,
        whereas more aggressive investors might be willing to take on the higher
        risk associated with lower-rated bonds for potentially higher returns.
    </Paragraph>
    <Paragraph>
        4. Portfolio Diversification: Having bonds with different credit ratings
        can help diversify a portfolio and balance risk and return.
    </Paragraph>
    <Paragraph>
        5. Regulatory Requirements: Certain institutional investors such as
        pension funds or insurance companies are often required to hold a
        certain percentage of their portfolios in high-grade bonds, further
        highlighting the importance of credit ratings.
    </Paragraph>
    <Paragraph>
        In essence, bond credit ratings serve as an indispensable guide in the
        bond market, helping investors understand the creditworthiness of the
        bond issuer and make informed investment decisions.
    </Paragraph>
    <Paragraph>
        * Fixed-income credit rating is available only for ETFs and mutual
        funds.
    </Paragraph>
</>


export const fiSectorDescription = <>
    <Paragraph>
        Fixed-income sector allocation plays a pivotal role in portfolio
        management, and its significance is multifold:
    </Paragraph>
    <Paragraph>
        1. Diversification: By spreading investments across various sectors such
        as government, corporate, or municipal bonds, investors can achieve a
        level of diversification, thereby mitigating sector-specific risks.
    </Paragraph>
    <Paragraph>
        2. Yield and Risk Profile: Different fixed-income sectors offer
        different yields and carry different risk profiles. For instance,
        government bonds are generally deemed safer with lower yields, while
        corporate bonds tend to offer higher yields but come with higher risk.
    </Paragraph>
    <Paragraph>
        3. Economic Cycle Adaptability: Fixed-income sectors react differently
        to economic cycles. For example, corporate bonds might perform well
        during economic expansions, while government bonds can provide stability
        during economic downturns. Having a diverse sector allocation allows the
        portfolio to adapt to different economic conditions.
    </Paragraph>
    <Paragraph>
        4. Inflation and Interest Rate Sensitivity: Different sectors have
        different levels of sensitivity to changes in inflation and interest
        rates. For instance, Treasury Inflation-Protected Securities (TIPS) can
        provide a hedge against inflation, while long-term bonds are more
        sensitive to interest rate changes.
    </Paragraph>
    <Paragraph>
        5. Regulatory and Tax Considerations: Bonds from different sectors come
        with different regulatory and tax implications. Government and municipal
        bonds often have tax advantages over corporate bonds.
    </Paragraph>
    <Paragraph>
        Hence, fixed-income sector allocation is a crucial aspect of portfolio
        management. It allows for risk mitigation through diversification,
        provides a range of yield options, enables adaptability to economic
        cycles, and offers tax optimization opportunities. It should be tailored
        to the individual's investment objectives, risk tolerance, and
        investment horizon.
    </Paragraph>
    <Paragraph>
        * Fixed-income sector allocation is available only for ETFs and mutual
        funds.
    </Paragraph>
</>


export const maturityDateDescription = <>
    <Paragraph>
        The maturity date of a bond is a critical element of fixed-income
        investing for the following reasons:
    </Paragraph>
    <Paragraph>
        1. Return of Principal: The maturity date is when the bond issuer is
        obliged to repay the principal amount to the bondholder. It signifies
        the end of the bond's life cycle, marking the point where investors get
        their initial investment back.
    </Paragraph>
    <Paragraph>
        2. Interest Rate Risk: The maturity date also influences a bond's
        sensitivity to interest rate changes, a concept known as duration.
        Long-term bonds are more sensitive to interest rate fluctuations,
        implying that their prices can vary significantly with changes in the
        interest rate. Conversely, short-term bonds are less sensitive to rate
        changes, making them more stable in a fluctuating rate environment.
    </Paragraph>
    <Paragraph>
        3. Yield and Pricing: The yield-to-maturity (YTM) of a bond, a key
        measure of its return, is directly influenced by its maturity date.
        Longer-term bonds typically offer higher yields to compensate for the
        increased risk of holding the bond for a longer period.
    </Paragraph>
    <Paragraph>
        4. Cash Flow Planning: For investors, the maturity date is important for
        cash flow planning. Knowing when a bond matures can help in planning for
        future cash needs or reinvestment decisions.
    </Paragraph>
    <Paragraph>
        5. Investment Strategy: The choice between short-term,
        intermediate-term, and long-term bonds, each with different maturity
        dates, plays a crucial role in an investor's strategy. The selection
        depends on an investor's risk tolerance, income requirements, and market
        expectations.
    </Paragraph>
    <Paragraph>
        In sum, a bond's maturity date is a fundamental characteristic that
        impacts its risk and return profile, pricing, and the overall investment
        strategy. It's an essential factor for investors to consider when
        investing in bonds.
    </Paragraph>
    <Paragraph>
        * Fixed-income maturity date is available only for ETFs and mutual
        funds.
    </Paragraph>
</>


const FixedIncomePlain = () => {
    const {
        proposal,
        loadingAiAnalyticalComments,
        aiAnalyticalComments
    } = useHolisticoProposalContext();
    const [show, setShow] = useState("both");
    const ref = useRef(null);
    const size = useSize(ref);
    const {token} = useToken();

    const vertical = size?.width < 800;
    const cv_cur = proposal?.p_bucket_cur?.calculated_values;
    const cv_prop = proposal?.p_bucket_prop?.calculated_values;

    return <Card hoverable ref={ref} style={{
        cursor: "default",
        borderColor: "rgb(220, 220, 220)",
        overflow: "hidden"
    }}>
        <CardHeader title={" Fixed-income"}/>
        <HRadioGroup
            options={[
                {label: "All", value: "both"},
                {label: "Current", value: "current"},
                {label: "Proposed", value: "proposed"},
            ]}
            value={show}
            onChange={(value) => setShow(value)}
        />
        <Divider/>
        <FundamentalsItemJoint
            title="Credit rating"
            description={creditRatingDescription}
            chartCurent={
                <div style={{marginTop: token.marginSM}}>
                    <CreditRatingChart allocation={cv_cur?.fi_credit_rating}/>
                </div>
            }
            chartPropposed={
                <div style={{marginTop: token.marginSM}}>
                    <CreditRatingChart allocation={cv_prop?.fi_credit_rating}/>
                </div>

            }
            commentCurrent={aiAnalyticalComments?.fi_credit_rating ?? "No credit rating information"}
            commentProposed={"No credit rating information"}
            aiCommentLoading={loadingAiAnalyticalComments}
            vertical={vertical}
            commonChart={false}
            show={show}
        />
        <FundamentalsItemJoint
            title="Fixed-income sector"
            description={fiSectorDescription}
            chartCurent={
                <FISectorPie
                    allocation={cv_cur?.fi_super_sector}
                    height={280}
                />
            }
            chartPropposed={
                 <FISectorPie
                     allocation={cv_prop?.fi_super_sector}
                     height={280}
                 />
            }
            commentCurrent={aiAnalyticalComments?.fi_super_sector ?? "No sector information"}
            commentProposed={"No sector information"}
            aiCommentLoading={loadingAiAnalyticalComments}
            vertical={vertical}
            commonChart={false}
            show={show}
        />
        <FundamentalsItemJoint
            title="Maturity date"
            description={maturityDateDescription}
            chartCurent={
               <MaturityDateBar
                    allocation={
                        ["both", "current"].includes(show)
                            ? cv_cur?.fi_effective_maturity
                            : {}
                    }
                    proposalAllocation={
                        ["both", "proposed"].includes(show)
                            ? cv_prop?.fi_effective_maturity
                            : {}
                    }
                    showLegend={show === "both"}
                    color={(show === "proposed" || Object.keys(cv_cur?.fi_effective_maturity ?? {}).length === 0) ? "#E0AD60" : ["#3196B2", "#E0AD60"]}
                />
            }
            commentCurrent={aiAnalyticalComments?.fi_effective_maturity ?? "No maturity date information"}
            commentProposed={"No maturity date information"}
            aiCommentLoading={loadingAiAnalyticalComments}
            vertical={vertical}
            commonChart={true}
            show={show}
        />
    </Card>
}


export default FixedIncomePlain

/* eslint-disable react/prop-types */
import React from 'react';
import {NavLink} from "react-router-dom";
import {Alert, Card} from "antd";
import Constants from "@global/Constants";
import PortfolioComposition
    from "@holistico/proposal/view/portfolios/PortfolioComposition";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import CardHeader from "@components/retail/cards/CardHeader";


const ProposedPortfolioComposition = () => {
    const {proposal} = useHolisticoProposalContext()
    const ability = proposal?.questionnaire?.risk_profile?.ability
    const behavioral = proposal?.questionnaire?.risk_profile?.behavioral

    return <>
        {ability || behavioral
            ?
            <PortfolioComposition
                bucketType={"prop"}
                title={"Proposed portfolio"}
            />
            :
            <Card hoverable style={{
                cursor: "default",
                borderColor: "rgb(220, 220, 220)",
                overflow: "hidden",
            }}>
                <CardHeader title={"Proposed portfolio"}/>
                <Alert
                    message="Questionnaire not filled"
                    description={
                        <div>
                            {"This section will become available after filling the "}
                            <NavLink
                                to={`/${Constants.RIA_URL}/proposals/${proposal?._id}/client/general-info`}>
                                questionnaire
                            </NavLink>
                            {" or passing the "}
                            <NavLink
                                to={`/${Constants.RIA_URL}/proposals/${proposal?._id}/client/behavioral-quiz`}>
                                behavioral quiz
                            </NavLink>
                        </div>
                    }
                    type="warning"
                    showIcon
                />
            </Card>
        }
    </>
}

export default ProposedPortfolioComposition;

import React, {useEffect, useRef, useState} from 'react';
import {useSize, useUpdateEffect} from "ahooks";
import {
    message,
    Typography,
    Alert,
    Card,
    Divider,
    theme,
} from 'antd';
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {calculateRetirement} from "@API/retirement";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import CardHeader from "@components/retail/cards/CardHeader";
import {
    DescriptionPopover, FundamentalsItemJoint
} from "@holistico/proposal/view/fundamentals/FundamentalsItem";
import HRadioGroup from "@holistico/aux/HRadioGroup";
import NestedCard from "@components/retail/cards/NestedCard";
import {
    RetirementMapChartWithStats,
    RetirementOptions
} from "@components/retail/portfolio/RetirementMapCard";


const {useToken} = theme;


export const prepareDataForRequest = (proposal, proposed=false, useDefault=true) => {
    const questionnaire = proposal?.questionnaire;
    const cv = proposed ? proposal?.p_bucket_prop?.calculated_values : proposal?.p_bucket_cur?.calculated_values;
    const fee = proposed ? questionnaire?.proposed_fee : questionnaire?.current_fee;

    if (!cv.market_value) {
        return null
    }

    if (!useDefault) {
        if (!questionnaire) {
            return null;
        }
        const required = [
            'birth_year',
            'retirement_year',
            'life_expectancy',
            'monthly_savings',
            'monthly_withdrawals',
            'inflation_rate',
        ];
        if (proposed) {
            required.push('proposed_fee');
        } else {
            required.push('current_fee');
        }
        if (required.some(field => is_null(questionnaire[field]))) {
            return null;
        }
    }

    return {
        projected_return_1m: cv?.risk?.projected_return_1m,
        variance_1m: cv?.risk?.variance_1m,
        investment_amount: cv?.market_value,
        expense_ratio: cv?.expense_ratio,
        client_full_name: proposal?.full_name,
        retirement_details: {
            birth_year: questionnaire?.birth_year ?? 1960,
            retirement_year: questionnaire?.retirement_year ?? 2035,
            life_expectancy: questionnaire?.life_expectancy ?? 90,
            monthly_savings: questionnaire?.monthly_savings ?? 0,
            monthly_withdrawals: questionnaire?.monthly_withdrawals ?? 0,
            savings_growth: questionnaire?.inflation_rate ?? 0,
            inflation_rate: questionnaire?.inflation_rate ?? 0,
            management_fee: fee ?? 0.5,
            nominal_values: true,
            ignore_extreme: true,
            wanted_at_retirement: cv?.market_value ?? 0,
            investment_amount: cv?.market_value ?? 0,
        }
    }
}


export const retirementMapDescription = <>
    <Typography.Paragraph>
        Retirement map is designed to examine how the current portfolio meets
        the client's long-term goals.
    </Typography.Paragraph>
    <Typography.Paragraph>
        The center line on the charts shows the projected portfolio
        dynamics. The probability range bounds the most probable
        investment dynamics scenarios. However, at each moment
        of time, there is a 10% probability of getting out the range
        down and a 10% probability of getting out the range up.
    </Typography.Paragraph>
    <Typography.Paragraph>
        As reference points, we present the projected value of wealth at
        retirement and
        the probability of not running out of money
        during the whole considered period.
    </Typography.Paragraph>
    <Typography.Paragraph>
        The retirement map calculation is based on Monte Carlo approach and is
        closely related to the portfolio projected return and risk characteristics.
    </Typography.Paragraph>
</>


const RetirementMap = () => {
    const {proposal} = useHolisticoProposalContext();
    const [show, setShow] = useState("both");
    const [loading, setLoading] = useState(false);
    const [loadingProposed, setLoadingProposed] = useState(false);
    const [warningMessage, setWarningMessage] = useState(false);
    const [retirementData, setRetirementData] = useState(null);
    const [retirementDataProposed, setRetirementDataProposed] = useState(null);
    const ref = useRef(null);
    const size = useSize(ref);
    const {token} = useToken();

    const vertical = size?.width < 800;
    const mkval = proposal?.p_bucket_cur?.calculated_values?.market_value
    const mkval_prop = proposal?.p_bucket_prop?.calculated_values?.market_value
    const mkval_diff = (mkval && mkval_prop) ? Math.abs(mkval - mkval_prop) : 0

    useUpdateEffect(() => {
        const questionnaire = proposal?.questionnaire;
        if (!questionnaire?.birth_year || !questionnaire?.life_expectancy) {
            return;
        }
        const currentYear = new Date().getFullYear();
        if (questionnaire.birth_year + questionnaire.life_expectancy < currentYear) {
            setWarningMessage(true);
            setRetirementData(null);
        } else {
            setWarningMessage(false);
        }
    }, [proposal])

    useEffect(() => {
        if (!proposal) {
            return;
        }

        setLoading(true)
        const data = prepareDataForRequest(proposal, false)
        if (data) {
            calculateRetirement(data,
                ret_data => {
                    setRetirementData(ret_data);
                    setLoading(false);
                },
                error => {
                    console.log(error)
                    message.error("Something is wrong with retirement processing");
                    setLoading(false);
                }
            );
        } else {
            setRetirementData(null);
            setLoading(false);
        }

        setLoadingProposed(true)
        const proposedData = prepareDataForRequest(proposal, true)
        if (proposedData) {
            calculateRetirement(proposedData,
                ret_data => {
                    setRetirementDataProposed(ret_data);
                    setLoadingProposed(false);
                },
                error => {
                    console.log(error)
                    message.error("Something is wrong with retirement processing");
                    setLoadingProposed(false);
                }
            );
        } else {
            setRetirementDataProposed(null);
            setLoadingProposed(false);
        }

    }, [proposal])

    return <Card hoverable ref={ref} style={{
        background: token.colorBgGrey,
        cursor: "default",
        borderColor: "rgb(220, 220, 220)",
        overflow: "hidden",
    }}>
        <NestedCard background={"white"}>
            <CardHeader title={<>
                Retirement map
                <DescriptionPopover>
                    {retirementMapDescription}
                </DescriptionPopover>
            </>}/>
            <HRadioGroup
                options={[
                    {label: "All", value: "both"},
                    {label: "Current", value: "current"},
                    {label: "Proposed", value: "proposed"},
                ]}
                value={show}
                onChange={(value) => setShow(value)}
            />
            {warningMessage &&
                <Alert
                    style={{marginTop: token.marginLG}}
                    message="Wrong life expectancy"
                    description={"Life expectancy must exceed the current year!"}
                    type="error"
                    showIcon
                />
            }
            {mkval_diff > 10
                && <Alert
                    style={{marginTop: token.marginLG}}
                    message="Warning"
                    description={<>
                        Client's current portfolio market value
                        ({ValueFormatter.currency(mkval)})
                        does not match
                        proposed portfolio market value
                        ({ValueFormatter.currency(mkval_prop)}).
                        This may affect the difference in the charts below.
                    </>}
                    type="warning"
                    showIcon
                />
            }
             <Divider/>
            <RetirementOptions setLoading={setLoading} showProposed={true}/>
        </NestedCard>
        <FundamentalsItemJoint
            chartCurent={
               <RetirementMapChartWithStats
                    loading={loading}
                    data={retirementData}
                    lifeExpectancy={proposal?.questionnaire?.life_expectancy}
                    investmentsAmount={proposal?.p_bucket_cur?.calculated_values?.market_value}
                    divId={"retirementGraphContainer"}
                    height={250}
                    showTitle={false}
                />
            }
            chartPropposed={
                <RetirementMapChartWithStats
                    loading={loadingProposed}
                    data={retirementDataProposed}
                    lifeExpectancy={proposal?.questionnaire?.life_expectancy}
                    investmentsAmount={proposal?.p_bucket_prop?.calculated_values?.market_value}
                    divId={"retirementGraphProposedContainer"}
                    height={250}
                    showTitle={false}
                />
            }
            vertical={vertical}
            commonChart={false}
            show={show}
            chartsOnly={true}
        />
    </Card>
}

export default RetirementMap;

import {List} from "antd";
import CustomLegendItem from "@holistico/chart/CustomLegend";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import React, {useEffect, useState} from "react";
import {NotificationOutlined, ThunderboltOutlined} from "@ant-design/icons";

export const getSScore = (values) => {
    let sscoreMarker = values?.score_markers?.find(marker => marker.label == 'sscore_plus')
    let value = sscoreMarker?.value ? sscoreMarker?.value : null
    let sscore = is_null(value) ? "-" : ValueFormatter.round(value, 0)
    return sscore
}

export const getAssetAllocation = (values) => {
    const assetAllocation = values?.asset_class
    const stocks = (assetAllocation["US Equity"] ?? 0) + (assetAllocation["Non-US Equity"] ?? 0)
    const bonds = (assetAllocation["US Bond"] ?? 0) + (assetAllocation["Non-US Bond"] ?? 0) + (assetAllocation["Cash"] ?? 0)
    return [stocks, bonds]
}

export const getAssetAllocationLabel = (values) => {
    const assetAllocation = values?.asset_class
    if (!assetAllocation) {
        return "-"
    }
    const stocks = (assetAllocation["US Equity"] ?? 0) + (assetAllocation["Non-US Equity"] ?? 0)
    const bonds = (assetAllocation["US Bond"] ?? 0) + (assetAllocation["Non-US Bond"] ?? 0) + (assetAllocation["Cash"] ?? 0)
    return `${ValueFormatter.round(100 * stocks, 0)} / ${ValueFormatter.round(100 * bonds, 0)}`
}

const StatsList = ({calculated_values}) => {
    const [sscorePlus, setSscorePlus] = useState(null)
    useEffect(() => {
        let sScorePlus = getSScore(calculated_values)
        setSscorePlus(sScorePlus)
    }, [calculated_values])

    return <List className={"custom-legend"}>
        <CustomLegendItem label={'Risk score'}
                          value={calculated_values?.risk?.risk_score} customMarker={<ThunderboltOutlined/>}/>
        <CustomLegendItem label={'Equity rating'}
                          value={sscorePlus} customMarker={<NotificationOutlined/>}/>
        <CustomLegendItem label={'Position count'}
                          value={calculated_values['unique_pos_count']}/>
        <CustomLegendItem label={'Distribution rate'}
                          value={ValueFormatter.percent(calculated_values['annual_dividend'] / 100)}/>
        <CustomLegendItem label={'Expense ratio'}
                          value={ValueFormatter.percent(calculated_values['expense_ratio'] / 100)}/>

    </List>
}

export default StatsList

export const getToleranceLevelByRiskScore = (total) => {
    switch (true) {
        case ( total <= 27 ):
            return "Low"
        case ( total > 27 && total <= 41 ):
            return "Below-average"
        case ( total > 41 && total <= 55 ):
            return "Moderate"
        case ( total > 55 && total <= 60 ):
            return "Above-average"
        case ( total > 60):
            return "High"
        default:
            return "Not defined"
    }
}

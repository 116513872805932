import React from "react";
import {Tooltip,} from "antd";
import HRadioGroup from "@holistico/aux/HRadioGroup";


const ReportPresetList = ({presets, selected, onSelect}) => {
    const options = presets.map(preset => {
        return {
            value: preset.key,
            label: <Tooltip title={preset.description}>{preset.title}</Tooltip>
        }
    })

    return <HRadioGroup
        options={options}
        value={selected.key}
        onChange={onSelect}
        size={"large"}
    />
}

export default ReportPresetList;

import React, {useRef} from "react";
import {useUpdateEffect} from "ahooks";
import {
    Avatar,
    Button,
    Card,
    Flex,
    List,
    theme,
    Typography
} from "antd";
import {FullscreenOutlined, MinusOutlined} from "@ant-design/icons";
import {useAiChatContext} from "@hooks/AiChatContext";
import ChatMessage from "@holistico/ai/chat/AiChatMessage";
import AiChatInput from "@holistico/ai/chat/AiChatInput";


const {useToken} = theme;


export const AiChat = ({height="45vh"}) => {
    const divRef = useRef(null);
    const {messageList, newMsgTrigger} = useAiChatContext()

    useUpdateEffect(() => {
        setTimeout(() => {
            if (divRef?.current) {
                divRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                })
            }
        }, 500)
    }, [newMsgTrigger])

    return <Flex vertical style={{background: "white"}}>
        <List
            gutter={24}
            size={'small'}
            dataSource={messageList}
            renderItem={(item, index) => {
                return <ChatMessage
                    key={index}
                    content={item.content}
                    role={item.role}
                />
            }}
            style={{
                width: '100%',
                height: height,
                overflowY: 'scroll',
            }}
        >
            <div ref={divRef} style={{marginBottom: 60}}/>
        </List>
        <AiChatInput/>
    </Flex>
}


const AiChatCard = ({
                    height = "45vh",
                    setAiMinimized=()=>{},
                    setAiExpand=()=>{}
                }) => {
    const {token} = useToken();

    return <Card
        hoverable
        style={{
            borderColor: "rgb(220, 220, 220)",
            cursor: "default",
            overflow: "hidden",
        }}
    >
        <div style={{margin: -token.marginLG}}>
            <Flex
                justify={"space-between"}
                style={{
                    borderBottom: "1px solid rgb(220, 220, 220)",
                    padding: token.paddingSM
                }}
            >
                <Flex gap={"small"} align={"center"}>
                    <Avatar size="small" src="/img/cyber-master-of-coin.jpg"/>
                    <Typography.Title level={5} style={{margin: 0}}>
                        AI chat
                    </Typography.Title>
                </Flex>
                <Flex align={"center"}>
                    <Button
                        type="text"
                        icon={<MinusOutlined/>}
                        onClick={() => setAiMinimized(true)}
                    />
                    <Button
                        type="text"
                        icon={<FullscreenOutlined/>}
                        onClick={() => setAiExpand(true)}
                    />
                </Flex>
            </Flex>
            <AiChat height={height}/>
        </div>
    </Card>
}

export default AiChatCard;

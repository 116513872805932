import {useState} from "react";
import {Select, theme} from "antd";


const {useToken} = theme;


const CustomSelectInput = ({items}) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    return <Select
        defaultActiveFirstOption={true}
        defaultValue={items[0].value}
        options={items}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        variant={"borderless"}
        size={"large"}
        style={{
            width: "100%",
            fontWeight: token.fontWeightStrong,
            background: hover ? token.colorBgGreyDark : token.colorBgGrey,
            borderRadius: token.borderRadius,
        }}
    />


}

export default CustomSelectInput

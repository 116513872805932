import React, {useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useSize} from "ahooks";
import {
    Card,
    ConfigProvider,
    Flex,
    Popover,
    theme,
    Typography
} from "antd";
import {ExportOutlined} from "@ant-design/icons";
import {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";


const {useToken} = theme;


const RiskProfilePanelItem = ({
                                  value,
                                  title,
                                  showLink = false,
                                  href = null,
                                  linkLabel = null,
                                  info,
                                  filler = true,
                                  selected = false,
                                  vertical = false
                              }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {token} = useToken();

    return <Flex
        vertical={!vertical}
        gap={"small"}
        align={vertical ? "center" : "flex-start"}
        style={{
            flexGrow: 1,
            cursor: "default"
        }}
    >
        <Flex
            gap={"middle"}
            align={"center"}
            style={{width: vertical ? "auto" : "100%"}}
        >
            <ConfigProvider theme={{
                token: {
                    colorText: "white",
                    colorBgElevated: token.colorBgSpotlight,
                    colorLink: token.colorStats,
                }
            }}>
                <Popover {...info}>
                    <Flex
                        justify={"center"}
                        align={"center"}
                        style={{
                            height: 40,
                            width: 40,
                            border: `2px ${token.colorStats} solid`,
                            borderRadius: 4,
                            background: selected ? token.colorStats : "white",
                            color: selected ? "white" : token.colorTextBase,
                            flexShrink: 0,
                            fontWeight: token.fontWeightStrong
                        }}
                    >
                        {is_null(value) ? "-" : value}
                    </Flex>
                </Popover>
            </ConfigProvider>
            {filler && !vertical
                && <div style={{
                    background: token.colorStats,
                    height: 2,
                    flexGrow: 1,
                }}/>
            }
        </Flex>
        <Flex vertical={!vertical} gap={4} align={"baseline"}>
            <Typography.Text
                type={"secondary"}
                style={{
                    fontSize: token.fontSizeSM,
                    lineHeight: 1.2,
                    textTransform: "uppercase",
                    ...(vertical ? {} : {wordSpacing: 1000})
                }}
            >
                {title}
            </Typography.Text>
            {showLink
                && <a
                    href={href}
                    style={{fontSize: token.fontSizeSM}}
                    onClick={(e) => {
                        e.preventDefault();
                        navigate({
                            pathname: "../" + href,
                            search: location.search
                        })
                    }}
                >
                    {linkLabel}
                </a>
            }
        </Flex>
    </Flex>
}


const RiskProfilePanel = ({
                              selected = null,
                              hoverable = true,
                              showLinks = false,
                              style = {}
                          }) => {
    const {proposal} = useHolisticoProposalContext();
    const ref = useRef(null);
    const size = useSize(ref);
    const {token} = useToken();

    const current = proposal?.p_bucket_cur?.calculated_values?.risk?.risk_score;
    const ability = proposal?.questionnaire?.risk_profile?.ability;
    const behavioral = proposal?.questionnaire?.risk_profile?.behavioral;
    const willingness = proposal?.questionnaire?.risk_profile?.willingness;
    const proposed = proposal?.p_bucket_prop?.calculated_values?.risk?.risk_score;

    const vertical = size?.width < 600;

    return <Card hoverable={hoverable} style={{
        cursor: "default",
        marginBottom: token.margin,
        borderColor: "rgb(220, 220, 220)",
        ...style
    }}>
        <Flex
            vertical={vertical}
            gap={"middle"}
            justify={"stretch"}
            style={{width: "100%"}}
            ref={ref}
        >
            <RiskProfilePanelItem
                vertical={vertical}
                value={current}
                title="Current portfolio risk"
                href={"portfolios/client-portfolio"}
                linkLabel={"Positions >"}
                showLink={showLinks}
                selected={selected === "current"}
                info={{
                    title: "Current portfolio risk score",
                    content: <>
                        <a
                            href={`/ria/tutorial#risk-scores-portfolio-risk-score`}
                            target={"_blank"}
                        >
                            Read more about risk scores <ExportOutlined/>
                        </a>
                    </>
                }}
            />
            <RiskProfilePanelItem
                vertical={vertical}
                value={ability}
                title="Risk ability score"
                href={"client/general-info"}
                linkLabel={"Questionnaire >"}
                showLink={showLinks}
                selected={selected === "ability"}
                info={{
                    title: "Risk score for the ability to take risk",
                    content: <div>
                        Calculated on the questionnaire data.<br/>
                        This risk score reflects client's financial
                        ability<br/>
                        to withstand declines in portfolio values.<br/><br/>
                        <a
                            href={`/ria/tutorial#risk-scores-portfolio-risk-score`}
                            target={"_blank"}
                        >
                            Read more about risk scores <ExportOutlined/>
                        </a>
                    </div>
                }}
            />
            <RiskProfilePanelItem
                vertical={vertical}
                value={behavioral}
                title="Risk tolerance score"
                href={"client/behavioral-quiz"}
                linkLabel={"Quiz >"}
                showLink={showLinks}
                selected={selected === "behavioral"}
                info={{
                    title: "Risk score for the behavioral tolerance to risk",
                    content: <div>
                        Based on the behavioral quiz results.<br/>
                        Behavioral risk score measures client's<br/>
                        subconsciousness tolerance to risk.<br/><br/>
                        <a
                            href={`/ria/tutorial#risk-scores-portfolio-risk-score`}
                            target={"_blank"}
                        >
                            Read more about risk scores <ExportOutlined/>
                        </a>
                    </div>
                }}
            />
            <RiskProfilePanelItem
                vertical={vertical}
                value={willingness}
                title="Risk willingness score"
                href={"client/risk-tolerance"}
                linkLabel={"Risk picker >"}
                showLink={showLinks}
                selected={selected === "willingness"}
                info={{
                    title: "Risk score for the willingness to take risk",
                    content: <div>
                        Based on the possible losses<br/>
                        that the client agrees to accept.<br/><br/>
                        <a
                            href={`/ria/tutorial#risk-scores-portfolio-risk-score`}
                            target={"_blank"}
                        >
                            Read more about risk scores <ExportOutlined/>
                        </a>
                    </div>
                }}
            />
            <RiskProfilePanelItem
                vertical={vertical}
                value={proposed}
                title="Proposed portfolio risk"
                href={"portfolios/proposed-portfolios"}
                linkLabel={"Positions >"}
                showLink={showLinks}
                selected={selected === "proposed"}
                filler={false}
                info={{
                    title: "Proposed portfolio risk score",
                    content: <>
                        <a
                            href={`/ria/tutorial#risk-scores-portfolio-risk-score`}
                            target={"_blank"}
                        >
                            Read more about risk scores <ExportOutlined/>
                        </a>
                    </>
                }}
            />
        </Flex>
    </Card>
}


export default RiskProfilePanel;

/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Bar} from "@ant-design/charts";
import ValueFormatter from "@global/ValueFormatter";
import ChartContainer from "@holistico/chart/ChartContainer";


export const heightCalculator = (allocation, proposalAllocation) => {
    let height;
    let _allocation;
    let _proposalAllocation;
    if (Object.keys(allocation).length === 0 && proposalAllocation && Object.keys(proposalAllocation).length > 0) {
        _allocation = proposalAllocation;
        _proposalAllocation = null;
    } else {
        _allocation = allocation;
        _proposalAllocation = proposalAllocation;
    }
    if (_proposalAllocation && Object.keys(_proposalAllocation).length > 0) {
        if (Math.max(Object.keys(_allocation).length, Object.keys(_proposalAllocation).length) > 2) {
            height = 40 + Math.max(Object.keys(_allocation).length, Object.keys(_proposalAllocation).length) * 45;
            return height;
        } else {
            height = 40 + (Object.keys(_allocation).length + Object.keys(_proposalAllocation).length) * 35;
            return height;
        }
    }
    height = 40 + Object.keys(_allocation).length * 35;
    return height;
}


const SectorBar = ({
                       allocation,
                       proposalAllocation = null,
                       label = "Current",
                       proposalLabel = "Proposed",
                       showLegend = true,
                       color = ['#3196B2', '#E0AD60'],
                   }) => {
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({
        angleField: 'value',
        data: [],
        colorField: 'label',
    });

    const emtpy = !data || data.length === 0;

    useEffect(() => {
        if (!allocation) {
            return
        }

        let maxLabelLen = 0;
        let maxValue = 0;
        let _data = Object.keys(allocation).map(key => {
            if (maxLabelLen < key.length) {
                maxLabelLen = key.length;
            }
            if (allocation[key] > maxValue) {
                maxValue = allocation[key];
            }
            return {
                label: key,
                value: allocation[key],
                type: label
            }
        })

        if (_data === data) {
            return
        }

        if (proposalAllocation) {
            Object.keys(proposalAllocation).forEach(key => {
                if (maxLabelLen < key.length) {
                    maxLabelLen = key.length;
                }
                if (proposalAllocation[key] > maxValue) {
                    maxValue = proposalAllocation[key]
                }
                const allocValue = {
                    label: key,
                    value: proposalAllocation[key],
                    type: proposalLabel
                }
                _data.push(allocValue)
            })
        }

        if (maxValue < 0.9) {
            maxValue += 0.1
        }

        _data.sort((a, b) => {
            return a?.label.localeCompare(b?.label)
        })
        setData(_data)

        let _config = {
            title: 'Sector Distribution',
            data: _data,
            height: heightCalculator(allocation, proposalAllocation),
            isGroup: true,
            color: color,
            maxBarWidth: 16,
            xField: 'value',
            yField: 'label',
            seriesField: 'type',
            renderer: 'svg',
            legend: showLegend
                ? {
                    position: 'top',
                    itemName: {
                        style: {
                            fontFamily: "Red Hat Display W",
                            fontWeight: 350,
                            fontSize: 14
                        }
                    }
                }
                : false,
            padding: [25, 45, 25, Math.max(6.6 * maxLabelLen, 50)],
            label: {
                position: 'right',
                offsetX: -5,
                style: {
                    fontFamily: "Red Hat Display W",
                    fontWeight: 350,
                    fontSize: 11
                }
            },
            yAxis: {
                label: {
                    style: {
                        fontFamily: "Red Hat Display W",
                        fontWeight: 350,
                        fill: "#4B4B4B",
                    },
                },
            },
            xAxis: {
                grid: {
                    line: {
                        style: {
                            stroke: "#a2a2a2",
                            lineWidth: 1,
                            lineDash: [1, 5],
                            strokeOpacity: 1,
                        }
                    }
                }
            },
            marginRatio: 0,
            meta: {
                value: {
                    formatter: (v, s, d) => {
                        return ValueFormatter.oneDigitPercent(v)
                    }
                },
                style: {
                    fontFamily: "Red Hat Display W",
                    fontWeight: 350,
                }
            },
            tooltip: {
                formatter: (datum) => {
                    return {
                        name: datum.type,
                        value: ValueFormatter.oneDigitPercent(datum.value)
                    };
                },
            },
        }

        setConfig(_config)

    }, [allocation, proposalAllocation])

    return <ChartContainer
        empty={emtpy}
        emptyText={"No sector information"}
        maxWidth={900}
    >
        {!emtpy &&  <Bar {...config}/>}
    </ChartContainer>
}

export default SectorBar;

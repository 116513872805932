import React from "react";
import {List, Spin, theme, Typography} from "antd";


const {useToken} = theme;


const ChatMessageContent = ({role, content}) => {
     const {token} = useToken();

    const style = {
        fontSize: token.fontSizeSM,
        whiteSpace: "pre-line",
        maxWidth: "calc(100% - 64px)",
        borderWidth: 1,
        borderStyle: "solid",
        padding: `${token.paddingXS}px ${token.paddingSM}px`,
    }

    if (typeof content === 'string' && role === 'user') {
        return <Typography.Text style={{
            ...style,
            background: token.colorBgGreyDark,
            fontWeight: token.fontWeightStrong,
            borderRadius: `24px 24px 0 24px`,
            marginLeft: "auto",
            border: "none",
        }}>
            {content}
        </Typography.Text>
    } else if (typeof content === 'string' && role === 'assistant') {
        return <Typography.Text copyable style={{
            ...style,
            background: token.colorBgGrey,
            border: "none",
            borderRadius: `24px 24px 24px 0`,
            marginRight: "auto",
        }}>
            {content}
        </Typography.Text>
    } else if (typeof content === 'string' && role === 'assistant-thinking') {
        return <Typography.Text style={{
            ...style,
            background: token.colorBgGrey,
            borderColor: token.colorBorderSecondary,
            borderRadius: `24px 24px 24px 0`,
            marginRight: "auto",
        }}>
            <Spin size="small" style={{marginRight: token.marginXS}}/>
            {content}
        </Typography.Text>
    } else {
        return content ?? ""
    }
}


const ChatMessage = ({role, content}) => {
    return <List.Item style={{border: "none"}}>
        <ChatMessageContent
            content={content}
            role={role}
        />
    </List.Item>
}


export default ChatMessage;

import React, {useEffect, useState} from "react";
import {useRequest} from "ahooks";
import {
    Select,
    Typography,
    theme,
    Flex
} from "antd";

import {getBenchmarkPortfoliosData} from "@API/portfolio";
import StatusProgress from "@holistico/chart/StatusProgress";


const {useToken} = theme;


const chooseModel = (risk_score, models) => {
    if (!models.length) {
        return null
    }
    let selectedPortfolio = models[0]

    let best_diff = 100
    for (let iteration_portfolio of models) {
        let iteration_risk = iteration_portfolio?.calculated_values?.risk?.risk_score
        let diff = Math.abs(risk_score - iteration_risk)
        if (diff < best_diff) {
            best_diff = diff
            selectedPortfolio = iteration_portfolio
        }
    }
    return selectedPortfolio
}


const PortfolioLabel = ({portfolio}) => {
    const {token} = useToken();

    return <Flex gap={"large"} justify={"space-between"} align={"baseline"}>
        <Typography.Text ellipsis style={{fontWeight: token.fontWeightStrong}}>
            {portfolio.name}
        </Typography.Text>
        <StatusProgress
            value={portfolio?.calculated_values?.risk?.risk_score ?? 0}
            status={null}
            steps={5}
        />
    </Flex>
}


const ModelSelectInput = ({risk_score = 50, onChange}) => {
    const [models, setModels] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    const {
        data,
        loading,
    } = useRequest(() => getBenchmarkPortfoliosData());


    useEffect(() => {
        if (!data) {
            return
        }
        let responseData = data.data;
        let selectedPortfolio = chooseModel(risk_score, responseData);
        setModels(responseData);
        setOptions(responseData.map(p => {
            return {
                label: <PortfolioLabel portfolio={p}/>,
                value: p._id
            }
        }))
        onChange(selectedPortfolio);
        setSelectedModel(selectedPortfolio._id)
    }, [data]);

    return <Select
        options={options}
        value={selectedModel}
        onChange={(_id) => {
            let selectedPortfolio = models.find(p => p._id === _id);
            onChange(selectedPortfolio);
            setSelectedModel(selectedPortfolio._id);
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        variant={"borderless"}
        size={"large"}
        loading={loading}
        style={{
            width: "100%",
            fontWeight: token.fontWeightStrong,
            background: hover ? token.colorBgGreyDark : token.colorBgGrey,
            borderRadius: token.borderRadius,
        }}
    />
}

export default ModelSelectInput;

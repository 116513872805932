import React, {useRef, useState} from "react";
import {Button, Flex, Input, Popover, theme} from "antd";
import {SendOutlined} from "@ant-design/icons";
import {useAiChatContext} from "@hooks/AiChatContext";


const {useToken} = theme;


const AiChatInput = ({}) => {
    const {
        sendMessage,
        waitingForResponse,
        predefinedMessages,
        getPredefinedMessageByKey
    } = useAiChatContext();
    const [currentMessage, setCurrentMessage] = useState('');
    const searchInputRef= useRef(null);
    const {token} = useToken();

    const loadPredefinedMessage = (key) => {
        let msg = getPredefinedMessageByKey(key);
        setCurrentMessage(msg);
        searchInputRef.current.focus();
        return msg
    }

    return <Flex vertical gap={"small"} style={{
        background: token.colorBgGreyDark,
        padding: token.paddingXS,
        borderTop: `1px solid rgb(220, 220, 220)`
    }}>
        <Flex>
            {predefinedMessages.slice(0, 3).map((obj => {
                return <Button
                    key={obj.key}
                    type={"text"}
                    size={"small"}
                    onClick={() => loadPredefinedMessage(obj.key)}
                    style={{
                        fontSize: token.fontSizeSM,
                        textTransform: "lowercase"
                    }}
                >
                    {obj.label}
                </Button>
            }))}
            <Popover
                content={
                    <Flex vertical gap={"small"}>
                        {predefinedMessages.slice(3).map((obj) => {
                            return <Button
                                key={obj.key}
                                type={"text"}
                                size={"small"}
                                onClick={() => loadPredefinedMessage(obj.key)}
                                style={{
                                    fontSize: token.fontSizeSM,
                                    textTransform: "lowercase"
                                }}
                            >
                                {obj.label}
                            </Button>
                        })}
                    </Flex>
                }
                placement="topRight"
            >
                <Button
                    type={"text"}
                    size={"small"}
                    style={{
                        fontSize: token.fontSizeSM,
                        textTransform: "lowercase"
                    }}
                >
                    more...
                </Button>
            </Popover>
        </Flex>
        <Flex gap={"small"}>
            <Input.TextArea
                placeholder={'Write you question to AI'}
                value={currentMessage}
                variant={"borderless"}
                size={"large"}
                autoSize={{minRows: 1}}
                autoFocus={true}
                ref={searchInputRef}
                disabled={waitingForResponse}
                onChange={(e) => setCurrentMessage(e.target.value)}
                onPressEnter={(e) => {
                    e.preventDefault();
                    sendMessage(e.target.value);
                    setCurrentMessage("");
                }}
                style={{
                    background: "white",
                    borderRadius: token.borderRadius,
                    // borderRadius: 36,
                    fontSize: token.fontSize,
                }}
            />
            <Button
                type={"primary"}
                icon={<SendOutlined style={{
                    fontSize: token.fontSizeSM,
                    rotate: "-30deg"
                }}/>}
                disabled={waitingForResponse}
                loading={waitingForResponse}
                onClick={() => {
                    sendMessage(currentMessage);
                    setCurrentMessage("");
                }}
                style={{
                    borderRadius: token.borderRadius,
                    // borderRadius: 36,
                    minWidth: 36,
                    minHeight: 36,
                }}
            />
        </Flex>
    </Flex>
}

export default AiChatInput;

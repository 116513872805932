import React, {useState} from "react";
import {
    Col,
    Row,
    Affix,
    theme,
    Grid,
    FloatButton,
    Typography, Flex
} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {AiChatContextContextProvider} from "@hooks/AiChatContext";
import AiChatCard, {AiChat} from "@holistico/ai/chat/AiChatCard";
import NotesCard, {Notes} from "@holistico/ai/notes/NotesCard";
import MobileDrawer from "@components/retail/cards/MobileDrawer";


const {useToken} = theme;
const {useBreakpoint} = Grid;


export const AiSidebarContainer = ({
                                       proposalId = null,
                                       chatType = "proposal",
                                       autoMessage = false,
                                       minimizeAi = false,
                                       minimizeNotes = false,
                                       hideAi = false,
                                       hideNotes = false,
                                       marginTop = 0,
                                       children
                                   }) => {
    const [aiMinimized, setAiMinimized] = useState(minimizeAi);
    const [notesMinimized, setNotesMinimized] = useState(minimizeNotes);
    const [aiExpand, setAiExpand] = useState(false);
    const [notesExpand, setNotesExpand] = useState(false);
    const screens = useBreakpoint();
    const {token} = useToken();

    const smallScreen = !screens.lg;
    const showSideBar = !smallScreen && (
        (!aiMinimized && !hideAi) || (!notesMinimized && !hideNotes));
    const showAiFloat = ((smallScreen && !aiExpand) || aiMinimized) && !hideAi;
    const showNotesFloat = ((smallScreen && !notesExpand) || notesMinimized) && !hideNotes;

    return (
        <AiChatContextContextProvider
            proposalId={proposalId}
            type={chatType}
            autoMessage={autoMessage}
        >
            <Row gutter={[token.margin, token.margin]}>
                <Col span={showSideBar ? 16 : 24}>
                    {children}
                </Col>
                {showSideBar
                    && <Col span={8}>
                        <div style={{height: marginTop}}/>
                        <Affix offsetTop={16}>
                            <Flex vertical gap={"middle"}>
                                {!aiMinimized && !hideAi && !smallScreen
                                    &&
                                    <AiChatCard
                                        height={notesMinimized ? "calc(100vh - 500px)" : "calc(50vh - 150px)"}
                                        setAiMinimized={setAiMinimized}
                                        setAiExpand={setAiExpand}
                                    />
                                }
                                {!notesMinimized && !hideNotes && !smallScreen
                                    &&
                                    <NotesCard
                                        autoSize={{minRows: 5, maxRows: 8}}
                                        setNotesMinimized={setNotesMinimized}
                                        setNotesExpand={setNotesExpand}
                                    />
                                }
                            </Flex>
                        </Affix>
                    </Col>
                }
            </Row>
            {aiExpand
                && <MobileDrawer
                    title={"AI Chat"}
                    open={aiExpand}
                    onClose={() => setAiExpand(false)}
                    closable={true}
                    width={640}
                    style={{background: token.colorBgGreyDark}}
                >
                    <div style={{
                        marginLeft: -token.marginLG,
                        marginRight: -token.marginLG,
                    }}>
                        <AiChat height={"calc(100vh - 200px)"}/>
                    </div>
                </MobileDrawer>
            }
            {notesExpand
                && <MobileDrawer
                    title={"Notes"}
                    open={notesExpand}
                    onClose={() => setNotesExpand(false)}
                    closable={true}
                    width={640}
                    style={{background: token.colorBgGreyDark}}
                >
                    <div style={{
                        marginLeft: -token.marginLG,
                        marginRight: -token.marginLG,
                    }}>
                        <Notes height={"calc(100vh - 180px)"}/>
                    </div>
                </MobileDrawer>
            }
            {(showAiFloat || showNotesFloat)
                && <FloatButton.Group shape={"square"} style={{
                    bottom: 8,
                    insetInlineEnd: 8,
                }}>
                    {((smallScreen && !aiExpand) || aiMinimized) && !hideAi
                        && <FloatButton
                            // type={"primary"}
                            icon={<Typography.Text>AI</Typography.Text>}
                            badge={{color: token.colorError, dot: true}}
                            onClick={() => {
                                smallScreen ? setAiExpand(true) : setAiMinimized(false)
                            }}
                        />
                    }
                    {((smallScreen && !notesExpand) || notesMinimized) && !hideNotes
                        && <FloatButton
                            // type={"primary"}
                            icon={<EditOutlined/>}
                            badge={{color: token.colorError, dot: true}}
                            onClick={() => {
                                smallScreen ? setNotesExpand(true) : setNotesMinimized(false)
                            }}
                        />
                    }
                </FloatButton.Group>
            }
        </AiChatContextContextProvider>
    );
}


export default AiSidebarContainer;

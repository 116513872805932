import {
    List,
    Input,
    Button,
    Avatar,
    Typography,
    Flex, Card, theme, Menu
} from 'antd';
import React, {useRef, useState} from "react";
import {useAuth} from "@hooks/AuthHook";
import {SendOutlined, SettingOutlined} from "@ant-design/icons";
import {highlight} from "@holistico/proposal/view/ai/textWorkers";
import {useAiChatContext} from "@hooks/AiChatContext";

import CardHeader from "@components/retail/cards/CardHeader";
import {useSize} from "ahooks";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import ConfigurationForm from "@holistico/proposal/view/ai/ConfigurationForm";


const {useToken} = theme;


const ChatMessageContent = ({content, role}) => {
    if (typeof content == 'string') {
        if (role === 'user') {
            return <Typography.Text mark={true}>{content}</Typography.Text>
        }
        return <Typography.Text> {content.split("\n").map((par, index) => {
            return <Typography.Paragraph key={index}>
                {highlight(par)}
            </Typography.Paragraph>
        })}
        </Typography.Text>
    } else {
        return content
    }
}

const ChatMessage = ({role, content, user, small}) => {
    let avatarSrc = (role === 'assistant' || role === 'assistant-thinking') ? '/img/cyber-master-of-coin.jpg' : user?.picture
    return <List.Item style={{
        border: "none",
        paddingRight: 0,
        paddingLeft: 0,
    }}>
        <List.Item.Meta
            avatar={small ? null : <Avatar src={avatarSrc}/>}
            title={(role === 'assistant' || role === 'assistant-thinking') ? 'Master of coin' : user.meta.full_name}
            description={<ChatMessageContent content={content} role={role}/>}
        />

    </List.Item>
}


const ClientChat = ({showConfigButton=false, style={}}) => {
    const {
        messageList,
        sendMessage,
        waitingForResponse,
        predefinedMessages,
        getPredefinedMessageByKey
    } = useAiChatContext();
    const auth = useAuth();
    const [currentMessage, setCurrentMessage] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const divRef = useRef(null);
    const searchInputRef = useRef(null);
    const size = useSize(divRef);
    const {token} = useToken();


    const getMessagesToShow = (messages) => {
        if (!messages || messages.length === 0) {
            return []
        }

        let swapped = [];
        let buffer = [];
        messages.map(msg => {
            if (msg.role === 'user') {
                swapped = [...buffer, ...swapped];
                buffer = [msg];
            } else {
                buffer.push(msg)
            }
        })
        swapped = [...buffer, ...swapped];

        return swapped;
    }

    const loadPredefinedMessage = (key) => {
        let msg = getPredefinedMessageByKey(key);
        setCurrentMessage(msg);
        searchInputRef.current.focus();
    }

    return <Card hoverable style={{
        cursor: "default",
        overflow: "hidden",
        minHeight: 500,
        ...style
    }}>
        <CardHeader
            title={"AI chat"}
            controls={showConfigButton && [
                <Button
                    type={"text"}
                    icon={<SettingOutlined />}
                    onClick={() => setOpenDrawer(true)}
                />
            ]}
        />
        {/*<Divider/>*/}
        <Flex vertical>
            <Input.Search
                placeholder={'Type your question here'}
                autoFocus={true}
                ref={searchInputRef}
                size={'large'}
                loading={waitingForResponse}
                disabled={waitingForResponse}
                value={currentMessage}
                enterButton={
                    <Button
                        icon={<SendOutlined/>}
                        shape="round"
                        type="primary"
                        size="middle"
                        loading={waitingForResponse}
                    />
                }
                onSearch={sendMessage}
                onChange={(e) => setCurrentMessage(e.target.value)}
            />
            <Flex wrap={"wrap"} style={{marginLeft: -token.margin}}>
                {predefinedMessages.map((obj => {
                    return <Button
                        key={obj.key}
                        type={"link"}
                        // size={"small"}
                        onClick={() => loadPredefinedMessage(obj.key)}
                        style={{
                            textTransform: "lowercase",
                            padding: 0,
                            marginLeft: token.margin
                        }}
                    >
                        {obj.label}
                    </Button>
                }))}
            </Flex>
        </Flex>
        {/*<Divider style={{margin: `${token.marginSM}px 0`}}/>*/}
        <div ref={divRef} style={{marginTop: token.margin}}>
            <List
                gutter={12}
                dataSource={getMessagesToShow(messageList)}
                size={'small'}
                renderItem={(item) => {
                    return <ChatMessage
                        content={item.content}
                        role={item.role}
                        user={auth.user}
                        small={size?.width < 800}
                    />
                }}
            >
                {!messageList?.length
                    && <ChatMessage
                        content={'Hi human, lets talk about your client portfolio'}
                        role={'assistant'}
                        user={auth.user}
                        small={size?.width < 800}
                    />
                }
            </List>
        </div>
        <MobileDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            width={400}
        >
            <ConfigurationForm/>
        </MobileDrawer>
    </Card>

}

export default ClientChat;

import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import {Button, FloatButton, Layout, message, Result, Spin} from 'antd';
import {getRetailClientId} from "@API/clients";
import {HolisticoProposalContextProvider} from "@hooks/ProposalContext";
import {AiChatContextContextProvider} from "@hooks/AiChatContext";
import RetailHeader from "@app/layout/retail/RetailHeader";
import MyPortfolio from "@components/retail/portfolio/MyPortfolio";
import packageInfo from "../../../../package.json";
import HHeader from "@app/layout/ria/HHeader";
import Screener from "@root/site/Screener";
import WelcomePage from "@components/retail/welcome/WelcomePage";
import {PortfolioScreener} from "@components/screener/AssetScreener";


const {Content, Footer} = Layout;


const NarrowPage = ({children}) => {
    return <div style={{marginLeft: "2%", marginRight: "2%"}}>
        {children}
    </div>
}


const RetailLayout = () => {
    const {externalId} = useParams();
    const version = packageInfo.version;
    const [loading, setLoading] = useState(true);
    const [proposalId, setProposalId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        if (externalId) {
            console.info(`using external proposal with ${externalId}`)
            setLoading(false)
            setProposalId(externalId)
        } else {
            setLoading(true);
            getRetailClientId((response, error) => {
                if (!error) {
                    setProposalId(response);
                } else {
                    message.error("Something went wrong while loading your portfolio!");
                }
                setLoading(false);
            })
        }

    }, [])

    return (
        <Layout style={{minHeight: "100vh", background: '#f6f9fa'}}>
            {externalId ? <HHeader/> : <RetailHeader/>}
            <Content style={{
                backgroundImage: "none",
                background: "none",
                margin: "0 auto",
                width: "98%",
                maxWidth: 1600,
            }}>
                <Spin spinning={loading} style={{height: 800}} size={"large"}>
                    {proposalId
                        &&
                        <HolisticoProposalContextProvider proposal_id={proposalId} autoUpdateRetirement={true}>
                            <AiChatContextContextProvider
                                proposalId={proposalId}
                                type={'proposal'}
                                autoMessage={false}
                            >
                                <Routes>
                                    {externalId ? <Route exact path=""
                                                         element={<MyPortfolio/>}/> :
                                        <Route exact path=""
                                               element={<Navigate to="dashboard" replace={true}/>}/>}
                                    <Route path="dashboard" element={<WelcomePage/>}/>
                                    <Route path="portfolio" element={<MyPortfolio/>}/>
                                    <Route path="screener/*" element={<NarrowPage><Screener/></NarrowPage>}/>
                                    <Route path="marketplace/*" element={<PortfolioScreener/>}/>
                                    <Route path="*" element={
                                        <Result
                                            status="404"
                                            title="404"
                                            subTitle="Sorry, the page you visited does not exist."
                                            extra={
                                                <Button
                                                    type="primary"
                                                    onClick={() => navigate("")}
                                                >
                                                    Back Home
                                                </Button>
                                            }
                                        />
                                    }/>
                                </Routes>
                            </AiChatContextContextProvider>
                        </HolisticoProposalContextProvider>
                    }
                </Spin>
            </Content>
            <FloatButton.BackTop
                visibilityHeight={800}
                style={{
                    bottom: 8,
                    insetInlineEnd: 64
                }}
            />
            <Footer style={{textAlign: 'center'}}>
                Powered by Holistico LLC
                ©{(new Date().getFullYear())}
                <br/><small> v. {version}</small>
            </Footer>
        </Layout>
    );
}

export default RetailLayout;

import {Col, Row} from "antd";
import React from "react";
import {Route, Routes, useSearchParams} from "react-router-dom";
import AssetAllocation from "@holistico/proposal/view/fundamentals/AssetAllocation";
// import Equities from "@holistico/proposal/view/fundamentals/Equities";
// import FixedIncome from "@holistico/proposal/view/fundamentals/FixedIncome";
import EquitiesPlain from "@holistico/proposal/view/fundamentals/EquitiesPlain";
import FixedIncomePlain
    from "@holistico/proposal/view/fundamentals/FixedIncomePlain";


const Fundamentals = () => {
    return <div style={{minHeight: "100vh"}}>
        <Routes>
            <Route path="asset-allocation" element={<AssetAllocation/>}/>
            <Route path="equities" element={<EquitiesPlain/>}/>
            <Route path="fixed-income" element={<FixedIncomePlain/>}/>
        </Routes>
    </div>
}

export default Fundamentals

/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Radar} from "@ant-design/charts";
import ChartStyles from "@global/ChartStyles";
import ValueFormatter from "@global/ValueFormatter";
import Localizator from "@global/Localizator";
import CustomLegendItem from "@holistico/chart/CustomLegend";
import StatusProgress from "@holistico/chart/StatusProgress";
import ChartContainer from "@holistico/chart/ChartContainer";


const ScoreMarkersRadar = ({
                               score_markers,
                               showChart = true,
                               showLegend = true,
                               height = 350,
                               alias = 'Portfolio Score'
                           }) => {
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({
        data: [],
        xField: 'label',
        yField: 'value'
    });

    const width = height  * 1.2;
    const emtpy = !data || data.length === 0;

    useEffect(() => {
        if (!score_markers) {
            return
        }

        let _data = score_markers.map((marker) => {
            let value = ValueFormatter.round(marker['value'], 0)
            let status = ChartStyles.get_status(value, 40, 61)
            let label = Localizator.t(`factor.${marker['label']}`)
            return {
                label: label,
                value: value,
                status: status
            }
        })
        setData(_data);

        const _config = {
            title: 'Score Markers',
            // autoFit: true,
            height: height,
            width: width,
            data: _data.slice(1),
            xField: 'label',
            yField: 'value',
            padding: 12,
            appendPadding: 12,
            meta: {
                value: {
                    alias: alias,
                    min: -30,
                    max: 140
                },
            },
            xAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        style: {
                            lineDash: [4, 4],
                        },
                    },
                },
                label: {
                    style: {
                        //fill: 'black'
                    },
                    offset: 0
                }
            },
            yAxis: {
                line: null,
                tickLine: null,
                label: null,
                grid: {
                    line: {
                        style: (x, y) => {
                            if (y < 6) {
                                return {
                                    fill: "r(0.5,0.5,0.2) 0:rgb(85, 157, 180)",
                                    fillOpacity: 0.1,
                                    opacity: 0
                                };
                            } else {
                                return {}
                            }

                        }
                    }
                },
            },
            point: {
                style: (datum) => {
                    let status = ChartStyles.get_status(datum.value, 40, 61)
                    let color = ChartStyles.get_status_color(status)

                    return {
                        lineWidth: 0,
                        fill: color,
                        r: 15,
                        opacity: 0.5
                    }
                }
            },
            lineStyle: {
                lineDash: [2, 2],
                stroke: ChartStyles.colors[1]
            },
            tooltip: {
                showMarkers: true,
                marker: {
                    lineWidth: 5,
                    fillOpacity: 0,
                    r: 15,
                    opacity: 1
                }
            }
        }
        setConfig(_config)
    }, [score_markers])

    return <ChartContainer
        legendDataSource={showLegend ? data : null}
        legendRenderItem={(item, index) => (
            <CustomLegendItem
                label={item.label}
                value={item.value}
                customMarker={
                    <StatusProgress
                        value={item.value + 10}
                        status={item.status}
                        steps={5}
                    />
                }
                highlight={index === 0}
                size={"small"}
            />
        )}
        empty={emtpy}
        emptyText={"No ratings information"}
        chartWidth={width}
    >
        {!emtpy && <Radar {...config}/>}
    </ChartContainer>
}

export default ScoreMarkersRadar;

import React, {useEffect, useMemo, useState} from "react";
import {
    Button,
    ConfigProvider,
    Divider,
    Flex,
    message,
    Modal,
    Spin,
    Switch, theme,
} from "antd";
import {
    CloudDownloadOutlined,
    DeleteOutlined,
    SaveOutlined
} from "@ant-design/icons";
import {
    createReport,
    deleteReportPreset,
    getReportBasics,
} from "@API/report";
import {downloadFile} from "@global/FileDownloader";
import MultiColumnDnD from "@holistico/proposal/view/report/MultiColumnDnD";
import ReportPresetList
    from "@holistico/proposal/view/report/ReportPresetList";
import sortSections
    from "@holistico/proposal/view/report/SectionSorter";
import SectionLocalizator
    from "@holistico/proposal/view/report/SectionLocalizator";
import CreateTemplateModal
    from "@holistico/proposal/view/report/CreateTemplateModal";
import "./ReportSettingsModal.less"
import NestedCard from "@components/retail/cards/NestedCard";
import CardHeader from "@components/retail/cards/CardHeader";


const {useToken} = theme;


const ReportSettingsModal = ({
                                 open,
                                 onCancel,
                                 proposal,
                                 reportClass,
                                 idsMain,
                                 idsProposed,
                                 defaultTemplateKey = "full_with_fixed_income"
                             }) => {
    const [presets, setPresets] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState({
        title: 'None',
        sections: [],
        'key': null
    });
    const [availableSections, setAvailableSections] = useState([]);
    const [selectedSections, setSelectedSections] = useState([]);
    const [sectionsOrder, setSectionsOrder] = useState([]);
    const [reportOptions, setReportOptions] = useState({show_volatility: true})
    const [loadingStatus, setLoadingStatus] = useState("loading templates");
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const {token} = useToken();

    const calculatedValues = idsMain !== null && idsMain.length > 1 ? proposal?.p_bucket_cur?.calculated_values : null

    let calculatedValuesProposed = null
    if (reportClass === "client") {
        idsProposed = proposal?.p_bucket_prop?.portfolios ? Object.keys(proposal.p_bucket_prop.portfolios) : null
        calculatedValuesProposed = idsProposed !== null && idsProposed.length > 1 ? proposal?.p_bucket_prop?.calculated_values : null
    }

    const loadTemplates = (defaultSelectedKey = null) => {
        setLoadingStatus("loading templates")
        getReportBasics(reportClass === "enrollment" ? "client" : reportClass, basicData => {
            let existingPresets = basicData.presets;
            let allSectionObjects = basicData.available_sections.map(section => {
                return {
                    ...section,
                    title: SectionLocalizator.t(section.title, reportClass),
                    checked: false
                };
            })
            setPresets(existingPresets)
            setAvailableSections(allSectionObjects);
            setSelectedPreset(existingPresets.filter(preset => preset.key === defaultSelectedKey)[0] ?? existingPresets[0])
            setLoadingStatus("done")
        });
    }

    const sortedPresetSections = useMemo(
        () => {
            const sorted = sortSections(availableSections, selectedPreset.sections)
            setSectionsOrder(sorted.map(section => section.key))
            return sorted
        },
        [selectedPreset?.key]
    );

    useEffect(() => {
        loadTemplates(defaultTemplateKey)
    }, [])

    useEffect(() => {
        setSelectedSections(
            availableSections.filter(section => selectedPreset.sections.includes(section.key)).map(section => section.key)
        )
    }, [selectedPreset])

    const selectPreset = (value) => {
        let preset = presets.filter(preset => preset.key === value)[0];
        setSelectedPreset(preset);
    }

    const createReportOnClick = () => {
        setLoadingStatus("generating report");
        createReport({
                main_ids: idsMain,
                benchmark_ids: idsProposed,
                main_calculated_values: calculatedValues,
                benchmark_calculated_values: calculatedValuesProposed,
                client_id: proposal ? proposal._id : null,
                sections: sectionsOrder.filter(section => selectedSections.includes(section)),
                show_volatility: reportOptions.show_volatility
            }, (response, error) => {
                if (error) {
                    message.error("Error while creating the report!");
                    setLoadingStatus("done");
                    return
                }
                let file_type = response.headers['content-type'];
                let file_name;
                if (proposal) {
                    file_name = `${proposal.full_name} (${new Date().toLocaleString()}).pdf`;
                } else {
                    file_name = `Playground Report ${mainPortfolios.name} vs ${benchmarkPortfolios.name} (${new Date().toLocaleString()}).pdf`;
                }
                downloadFile(response.data, file_name, file_type, () => {
                    setLoadingStatus("done");
                })
                message.success("Report was generated successfully!")
                onCancel()
            }
        )
    }

    return <>
        <CreateTemplateModal
            open={openCreateModal}
            sections={sectionsOrder.filter(section => selectedSections.includes(section))}
            onCancel={() => setOpenCreateModal(false)}
            onSave={(key) => {
                loadTemplates(key);
                setOpenCreateModal(false)
            }}
            reportClass={reportClass === "enrollment" ? "client" : reportClass}
            name={selectedPreset.title + " (copy)"}
            description={selectedPreset.description}
        />
        <ConfigProvider theme={{components: {Modal: {contentBg: token.colorBgGreyDark}}}}>
            <Modal
                open={open}
                closable={true}
                title={null}
                footer={null}
                width={"94%"}
                style={{maxWidth: "1500px"}}
                onCancel={onCancel}
            >
                <Spin
                    style={{height: 800}}
                    spinning={loadingStatus !== "done"}
                    tip={
                        {
                            "generating report": "Generating report file...",
                            "loading templates": "Loading templates...",
                            "deleting template": "Deleting template...",
                        }[loadingStatus] || ""
                    }
                    size={"large"}
                >
                    <NestedCard background={token.colorBgGrey}>
                        <NestedCard background={"white"}>
                            <CardHeader title="Report configuration"/>
                            <ReportPresetList
                                presets={presets}
                                selected={selectedPreset}
                                onSelect={(key) => selectPreset(key)}
                            />
                        </NestedCard>
                        <div style={{minHeight: "50vh"}}>
                            <Flex gap={"small"} align={"center"}>
                                <Switch
                                    checked={reportOptions.show_volatility}
                                    onChange={(checked) => {
                                        setReportOptions({
                                            ...reportOptions,
                                            show_volatility: checked
                                        })
                                    }}
                                >
                                    Show volatility info?
                                </Switch>
                                Include projected return and volatility info
                            </Flex>
                            <Divider />
                            <MultiColumnDnD
                                sections={sortedPresetSections}
                                selected={selectedSections}
                                setSelected={setSelectedSections}
                                setOrder={setSectionsOrder}
                            />
                        </div>
                    </NestedCard>
                    <Flex
                        gap={"small"}
                        justify={"flex-end"}
                        wrap={"wrap"}
                    >
                        {selectedPreset.type === 'user' &&
                            <Button
                                type="link"
                                size="large"
                                onClick={() => {
                                    setLoadingStatus("deleting template")
                                    deleteReportPreset(selectedPreset._id, () => {
                                        message.success("Template was deleted successfully!")
                                        loadTemplates(defaultTemplateKey);
                                    })
                                }}
                            >
                                <DeleteOutlined/>
                                Delete template
                            </Button>
                        }
                        <Button
                            type="text"
                            onClick={() => {
                                setOpenCreateModal(true)
                            }}
                        >
                            <SaveOutlined/>
                            Save as new template
                        </Button>
                        <Button
                            type="primary"
                            onClick={createReportOnClick}
                        >
                            <CloudDownloadOutlined/>
                            Download report
                        </Button>
                    </Flex>
                </Spin>
            </Modal>
        </ConfigProvider>
    </>
}

export default ReportSettingsModal;

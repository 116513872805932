import React, {useEffect, useState} from "react";
import {useLatest} from "ahooks";
import {Flex, Typography, theme, Row, Col} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import ReportDownloadButton
    from "@holistico/proposal/view/report/ReportDownloadButton";


const {useToken} = theme;


const ProposalHeader = () => {
    const {proposal, updateBaseClientInfo} = useHolisticoProposalContext()
    const [name, setName] = useState(proposal?.full_name);
    const latestCountRef = useLatest(name);
    const {token} = useToken();

    useEffect(() => {
        setName(proposal?.full_name);
    }, [proposal])


    const saveNewName = () => {
        // Without setTimeout, it takes previous value
        setTimeout(() => updateBaseClientInfo({full_name: latestCountRef.current}), 0);
    }

    return <Flex
        gap={"middle"}
        justify={"space-between"}
        align={"baseline"}
        wrap={"wrap"}
    >
        <Typography.Title
            level={2}
            style={{
                color: token.colorStats,
                fontVariant: "small-caps",
                fontSize: token.fontSizeHeading1,
                lineHeight: 1.2,
                margin: 0
            }}
            editable={{
                icon: <EditOutlined style={{fontSize: '30%'}}/>,
                onChange: (value) => setName(value),
                onEnd: () => saveNewName(),
            }}
        >
            {name ?? "Unknown"}
        </Typography.Title>
        <ReportDownloadButton
            reportClass="client"
            strictPresets={['full_with_fixed_income', 'single']}
            type={"primary"}
        />
    </Flex>

}


export default ProposalHeader

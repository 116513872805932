import React, {useEffect, useRef, useState} from "react";
import moment from "moment/moment";
import {useSize} from "ahooks";
import {
    Button,
    Card,
    Divider,
    Flex,
    Form,
    Input,
    theme,
    Typography
} from "antd";
import {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import QuestionnaireForm from "@holistico/proposal/new/QuestionnaireForm";
import {
    getToleranceLevelByRiskScore
} from "@holistico/proposal/view/client/utils";
import NestedCard from "@components/retail/cards/NestedCard";
import CardHeader from "@components/retail/cards/CardHeader";
import ModelsCarousel from "@holistico/proposal/view/client/ModelsCarousel";
import RiskResult from "@holistico/proposal/view/client/RiskResult";
import RiskProfilePanel from "@holistico/proposal/view/client/RiskProfilePanel";


const {useToken} = theme;


export const getRiskScoreFromQuestionnaire = (values) => {
    const answers = values?.questionnaire
    if (!answers) {
        return null
    }
    let score = 50
    score += {
        short: -5,
        intermediate: 0,
        long: +10
    }[answers.investment_time_horizon]
    score += {
        below_spending: -5,
        above_spending: +10
    }[answers.non_investment_income]
    score += {not_working: -5, retired: -5, working: 5}[answers.wstatus]
    score += {married: -5, single: +5, divorced: 0}[answers.mstatus]
    const age = moment().year() - answers.birth_year
    score += Math.round((40 - age) / 2)
    score += Number(answers.hstatus) - 5
    return score
}


export function getFormLayoutPropsByWidth (width) {
    const props = {};
    const auxProps = {}

    if (width < 600) {
        props["layout"] = "vertical";
    } else if (width < 720) {
        props["labelCol"] = {span: 8}
        props["wrapperCol"] = {span: 16}
        auxProps["wrapperCol"] = {offset: 8, span: 16};
    } else if (width < 840) {
        props["labelCol"] = {span: 7}
        props["wrapperCol"] = {span: 14}
        auxProps["wrapperCol"] = {offset: 7, span: 14};
    } else {
        props["labelCol"] = {span: 6}
        props["wrapperCol"] = {span: 13}
        auxProps["wrapperCol"] = {offset: 6, span: 13};
    }
    return [props, auxProps];
}

const ProposalQuestionnaireForm = ({parentWidth}) => {
    const [form] = Form.useForm();
    const {proposal, updateClientInfo} = useHolisticoProposalContext();
    const [saving, setSaving] = useState(false);
    const scrollToRef = useRef(null);

    const [props, auxProps]  = getFormLayoutPropsByWidth(parentWidth);

    useEffect(() => {
        form.setFieldsValue(proposal);
    }, [proposal])

    const submitForm = (values) => {
        setSaving(true)
        const riskScore = getRiskScoreFromQuestionnaire(values);
        if (riskScore) {
            values["questionnaire"]["risk_profile"] = {"ability": riskScore};
        }
        updateClientInfo(values, true, true, () => {
            setSaving(false);
            if (riskScore && scrollToRef?.current) {
                scrollToRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                });
            }
        })
    };

    return <Form
        form={form}
        onFinish={submitForm}
        requiredMark={true}
        {...props}
    >
        <Form.Item
            name="full_name"
            label="Name"
        >
            <Input placeholder={"Name"}/>
        </Form.Item>
        <QuestionnaireForm withRules={true}/>
        <Form.Item {...auxProps}>
            <Flex gap={"small"}>
                <span style={{color: "#f5222d"}}>*</span>
                fields required for the risk ability estimation.
            </Flex>
        </Form.Item>
        <Form.Item {...auxProps}>
            <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{width: "200px"}}
                loading={saving}
                ref={scrollToRef}
            >
                Save
            </Button>
        </Form.Item>
    </Form>
}


const Questionnaire = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const cardRef = useRef(null);
    const size = useSize(cardRef);
    const {token} = useToken();

    const abilityScore = proposal?.questionnaire?.risk_profile?.ability;
    const abilityLevel = getToleranceLevelByRiskScore(abilityScore);

    return <>
        {proposal &&
            <>
                <RiskProfilePanel selected={"ability"}/>
                <Card
                    hoverable
                    ref={cardRef}
                    style={{
                        cursor: "default",
                        background: token.colorBgGreyDark,
                        borderColor: "rgb(220, 220, 220)"
                    }}
                >
                    <NestedCard background={token.colorBgGrey}>
                        <NestedCard background={"white"}>
                            <CardHeader
                                title="Questionnaire"
                                style={{marginBottom: token.marginXXS}}
                            />
                            <Typography.Text>
                                The questionnaire below estimates
                                risk ability score
                            </Typography.Text>
                            <Divider/>
                            <ProposalQuestionnaireForm
                                parentWidth={size?.width}
                            />
                        </NestedCard>
                        <RiskResult
                            title={abilityScore
                                ? `Risk ability score: ${abilityScore}`
                                : "Risk ability score not set"
                            }
                            description={abilityScore
                                ? `${abilityLevel} risk ability`
                                : "Fill the questionnaire to get risk ability score"
                            }
                            success={!is_null(abilityScore)}
                            style={{padding: `${token.paddingLG}px 0`}}
                        />
                    </NestedCard>
                    <Typography.Title level={5}>
                        Suitable models
                    </Typography.Title>
                    <ModelsCarousel
                        riskScore={abilityScore}
                        active={!!abilityScore}
                        inactiveMessage={"Models will become available after filling the questionnaire"}
                        style={{
                            minHeight: 340,
                            marginRight: -token.margin,
                            marginLeft: -token.margin,
                        }}
                    />
                </Card>
            </>
        }
    </>
}


export default Questionnaire;

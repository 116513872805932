import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    Divider,
    Flex,
    Form,
    InputNumber,
    Row,
    Spin,
    Typography,
    theme
} from "antd";
import {
    ClockCircleFilled,
    ReloadOutlined
} from "@ant-design/icons";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import RetirementGraph, {
    retirementAmountSuccessProbability
} from "@holistico/proposal/view/retirement_map/RetirementGraph";
import NestedCard from "@components/retail/cards/NestedCard";
import StatsNonBordered from "@components/retail/cards/StatsNonBordered";
import CardHeader from "@components/retail/cards/CardHeader";


const {useToken} = theme;


export const RetirementOptions = ({setLoading, showProposed=false}) => {
    const [form] = Form.useForm();
    const {proposal, updateQuestionnaire} = useHolisticoProposalContext()
    const currentYear = new Date().getFullYear();
    const initialValues = {}
    const portfolios = proposal?.current_portfolios;

    useEffect(() => {
        form.setFieldsValue(proposal?.questionnaire);
    }, [proposal])

    const onFinish = (values) => {
        setLoading(true);
        updateQuestionnaire(values, false);
    };

    return <Form
        form={form}
        layout="horizontal"
        labelCol={{span: 16}}
        wrapperCol={{span: 8}}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={initialValues}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    >
        <Flex justify={"center"}>


            <Row gutter={[16, 0]} style={{maxWidth: 800}}>
                <Col span={12}>
                    <Form.Item
                        label="Birth year"
                        name="birth_year"
                        rules={[{
                            required: true,
                            message: '* required'
                        }]}
                    >
                        <InputNumber/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Retirement year"
                        name="retirement_year"
                        rules={[
                            {
                                required: true,
                                message: '* required'
                            }
                        ]}
                    >
                        <InputNumber/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Life expectancy"
                        name="life_expectancy"
                        rules={[
                            {
                                required: true,
                                message: '* required'
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    let birth_year = getFieldValue('birth_year')
                                    if (!value || !birth_year || value + birth_year > currentYear) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('* must be greater than current year'));
                                },
                            }),
                        ]}
                    >
                        <InputNumber/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Inflation rate, %"
                        name="inflation_rate"
                        rules={[{
                            required: true,
                            message: '* required'
                        }]}
                    >
                        <InputNumber step={0.1}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Monthly savings"
                        name="monthly_savings"
                        rules={[{required: true, message: '* required'}]}
                    >
                        <InputNumber
                            step={1000}
                            formatter={(value) => ValueFormatter.currencyCustom(value, "")}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Monthly withdrawals"
                        name="monthly_withdrawals"
                        rules={[{
                            required: true,
                            message: '* required'
                        }]}
                    >
                        <InputNumber
                            step={1000}
                            formatter={(value) => ValueFormatter.currencyCustom(value, "")}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={showProposed ? "Current fee, %" : "Management fee, %"}
                        name="current_fee"
                        rules={[{
                            required: true,
                            message: '* required'
                        }]}
                    >
                        <InputNumber step={0.1}/>
                    </Form.Item>
                </Col>
                {showProposed
                    && <Col span={12}>
                        <Form.Item
                            label="Proposed fee, %"
                            name="proposed_fee"
                            rules={[{
                                required: true,
                                message: '* required'
                            }]}
                        >
                            <InputNumber step={0.1}/>
                        </Form.Item>
                    </Col>
                }
            </Row>
        </Flex>
        <Divider style={{margin: "0 0 16px 0"}}/>
        <Flex justify={"flex-end"}>
            <Button
                type={"link"}
                htmlType="submit"
                disabled={!portfolios || portfolios.length === 0}
                icon={<ReloadOutlined/>}
            >
                Refresh chart
            </Button>
        </Flex>
    </Form>
}


export const RetirementMapChartWithStats = ({
                                         data,
                                         lifeExpectancy,
                                         investmentsAmount,
                                         divId,
                                         height = 250,
                                         showTips = false,
                                         noDataText = "No data",
                                         loading = false,
                                         showTitle=true,
                                     }) => {
    const {token} = useToken();

    return <Spin size="large" spinning={loading}>
        {showTitle
            && <Typography.Title level={5}>
                Projected wealth
            </Typography.Title>
        }
        <RetirementGraph
            retirementData={data}
            lifeExpectancy={lifeExpectancy}
            investmentsAmount={investmentsAmount}
            divId={divId}
            height={height}
            showTips={showTips}
            noDataText={noDataText}
        />
        <Flex style={{marginTop: token.marginXL}}>
            <StatsNonBordered
                title={"Projected wealth at retirement"}
                value={
                    is_null(data?.projected_at_retirement)
                        ? "-"
                        : ValueFormatter.currency(data.projected_at_retirement)
                }
            />
            <StatsNonBordered
                title={
                    " probability of running out of money when you turn "
                    + lifeExpectancy
                }
                value={retirementAmountSuccessProbability(100 - data?.survival_probability)}
            />
        </Flex>
    </Spin>
}


const RetirementMapCard = ({}) => {
    const {
        proposal,
        retirement,
        loadingRetirement,
        setLoadingRetirement
    } = useHolisticoProposalContext();
    const {token} = useToken();

    return <Card
        hoverable
        style={{
            background: token.colorBgGrey,
            borderColor: "rgb(220, 220, 220)",
            cursor: "default"
        }}
    >
        <NestedCard background={"white"}>
            <CardHeader title="Retirement map" icon={<ClockCircleFilled/>}/>
            {!retirement &&
                <Flex gap={"small"} align={"center"}>
                    <Typography.Text type={"secondary"}>
                        Fill the form above and click "Refresh chart" to get
                        retirement map
                    </Typography.Text>
                </Flex>

            }
            <Divider/>
            <RetirementOptions setLoading={setLoadingRetirement}/>
        </NestedCard>
        <RetirementMapChartWithStats
            loading={loadingRetirement}
            data={retirement}
            lifeExpectancy={proposal?.questionnaire?.life_expectancy}
            investmentsAmount={proposal?.p_bucket_cur?.calculated_values?.market_value}
            divId={"retirementGraphContainer"}
            height={250}
        />
    </Card>
}


export default RetirementMapCard;

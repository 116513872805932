import React from "react";
import {Card, Divider, Result} from "antd";
import {FileSearchOutlined} from "@ant-design/icons";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import ComparativePortfolioPreview from "@holistico/proposal/new/preview/ComparativePortfolioPreview";
import CardHeader from "@components/retail/cards/CardHeader";


const PortfolioPreview = ({}) => {
    const {proposal} = useHolisticoProposalContext();

    return <Card hoverable style={{
        cursor: "default",
        borderColor: "rgb(220, 220, 220)",
        overflow: "hidden",
    }}>
        <CardHeader
            title={"Portfolio preview"}
            icon={<FileSearchOutlined />}
        />
        <Divider/>
        {proposal?.current_portfolios?.length > 0
            ? <ComparativePortfolioPreview/>
            : <Result
                status="403"
                title="No accounts"
                subTitle="Add client's portfolio accounts above to
                    see portfolio analytics "
            />
        }
    </Card>
}

export default PortfolioPreview;

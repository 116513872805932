/* eslint-disable react/prop-types */
import React from 'react';
import PortfolioComposition
    from "@holistico/proposal/view/portfolios/PortfolioComposition";


const ClientPortfolioComposition = () => {
    return <PortfolioComposition
        title = "Current portfolio"
        bucketType={"cur"}
    />
}

export default ClientPortfolioComposition;

import {Button, Col, Input, message, Row} from 'antd';
import React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import './AddClient.less';
import {cancel as createClientCancel, createClient, editClient, getClient} from "@API/clients";

const defaultClient = {
    "title": "Mr",
    "fullName": "",
    "description": "",
    "email": "",
    "phone": "",
    "user": ""
};

// eslint-disable-next-line react/prop-types
const AddClient = ({clientId, closeCreationModal}) => {
    // const {id} = useParams();
    const [errorInfo, setErrorInfo] = useState(null);
    const [client, setClient] = useState(defaultClient);
    const navigate = useNavigate();

    const getClientCallback = (data, error) => {
        if (error) {
            message.error("You can not edit this client. Access denied.");
            closeCreationModal();
            setClient(defaultClient);
        } else {
            setClient(data);
        }
    }

    useEffect(() => {
        if (clientId) {
            getClient(clientId, getClientCallback);
        } else {
            setClient(defaultClient);
        }
    }, [clientId])

    const handleInputChange = e => {
        const {name, value} = e.target;
        setClient({...client, [name]: value});
    }

    const clientSavingCallback = (data, error) => {
        if (error) {
            const processing_message = "Please, fill full Name";
            setErrorInfo(processing_message);
            message.error(processing_message);
        } else {
            message.success(`${data.full_name} was saved successfully`);
            navigate("/");
        }
    }

    const save = () => {
        if (client.full_name === "") {
            setErrorInfo("You must fill client full name")
        }
        if (!client.hasOwnProperty("_id")) {
            createClient(client, clientSavingCallback);
        } else {
            editClient(client._id, client, clientSavingCallback);
        }
    }

    useEffect(() => {
        return () => {
            createClientCancel && createClientCancel();
        }
    }, [])

    return (
        <div className={'add-client-container'}>
            <Row gutter={[16, 16]} className={'add-client'}>
                <Col span={24}>
                    <Input
                        placeholder="Full Name" name={"full_name"} value={client.full_name} onChange={handleInputChange}
                    />
                </Col>
                <Col span={12}>
                    <Input
                        placeholder="Email" name={"email"} value={client.email} onChange={handleInputChange}
                    />
                </Col>
                <Col span={12}>
                    <Input
                        placeholder="Phone" name={"phone"} value={client.phone} onChange={handleInputChange}
                    />
                </Col>
            </Row>
            {/* <Row gutter={[16, 16]}>
                <Col span={24}>
                    <RetirementOptions handleChange={handleDetailsChange} retirementBasics={clientDetails}
                                       title={"Additional Information"}/>
                </Col>
            </Row> */}

            <Row className="add-client-bottom-row">
                <div className="add-client-error">{errorInfo}</div>
                <div className="add-client-buttons">
                    <Button onClick={save} type="primary">Save</Button>
                    {/* <Link to="/clients"><Button style={{marginLeft: 13}}>Cancel</Button></Link> */}
                </div>
            </Row>
        </div>
    );
}

export default AddClient

import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Flex,
    InputNumber,
    Modal,
    Popconfirm,
    Spin,
    theme,
    Tooltip,
    Typography,
} from "antd";
import {
    DeleteFilled,
    EditOutlined,
    ExclamationCircleFilled,
} from "@ant-design/icons";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import NestedCard from "@components/retail/cards/NestedCard";
import StatsInline from "@components/retail/cards/StatsInline";
import PortfolioAssetSearch
    from "@components/portfolio/position/new/PortfolioAssetSearch";
import FileUpload from "@components/retail/portfolio/constructor/FileUpload";
import PositionsTable
    from "@components/retail/portfolio/constructor/PositionsTable";


const {useToken} = theme;


const CASH_POSITION = {
    ticker: "$$$",
    name: "Cash",
    market_value: 0,
    prohibitDeletion: true
}


const PortfolioConstructorTitlePanel = ({
                                            portfolio,
                                            portfolioName,
                                            setPortfolioName,
                                            positions,
                                            positionsChanged,
                                            marketValue,
                                            onCancel,
                                            onSubmit,
                                            allowEditName,
                                            showApplyButton,
                                            showCancelButton,
                                            applyButtonText,
                                            cancelButtonText
                                        }) => {
    const {token} = useToken();

    const disabled = !marketValue || marketValue <= 0 || (!positionsChanged && portfolioName === portfolio?.name);
    let tooltip = null;
    if (disabled && !marketValue) {
        tooltip = "Fill portfolio positions to get analytics!";
    } else if (disabled && marketValue < 0) {
        tooltip = "Portfolio market value cannot be negative!";
    } else if (disabled) {
        tooltip = "No changes have been made!";
    }


    return <Flex justify={"space-between"} align={"baseline"}>
        <Typography.Title
            level={4}
            style={{margin: "0 0 12px 0"}}
            editable={allowEditName
                ? {
                    onChange: setPortfolioName,
                    triggerType: ["icon", "text"],
                    icon: <EditOutlined
                        style={{color: token.colorTextDescription}}/>,
                    tooltip: "Click to edit account name"
                }
                : false
            }
        >
            {portfolioName}
        </Typography.Title>
        {(showApplyButton || showCancelButton)
            &&
            <Flex gap={"small"}>
                {showCancelButton
                    &&
                    <Button
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        {cancelButtonText}
                    </Button>
                }
                {showApplyButton
                    &&
                    <Tooltip title={tooltip}>
                        <Button
                            type={"primary"}
                            disabled={disabled}
                            onClick={() => {
                                const nameChanged = portfolioName !== portfolio?.name;
                                onSubmit(positions, portfolioName, positionsChanged, nameChanged);
                            }}
                        >
                            {applyButtonText}
                        </Button>
                    </Tooltip>
                }
            </Flex>
        }
    </Flex>
}


const PortfolioConstructorTip = ({tip}) => {
    return <Flex gap={"middle"}>
        <span style={{maxWidth: 500}}>
           {tip}
        </span>
    </Flex>
}


const PortfolioConstructorStats = ({marketValue, positionsCount, clearAll}) => {
    return <Flex gap={"large"} align={"baseline"} justify={"space-between"}
                 wrap={"wrap"}>
        <Flex gap={"large"} align={"baseline"}>
            <StatsInline
                value={ValueFormatter.currency(marketValue)}
                title={"market value"}
            />
            <StatsInline
                value={positionsCount}
                title={positionsCount === 1 ? " position" : " positions"}
            />
        </Flex>
        {(marketValue > 0 || positionsCount > 1)
            &&
            <Popconfirm
                title={`Are you sure to remove all positions?`}
                okText="Yes"
                cancelText="No"
                onConfirm={() => clearAll()}
            >
                <Button type={"link"} danger icon={<DeleteFilled/>}
                        style={{paddingLeft: 0}}>
                    Remove all positions
                </Button>
            </Popconfirm>
        }
    </Flex>
}


const PortfolioConstructor = ({
                                  portfolio = null,
                                  name = null,
                                  allowEditName = false,
                                  applyButtonText = "Save and open analytics",
                                  cancelButtonText = "Cancel",
                                  showApplyButton = true,
                                  showCancelButton = false,
                                  tip = null,
                                  bordered = true,
                                  hoverable = true,
                                  onSubmit = () => null,
                                  onCancel = () => null,
                                  style = {},
                                  refreshTrigger = null,
                                  onChange = null
                              }) => {
    const [cashValue, setCashValue] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [portfolioName, setPortfolioName] = useState(null);
    const [positions, setPositions] = useState([]);
    const [positionsFromFile, setPositionsFromFile] = useState(null);
    const [positionsChanged, setPositionsChanged] = useState(false);
    const [marketValue, setMarketValue] = useState(0);
    const [positionsCount, setPositionsCount] = useState(0);
    const cashInputRef = useRef();
    const {token} = useToken();

    useEffect(() => {
        setPortfolioName(name ?? portfolio?.name);
        if (portfolio?.positions) {
            setPositions(mergeAssets([{...CASH_POSITION}], portfolio?.positions.map(x => ({...x}))));
        } else {
            setPositions([{...CASH_POSITION}]);
        }
    }, [portfolio, refreshTrigger])

    useEffect(() => {
        setMarketValue(positions.reduce((mv, {market_value}) => mv + market_value, 0));
        setPositionsCount(positions.filter(p => p.market_value).length);
        setCashValue(positions[positions.length - 1]?.market_value);
    }, [positions])

    useEffect(() => {
        if (onChange) {
            const nameChanged = portfolioName !== portfolio?.name;
            onChange(positions, portfolioName, positionsChanged, nameChanged);
        }
    }, [positions, portfolioName])

    const mergeAssets = (prev, assets) => {
        const newCash = assets.filter(asset => asset.ticker === '$$$');
        if (newCash.length > 0) {
            const cashMarketValue = newCash.reduce((mv, {market_value}) => mv + market_value, 0);
            const noCashAssets = assets.filter(asset => asset.ticker !== '$$$');
            const _positions = [...noCashAssets, ...prev];
            _positions[_positions.length - 1].market_value += cashMarketValue;
            return _positions;
        } else {
            return [...assets, ...prev];
        }
    }

    const addPosition = (asset) => {
        setPositions(prev => mergeAssets(prev, [asset]));
        setPositionsChanged(true);
    };

    const addPositions = (assets, clearOld = false) => {
        setPositions(prev => {
            const _prev = clearOld ? [{...CASH_POSITION}] : prev;
            return mergeAssets(_prev, assets);
        });
        setPositionsChanged(true);
    };

    const deletePosition = (index) => {
        setPositions(prev => {
            return prev.filter((_, idx) => idx !== index);
        })
        setPositionsChanged(true);
    };

    const clearAll = () => {
        setPositions([{...CASH_POSITION}]);
        setPositionsChanged(true);
    };

    const changePositionMarketValue = (positionMarketValue, positionIndex) => {
        setPositions(prev => {
            prev[positionIndex].market_value = positionMarketValue;
            if (!is_null(prev[positionIndex].quantity)) {
                prev[positionIndex].quantity = prev[positionIndex].market_value / prev[positionIndex].price;
            }
            // Need new positions object to trigger useEffect
            return [...prev];
        });
        setPositionsChanged(true);
    };

    return <Spin
        spinning={uploading}
        size={"large"}
        tip={"Preparing positions from file..."}
    >
        <Card
            hoverable={hoverable}
            bordered={bordered}
            style={{
                background: token.colorBgGreyDark,
                borderColor: "rgb(220, 220, 220)",
                ...style
            }}
        >
            <NestedCard background={token.colorBgGrey}>
                <NestedCard background={token.colorBgBase}>
                    <PortfolioConstructorTitlePanel
                        portfolio={portfolio}
                        portfolioName={portfolioName}
                        positions={positions}
                        setPortfolioName={setPortfolioName}
                        positionsChanged={positionsChanged}
                        marketValue={marketValue}
                        allowEditName={allowEditName}
                        showApplyButton={showApplyButton}
                        showCancelButton={showCancelButton}
                        applyButtonText={applyButtonText}
                        cancelButtonText={cancelButtonText}
                        onCancel={onCancel}
                        onSubmit={onSubmit}
                    />
                    <Flex
                        vertical
                        gap={"large"}
                        style={{
                            flexGrow: 1,
                            marginBottom: 16,
                            marginTop: tip ? 0 : 16
                        }}
                    >
                        {tip && <PortfolioConstructorTip tip={tip}/>}
                        <FileUpload
                            addPositions={(assets) => {
                                if (marketValue || positions?.length > 1) {
                                    setPositionsFromFile(assets);
                                } else {
                                    addPositions(assets);
                                }
                            }}
                            setUploading={setUploading}
                        />
                        <PortfolioAssetSearch
                            onFinish={addPosition}
                            allowCreate={true}
                            placeholder={"Search asset to add..."}
                            size={"large"}
                            byTickerOnly={false}
                        />
                        <Flex gap={"small"} align={"center"}>
                             <span style={{fontWeight: token.fontWeightStrong}}>
                                 Cash:
                             </span>
                            <InputNumber
                                ref={cashInputRef}
                                prefix={"$"}
                                placeholder={"Enter cash value"}
                                formatter={ValueFormatter.int_number}
                                step={1000}
                                controls={false}
                                value={cashValue}
                                onChange={value => {
                                    changePositionMarketValue(value, positions.length - 1)
                                }}
                                onPressEnter={() => cashInputRef.current.blur()}
                                style={{width: 180}}
                            />
                        </Flex>
                    </Flex>
                </NestedCard>
                <PortfolioConstructorStats
                    marketValue={marketValue}
                    positionsCount={positionsCount}
                    clearAll={clearAll}
                />
            </NestedCard>
            <PositionsTable
                positions={positions}
                changePositionMarketValue={changePositionMarketValue}
                deletePosition={deletePosition}
                rowHeight={40}
            />
            <Modal
                title={null}
                footer={null}
                closable={false}
                maskClosable={false}
                open={positionsFromFile && (marketValue || positions?.length > 1)}
            >
                <Typography.Paragraph>
                    <ExclamationCircleFilled
                        style={{color: token.colorWarning, marginRight: 12}}/>
                    {/*Overwrite existing positions with positions from file?*/}
                    Append positions from file to existing ones or overwrite
                    them?
                </Typography.Paragraph>
                <Flex gap={"small"} justify={"flex-end"}>
                    <Button type={"text"}
                            onClick={() => setPositionsFromFile(null)}>
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        addPositions(positionsFromFile, false);
                        setPositionsFromFile(null);
                    }}>
                        Append to existing
                    </Button>
                    <Button type={"primary"} onClick={() => {
                        addPositions(positionsFromFile, true);
                        setPositionsFromFile(null);
                    }}>
                        Overwrite
                    </Button>
                </Flex>
            </Modal>
        </Card>
    </Spin>
}

export default PortfolioConstructor;

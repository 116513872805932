import React, {useRef, useState} from "react";
import {Card, theme, Typography} from "antd";
import {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import RiskResult from "@holistico/proposal/view/client/RiskResult";
import {
    getToleranceLevelByRiskScore
} from "@holistico/proposal/view/client/utils";
import ModelsCarousel
    from "@holistico/proposal/view/client/ModelsCarousel";
import BehavioralQuizForm
    from "@holistico/proposal/view/client/behavioral_quiz/BehavioralQuizForm";
import RiskProfilePanel from "@holistico/proposal/view/client/RiskProfilePanel";
import NestedCard from "@components/retail/cards/NestedCard";
import CardHeader from "@components/retail/cards/CardHeader";


const {useToken} = theme;


export const getQuizTotal = (answers) => {
    let total = 0
    for (let i in answers) {
        total += answers[i]
    }
    return total
}


export const getRiskScoreByQuizScore = (total) => {
    if (total < 25) {
        return Math.round(total + 25 - 2.5 * (25 - total))
    } else if (total >= 25) {
        return Math.round(total + 25 + 0.4 * (total - 25))
    } else {
        return null
    }
}


const BehavioralQuiz = ({}) => {
    const {
        proposal,
        updateQuestionnaire,
    } = useHolisticoProposalContext();
    const [saving, setSaving] = useState(false);

    const {token} = useToken();
    const scrollToRef = useRef(null);

    const behavioralScore = proposal?.questionnaire?.risk_profile?.behavioral;
    const behavioralLevel = getToleranceLevelByRiskScore(behavioralScore);

    const submitQuizResults = (values) => {
        if (!values) {
            return
        }
        const total = getQuizTotal(values["answers"])
        const riskScore = getRiskScoreByQuizScore(total)
        const questionnaire = {"behavioral_quiz": {...values, total: total}}
        if (riskScore) {
            questionnaire["risk_profile"] = {"behavioral": riskScore}
        }
        setSaving(true);
        updateQuestionnaire(questionnaire, true, true, () => {
            setSaving(false);
            if (riskScore && scrollToRef?.current) {
                scrollToRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                });
            }
        })
    }

    return <>
        {proposal &&
            <>
                <RiskProfilePanel selected={"behavioral"}/>
                <Card
                    hoverable
                    style={{
                        cursor: "default",
                        background: token.colorBgGreyDark,
                        borderColor: "rgb(220, 220, 220)"
                    }}
                >
                    <NestedCard background={token.colorBgGrey}>
                        <NestedCard background={"white"}>
                            <CardHeader
                                title="Behavioral quiz"
                                style={{marginBottom: token.marginXXS}}
                            />
                            <Typography.Text
                                style={{marginBottom: token.margin}}>
                                The quiz below estimates
                                behavioral risk tolerance score
                            </Typography.Text>
                            <BehavioralQuizForm onFinish={submitQuizResults} saving={saving}/>
                            <div ref={scrollToRef}/>
                        </NestedCard>
                        <RiskResult
                            title={behavioralScore
                                ? `Risk tolerance score: ${behavioralScore}`
                                : "Risk tolerance score not set"
                            }
                            description={behavioralScore
                                ? `${behavioralLevel} risk tolerance`
                                : "Take the quiz to get risk tolerance score"
                            }
                            success={!is_null(behavioralScore)}
                            style={{padding: `${token.paddingLG}px 0`}}
                        />
                    </NestedCard>
                    <Typography.Title level={5}>
                        Suitable models
                    </Typography.Title>
                    <ModelsCarousel
                        riskScore={behavioralScore}
                        active={!!behavioralScore}
                        inactiveMessage={"Models will become available after filling the quiz"}
                        style={{
                            minHeight: 340,
                            marginRight: -token.margin,
                            marginLeft: -token.margin,
                        }}
                    />
                </Card>
            </>
        }
    </>
}

export default BehavioralQuiz;

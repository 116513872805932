/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Bar} from "@ant-design/charts";
import ValueFormatter from "@global/ValueFormatter";
import ChartContainer from "@holistico/chart/ChartContainer";
import {heightCalculator} from "@holistico/chart/SectorBar";



const MaturityDateBar = ({
                             allocation,
                             proposalAllocation = null,
                             label = "Current",
                             proposalLabel = "Proposed",
                             color = ['#3196B2', '#E0AD60'],
                             showLegend = true
                         }) => {
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({
        angleField: 'value',
        data: [],
        colorField: 'label',
    });

    const emtpy = !data || data.length === 0;

    useEffect(() => {
        if (!allocation) {
            return
        }

        let maxLabelLen = 0;
        let maxValue = 0;
        let _data = Object.keys(allocation).map(key => {
            if (maxLabelLen < key.length) {
                maxLabelLen = key.length;
            }
            if (allocation[key] > maxValue) {
                maxValue = allocation[key];
            }
            return {
                label: key,
                value: allocation[key],
                type: label
            }
        })

        if (_data === data) {
            return
        }

        if (proposalAllocation) {
            Object.keys(proposalAllocation).forEach(key => {
                if (maxLabelLen < key.length) {
                    maxLabelLen = key.length;
                }
                if (proposalAllocation[key] > maxValue) {
                    maxValue = proposalAllocation[key]
                }
                const allocValue = {
                    label: key,
                    value: proposalAllocation[key],
                    type: proposalLabel
                }
                _data.push(allocValue)
            })
        }

        if (maxValue < 0.9) {
            maxValue += 0.1
        }

        _data.sort((a, b) => {
            let a_key = a?.label
            let b_key = b?.label
            if (!a_key) return -1
            if (!b_key) return 1

            a_key = parseInt(a_key.split('-')[0])
            b_key = parseInt(b_key.split('-')[0])
            if (a_key > b_key) return 1;
            if (a_key < b_key) return -1;
            return 0;
        });
        setData(_data)

        let _config = {
            title: 'Maturity date',
            data: _data,
            height: heightCalculator(allocation, proposalAllocation),
            isGroup: true,
            color: color,
            maxBarWidth: 16,
            xField: 'value',
            yField: 'label',
            seriesField: 'type',
            renderer: 'svg',
            legend: showLegend
                ? {
                    position: 'top',
                    itemName: {
                        style: {
                            fontFamily: "Red Hat Display W",
                            fontWeight: 350,
                            fontSize: 14
                        }
                    }
                }
                : false,
            padding: [25, 45, 25, Math.max(6.6 * maxLabelLen, 50)],
            label: {
                position: 'right',
                offsetX: -5,
                style: {
                    fontFamily: "Red Hat Display W",
                    fontWeight: 350,
                    fontSize: 11
                }
            },
            yAxis: {
                label: {
                    style: {
                        fontFamily: "Red Hat Display W",
                        fontWeight: 350,
                        fill: "#4B4B4B",
                    },
                },
            },
            xAxis: {
                grid: {
                    line: {
                        style: {
                            stroke: "#a2a2a2",
                            lineWidth: 1,
                            lineDash: [1, 5],
                            strokeOpacity: 1,
                        }
                    }
                }
            },
            marginRatio: 0,
            meta: {
                value: {
                    formatter: (v, s, d) => {
                        return ValueFormatter.oneDigitPercent(v)
                    }
                },
                style: {
                    fontFamily: "Red Hat Display W",
                    fontWeight: 350,
                }
            },
            tooltip: {
                formatter: (datum) => {
                    return {
                        name: datum.type,
                        value: ValueFormatter.oneDigitPercent(datum.value)
                    };
                },
            },
        }

        setConfig(_config);

    }, [allocation, proposalAllocation])

    return <ChartContainer
        empty={emtpy}
        emptyText={"No maturity date information"}
        maxWidth={900}
    >
        {!emtpy &&  <Bar {...config}/>}
    </ChartContainer>
}

export default MaturityDateBar;

/* eslint-disable react/prop-types */
import React, {useRef} from 'react';
import {useSize} from "ahooks";
import {Col, Row, Statistic} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import './Portfolio.less';


const PortfolioStats = ({calculated_values = {}}) => {
    const ref = useRef(null);
    const size = useSize(ref);

    let span;
    if (size?.width < 500) {
        span = 12;
    } else if (size?.width < 800) {
        span = 8;
    } else {
        span = 4;
    }

    return <div ref={ref} className={"portfolio-stats"}>
        <Row gutter={[24, 24]}>
            <Col span={span}>
                <Statistic
                    title="Risk score"
                    value={calculated_values?.risk?.risk_score}
                    precision={0}
                />

            </Col>
            <Col span={span}>
                <Statistic
                    title="Market value"
                    value={ValueFormatter.currency(calculated_values['market_value'])}
                    precision={0}
                />
            </Col>
            <Col span={span}>
                <Statistic
                    title="# of positions"
                    value={calculated_values['unique_pos_count']}
                    precision={0}
                />
            </Col>
            <Col span={span}>
                <Statistic
                    title="Distribution rate"
                    value={ValueFormatter.percent(calculated_values['annual_dividend'] / 100)}
                />
            </Col>
            <Col span={span}>
                <Statistic
                    title="Expense ratio"
                    value={ValueFormatter.percent(calculated_values['expense_ratio'] / 100)}
                />
            </Col>
            <Col span={span}>
                <Statistic
                    title="Projected return"
                    value={ValueFormatter.percent(calculated_values?.risk?.projected_return_1yr / 100)}
                />
            </Col>
        </Row>
    </div>
}

export default PortfolioStats;

import React from "react";
import {ExportOutlined} from "@ant-design/icons";

function debounce(func, timeout = 600, finish = false) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        if (finish) {
            return
        }
        timer = setTimeout(() => func.apply(this, args), timeout);
    };
}

//used for creating good menus with real links
const getHiddenHref = (label, path, urlBase = "", blank = false) => {
    if (blank === true) {
        return <a href={urlBase + path} target="_blank">
            {label} <ExportOutlined style={{fontSize: 13}}/>
        </a>
    } else {
        return <a href={urlBase + path} onClick={(e) => {
            e.preventDefault()
        }}>
            {label}
        </a>
    }
}

const isObjectId = (str) => {
    let parsedValue = parseInt(str, 16);
    return !isNaN(parsedValue)
}

const filterArrayWithSkipper = (array, skipper) => {
    if (skipper == 0) {
        return array
    }
    let filteredArray = []
    let iter = 0
    for (let valueNumber in array) {
        if (iter % skipper != 0) {
            iter++;
            continue;
        }
        filteredArray.push(array[valueNumber])
        iter++
    }
    return filteredArray

}

const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1)
}

export {debounce, getHiddenHref, filterArrayWithSkipper, isObjectId, capitalize}

import React, {useRef, useState} from 'react';
import {useSize} from "ahooks";
import {Alert, Card, Divider, Flex, theme, Typography} from 'antd';
import {StarFilled} from "@ant-design/icons";
import Localizator from "@global/Localizator";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import CardHeader from "@components/retail/cards/CardHeader";
import HRadioGroup from "@holistico/aux/HRadioGroup";
import {
    DescriptionPopover,
} from "@holistico/proposal/view/fundamentals/FundamentalsItem";
import {
    prepareScenarios, StressTestTip,
} from "@components/retail/portfolio/StressTestCard";
import NestedCard from "@components/retail/cards/NestedCard";


const {useToken} = theme;


export const stressTestsDescription = <>
    <Typography.Paragraph>
        Portfolio stress tests are of paramount importance for the following
        reasons:
    </Typography.Paragraph>
    <Typography.Paragraph>
        1. Risk Assessment: Stress tests allow investors to evaluate their
        portfolios under extreme but plausible market conditions. They provide a
        measure of how much value a portfolio could lose in such scenarios. This
        helps investors understand the level of risk they are assuming.
    </Typography.Paragraph>
    <Typography.Paragraph>
        2. Portfolio Resilience: By simulating various adverse market
        conditions, stress tests assess the resilience of a portfolio. They help
        determine whether the portfolio can withstand market turbulence and
        maintain its value.
    </Typography.Paragraph>
    <Typography.Paragraph>
        3. Asset Allocation: Stress testing can guide asset allocation decisions
        by indicating which assets could potentially lead to significant losses
        in a downturn. This can help investors adjust their portfolio to
        mitigate these risks.
    </Typography.Paragraph>
    <Typography.Paragraph>
        4. Regulatory Compliance: For institutional investors, stress tests are
        often a regulatory requirement. These tests ensure that the institution
        can endure market shocks without posing a risk to the financial system.
    </Typography.Paragraph>
    <Typography.Paragraph>
        In essence, stress tests are a potent tool for understanding and
        managing portfolio risk. They provide critical insights into how a
        portfolio could perform under severe market conditions, informing
        strategies to enhance portfolio resilience.
    </Typography.Paragraph>
</>


const PerformanceStats = ({
                              label,
                              portfolioReturn,
                              marketValue,
                              portfolioScore,
                              background = "none",
                              tip=null,
                              style = {}
                          }) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();
    const pVal = is_null(portfolioReturn)
        ? "-"
        : (portfolioReturn > 0 ? "+" : "") + ValueFormatter.oneDigitPercent(portfolioReturn / 100);
    const dVal = is_null(portfolioReturn)
        ? "-"
        : (portfolioReturn > 0 ? "+" : "") + ValueFormatter.compactCurrency(marketValue * portfolioReturn / 100);

    return <Flex
        vertical
        style={{
            minWidth: 120,
            padding: token.padding,
            borderRadius: token.borderRadiusLG,
            background: hover ? token.colorBgHoverBlue : background,
            border: "1px solid rgb(220, 220, 220)",
            flexGrow: 1,
            flexBasis: 0,
            ...style
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        <Flex
            gap={"small"}
            justify={"space-between"}
            align={"flex-start"}
            wrap={"wrap"}
        >
            <Flex vertical gap={4}>
            <span style={{
                fontSize: token.fontSizeSM,
                fontWeight: token.fontWeightStrong,
                lineHeight: 1.1,
            }}>
                {label}
            </span>
                <Flex gap={2}>
                    {Array.from(Array(5)).map((_, i) => {
                        return <StarFilled key={i} style={{
                            color: i < portfolioScore ? "#eeba6c" : "rgba(0,0,0,0.1)",
                            fontSize: 10
                        }}/>
                    })}
                </Flex>
            </Flex>
            <Flex vertical gap={4}>
             <span style={{
                 fontSize: token.fontSizeHeading4,
                 lineHeight: 1,
                 color: portfolioReturn >= 0 ? "rgb(73,170,25)" : "rgb(245,34,45)"
             }}>
                {pVal}
            </span>
                <span style={{color: token.colorTextDescription}}>
                {dVal}
            </span>
            </Flex>
        </Flex>
        {tip
            && <>
                <Divider style={{margin: `${token.marginSM}px 0`}}/>
                <Typography.Text type={"secondary"}>
                    {tip}
                </Typography.Text>
            </>
        }
    </Flex>
}


const StressTestItem = ({item, marketValue, vertical, show}) => {
    const {token} = useToken();

    return <Flex vertical align={"flex-start"}>
        <Typography.Title
            level={5}
            style={{
                margin: 0,
                textTransform: "uppercase",
                letterSpacing: 1,
                fontWeight: 350
            }}
        >
            {Localizator.t("stress." + item.label)}
        </Typography.Title>
        <Typography.Text
            type={"secondary"}
            style={{
                fontSize: token.fontSizeSM,
                marginBottom: token.marginSM,
            }}
        >
            {item.start + ' — ' + item.end}
        </Typography.Text>
        <Flex vertical={vertical} gap={"small"} style={{width: "100%"}}>
            {["both", "current"].includes(show)
                && <PerformanceStats
                    label={<>Current<br/>portfolio</>}
                    portfolioReturn={item.currentPortfolioReturn}
                    marketValue={marketValue}
                    portfolioScore={item.currentPortfolioScore}
                    background={"white"}
                    tip={
                        <StressTestTip
                            scenarioLabel={item.label}
                            portfolioReturn={item.currentPortfolioReturn}
                            stockMarketReturn={item.stockMarketReturn}
                            bondMarketReturn={item.bondMarketReturn}
                            portfolioLabel={"client's current portfolio"}
                        />
                    }
                />
            }
            {["both", "proposed"].includes(show)
                && <PerformanceStats
                    label={<>Proposed<br/>portfolio</>}
                    portfolioReturn={item.proposedPortfolioReturn}
                    marketValue={marketValue}
                    portfolioScore={item.proposedPortfolioScore}
                    background={"white"}
                    tip={
                        <StressTestTip
                            scenarioLabel={item.label}
                            portfolioReturn={item.proposedPortfolioReturn}
                            stockMarketReturn={item.stockMarketReturn}
                            bondMarketReturn={item.bondMarketReturn}
                            portfolioLabel={"proposed portfolio"}
                        />
                    }
                />
            }
            <Flex  gap={"small"} style={{
                flexGrow: 1,
                flexBasis: 0,
            }}>
                <PerformanceStats
                    label={<>Stock<br/>market</>}
                    portfolioReturn={item.stockMarketReturn}
                    marketValue={marketValue}
                    portfolioScore={item.stockMarketScore}
                    background={"white"}
                />
                <PerformanceStats
                    label={<>Bond<br/>market</>}
                    portfolioReturn={item.bondMarketReturn}
                    marketValue={marketValue}
                    portfolioScore={item.bondMarketScore}
                    background={"white"}
                />
            </Flex>
        </Flex>
    </Flex>
}


const StressTests = () => {
    const {proposal} = useHolisticoProposalContext()
    const [show, setShow] = useState("both");
    const ref = useRef(null);
    const size = useSize(ref);
    const {token} = useToken();

    const vertical = size?.width < 800;

    const mkvalCur = proposal?.p_bucket_cur?.calculated_values?.market_value;
    const mkvalProp= proposal?.p_bucket_prop?.calculated_values?.market_value;
    const mkvalDiff = (mkvalCur && mkvalProp) ? Math.abs(mkvalCur - mkvalProp) : 0;
    const scenarios = prepareScenarios(proposal);


    return <Card hoverable ref={ref} style={{
        cursor: "default",
        borderColor: "rgb(220, 220, 220)",
        overflow: "hidden",
        background: token.colorBgGrey
    }}>
        <NestedCard background={"white"}>
            <CardHeader title={<>
                Stress tests
                <DescriptionPopover>
                    {stressTestsDescription}
                </DescriptionPopover>
            </>}/>
            <HRadioGroup
                options={[
                    {label: "All", value: "both"},
                    {label: "Current", value: "current"},
                    {label: "Proposed", value: "proposed"},
                ]}
                value={show}
                onChange={(value) => setShow(value)}
            />
            {mkvalDiff > 10
                && <Alert
                    style={{marginTop: token.marginLG}}
                    message="Warning"
                    description={<>
                        Proposed portfolio market value
                        ({ValueFormatter.currency(mkvalProp)})
                        does not match
                        client's current portfolio market value
                        ({ValueFormatter.currency(mkvalCur)}).
                        For comparability, current portfolio market value is
                        used for stress tests.
                    </>}
                    type="warning"
                    showIcon
                />
            }
        </NestedCard>
        <Flex vertical gap={token.marginXXL}>
            {scenarios.map((item, idx) => {
                return <StressTestItem
                    key={item.label}
                    item={item}
                    marketValue={mkvalCur ?? mkvalProp}
                    performanceCardBackground={idx % 2 ? token.colorBgGrey : "white"}
                    vertical={vertical}
                    show={show}
                />
            })}
        </Flex>
    </Card>
}

export default StressTests;

import React, {useEffect, useState} from "react";
import {Dropdown, Menu, message, theme, Tooltip} from "antd";
import {
    CloudDownloadOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import {createReport, getReportBasics} from "@API/report";
import {downloadFile} from "@global/FileDownloader";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import ReportSettingsModal
    from "@holistico/proposal/view/report/ReportSettingsModal";


const {useToken} = theme;


const ReportDownloadButton = ({
                                  strictPresets = [],
                                  defaultKey = 'full_with_fixed_income',
                                  reportClass = "client",
                                  disabled = false,
                                  tooltip = null,
                                  size = null,
                                  type = null,
                              }) => {
    const {token} = useToken();
    const {proposal, benchmark, notesInReport} = useHolisticoProposalContext()
    const [loading, setLoading] = useState(false)
    const [templates, setTemplates] = useState([])
    const [defaultTemplate, setDefaultTemplate] = useState(null)
    const [openTemplateSettings, setOpenTemplateSettings] = useState(false)

    const ids = proposal?.p_bucket_cur?.portfolios ? Object.keys(proposal.p_bucket_cur.portfolios) : null
    const calculatedValues = ids !== null && ids.length > 1 ? proposal?.p_bucket_cur?.calculated_values : null

    let idsProposed = null
    let calculatedValuesProposed = null
    if (reportClass === "client") {
        idsProposed = proposal?.p_bucket_prop?.portfolios ? Object.keys(proposal.p_bucket_prop.portfolios) : null
        calculatedValuesProposed = idsProposed !== null && idsProposed.length > 1 ? proposal?.p_bucket_prop?.calculated_values : null
    } else {
        idsProposed = benchmark ? [benchmark?._id] : null
        calculatedValuesProposed = null
    }


    const menuItems = templates.map(item => {
        return {
            label: item.title,
            key: item.key,
            icon: <CloudDownloadOutlined/>
        }
    }).concat([{type: "divider"}]).concat([{
        label: "More options",
        key: "_edit_templates",
        icon: <SettingOutlined/>
    }]);

    const loadTemplates = () => {
        getReportBasics("client", basics => {
            let allPresets = basics.presets
            if (strictPresets.length > 0) {
                allPresets = basics.presets.filter(preset => strictPresets.includes(preset.key))
            }
            setTemplates(allPresets)
            let dt = basics.presets.find(preset => preset.key == defaultKey)
            if (!dt) {
                dt = basics.presets[0]
            }
            setDefaultTemplate(dt)
        });
    }

    const createReportOnClick = (sections) => {
        setLoading(true);
        let curSections = sections
        if (reportClass === "enrollment" && !notesInReport) {
            curSections = sections.filter(section => section !== "client_description")
        }

        console.log("sections", curSections)

        createReport({
                main_ids: ids,
                benchmark_ids: idsProposed,
                main_calculated_values: calculatedValues,
                benchmark_calculated_values: calculatedValuesProposed,
                client_id: proposal ? proposal._id : null,
                sections: curSections,
                show_volatility: true
            }, (response, error) => {
                if (error) {
                    message.error("Error while creating the report!");
                    setLoading(false);
                    return
                }
                let file_type = response.headers['content-type'];
                let file_name;
                file_name = `${proposal?.full_name} (${new Date().toLocaleString()}).pdf`;
                downloadFile(response.data, file_name, file_type, () => {
                    setLoading(false);
                })
            }
        )
    }

    const handleButtonClick = () => {
        if (templates && templates.length > 0) {
            createReportOnClick(defaultTemplate.sections)
        } else {
            message.error("No report templates found")
        }
    }

    const handleMenuClick = (e) => {
        if (e.key === "_edit_templates") {
            setOpenTemplateSettings(true)
        } else {
            let template = templates.find((item => item.key === e.key))
            if (template) {
                createReportOnClick(template.sections)
            } else {
                message.error("Cannot find chosen report template")
            }
        }
    }

    useEffect(() => {
        loadTemplates()
    }, [])


    return <>
        <Tooltip title={tooltip}>
            <Dropdown.Button
                onClick={handleButtonClick}
                overlay={<Menu onClick={handleMenuClick} items={menuItems}/>}
                type={type}
                size={size}
                disabled={disabled}
                loading={loading}
                style={{width: "auto"}}
            >
                {/*<CloudDownloadOutlined/>*/}
                Get PDF report
            </Dropdown.Button>
        </Tooltip>
         <ReportSettingsModal
            open={openTemplateSettings}
            onCancel={() => setOpenTemplateSettings(false)}
            proposal={proposal}
            reportClass={reportClass}
            idsMain={ids}
            idsProposed={idsProposed}
            getBenchmarkPortfolios={() => (idsProposed)}
            defaultTemplateKey={defaultKey}
        />
    </>
}


export default ReportDownloadButton

/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {Badge, Flex, List, theme, Typography} from "antd";


const {useToken} = theme;


const CustomLegendItem = ({
                              label,
                              value,
                              color = null,
                              customMarker = null,
                              highlight = false,
                              size=null
                          }) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    return <List.Item
        title={label}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
            display: "flex",
            alignItems: "center",
            border: "none",
            padding: `${token.paddingXXS}px ${token.paddingXS}px`,
            gap: token.paddingXXS,
            background: hover ? token.colorBgHoverBlue : "none",
            cursor: "default",
        }}
    >
        <Flex gap={"small"} align={"center"} style={{ overflow: "hidden"}}>
            {color && <Badge color={color}/>}
            {customMarker && customMarker}
            <Typography.Text ellipsis strong={highlight} style={{
                fontSize: size === "small" ? token.fontSizeSM : token.fontSize,
                lineHeight: 1.2
            }}>
                {label}
            </Typography.Text>
        </Flex>
        <div style={{
            height: 0.8 * token.fontSize,
            borderBottom: `rgba(0,0,0,0.05) 1px solid`,
            flexGrow: 1,
        }}/>
        <Typography.Text strong style={{
            whiteSpace: "nowrap",
            fontSize: size === "small" ? token.fontSizeSM : token.fontSize,
            lineHeight: 1.2
        }}>
            {value}
        </Typography.Text>
    </List.Item>
}


export default CustomLegendItem;

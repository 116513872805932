import React, {useState} from "react";
import {
    Button,
    Card,
    ConfigProvider,
    Divider,
    Flex,
    message,
    Popconfirm,
    theme,
    Typography
} from "antd";
import {
    CheckCircleOutlined,
    CreditCardFilled,
    PlusCircleOutlined,
} from "@ant-design/icons";
import {createFromPositions, deletePortfolio} from "@API/clientPortfolio";
import {setName, updatePortfolioFromPositions} from "@API/portfolio";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import StatsCard from "@components/retail/cards/StatsCard";
import NestedCard from "@components/retail/cards/NestedCard";
import CardHeader from "@components/retail/cards/CardHeader";
import FakeProgress
    from "@components/retail/portfolio/constructor/FakeProgress";
import PortfolioConstructor
    from "@components/retail/portfolio/constructor/PortfolioConstructor";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import SendToAdvisor from "@components/retail/portfolio/SendToAdvisor";
import {useParams} from "react-router-dom";


const {useToken} = theme;


export const AccountCard = ({account}) => {
    const {proposal, fetchProposal, setLoading, updateAiAnalyticalComments} = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(null);
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    const onEditClick = (e) => {
        setOpenDrawer(true);
    }

    const onDeleteClick = (e) => {
        setOpenProgress(true);
        deletePortfolio(proposal._id, 'cur', account._id, (result, error) => {
            if (!error) {
                if (proposal?.current_portfolios?.length > 1) {
                    fetchProposal(true, updateAiAnalyticalComments);
                } else {
                    fetchProposal(true);
                }
            } else {
                message.error("Something went wrong while deleting account!");
            }
            setOpenProgress(false);
        })
    }

    const updateAccount = (_positions, _name, _positionsChanged, _nameChanged) => {
        setTimeout(() => setRefreshTrigger(Math.random()), 500);
        setOpenDrawer(false);
        if (_positionsChanged && _nameChanged) {
            setOpenProgress(true);
            updatePortfolioFromPositions(account._id, _positions, (result, error) => {
                if (!error) {
                    setName(account._id, _name, (result, error) => {
                        if (error) {
                            message.error("Something went wrong while changing account title!")
                        }
                        fetchProposal(true, updateAiAnalyticalComments);
                    });
                } else {
                    // TODO: updateAiAnalyticalComments?
                    message.error("Something went wrong while updating the account!");
                    console.error(result);
                }
                setOpenProgress(false);
            })
        }
        if (_positionsChanged && !_nameChanged) {
            setOpenProgress(true);
            updatePortfolioFromPositions(account._id, _positions, (result, error) => {
                if (!error) {
                    fetchProposal(true, updateAiAnalyticalComments);
                } else {
                    // TODO: updateAiAnalyticalComments?
                    message.error("Something went wrong while updating the account!");
                    console.error(result);
                }
                setOpenProgress(false);
            })
        }
        if (!_positionsChanged && _nameChanged) {
            setLoading(true);
            setName(account._id, _name, (result, error) => {
                if (error) {
                    message.error("Something went wrong while changing account title!")
                }
                fetchProposal();
            });
        }
    }

    return <Flex
        gap={8}
        align={"flex-start"}
        style={{
            background: hover ? token.colorBgHoverBlue : token.colorBgGrey,
            border: "1px solid rgb(220, 220, 220)",
            borderRadius: token.borderRadiusLG,
            padding: "16px 24px 4px 16px",
            flexGrow: 1
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        <CheckCircleOutlined style={{fontSize: token.fontSizeLG}}/>
        <Flex vertical style={{flexGrow: 1}}>
            <Flex justify={"space-between"} gap={48} style={{
                fontWeight: token.fontWeightStrong,
                lineHeight: 1.2,
            }}>
                <span>{account.name}</span>
                {ValueFormatter.currency(account?.calculated_values?.market_value)}
            </Flex>
            <ConfigProvider theme={{
                components: {
                    Button: {
                        colorLink: token.colorTextDescription,
                        colorError: token.colorTextDescription,
                        fontSize: token.fontSizeSM,
                        paddingInline: 0
                    }
                }
            }}>
                <Flex gap={"middle"} align={"center"}>
                    <Popconfirm
                        title={"Are you sure to delete this account?"}
                        okText={"Yes"}
                        cancelText={"No"}
                        onCancel={(e) => {
                        }}
                        onConfirm={onDeleteClick}
                    >
                        <Button type={"link"} danger>
                            Delete
                        </Button>
                    </Popconfirm>
                    <Divider type={"vertical"} style={{height: 16}}/>
                    <Button onClick={onEditClick} type={"link"}>
                        Edit
                    </Button>
                    <span style={{
                        flexGrow: 1,
                        textAlign: "right",
                        color: token.colorTextDescription,
                        fontSize: token.fontSizeSM
                    }}>
                        {account?.positions?.length ?? 0}
                        {account?.positions?.length === 1 ? " position" : " positions"}
                    </span>
                </Flex>
            </ConfigProvider>
        </Flex>
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setTimeout(() => setRefreshTrigger(Math.random()), 500);
                setOpenDrawer(false);
            }}
            maskClosable={false}
            backButtonText={"Cancel"}
        >
            <PortfolioConstructor
                portfolio={account}
                allowEditName={true}
                applyButtonText={"Apply changes"}
                hoverable={false}
                onSubmit={updateAccount}
                refreshTrigger={refreshTrigger}
            />
        </MobileDrawer>
        <FakeProgress
            tip={"Updating portfolio analytics..."}
            open={openProgress}
            time={30000}
        />
    </Flex>
}


export const AddAccountLink = ({type="cur"}) => {
    const {proposal, id, fetchProposal, updateAiAnalyticalComments} = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(null);
    const portfolios = type === "cur"
        ? proposal?.current_portfolios
        : proposal?.proposed_portfolios;

    const createAccount = (_positions, _name) => {
        setTimeout(() => setRefreshTrigger(Math.random()), 500);
        setOpenDrawer(false);
        const postData = {
            positions: _positions,
            portfolio_name: _name,
            portfolio_type: type
        }
        setOpenProgress(true);
        createFromPositions(id, postData, (result, error) => {
            if (!error) {
                // TODO: update comments depending on type
                fetchProposal(true, updateAiAnalyticalComments);
            } else {
                message.error("Something went wrong while creating account!");
                console.error(result);
            }
            setOpenProgress(false);
        })
    }

    return <>
        <Button
            type={"link"}
            style={{padding: 0}}
            icon={<PlusCircleOutlined/>}
            onClick={() => setOpenDrawer(true)}
        >
            Add account
        </Button>
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setTimeout(() => setRefreshTrigger(Math.random()), 500);
                setOpenDrawer(false);
            }}
            maskClosable={false}
            backButtonText={"Cancel"}
        >
            <PortfolioConstructor
                name={portfolios ? `Account ${portfolios.length + 1}` : "Main account"}
                tip={
                    "Fill portfolio positions to create account. " +
                    "You can either upload file with positions or " +
                    "use asset search to fill them manually."
                }
                allowEditName={true}
                applyButtonText={"Save"}
                hoverable={false}
                onSubmit={createAccount}
                refreshTrigger={refreshTrigger}
            />
        </MobileDrawer>
        <FakeProgress
            tip={"Updating portfolio analytics..."}
            open={openProgress}
            time={30000}
        />
    </>
}


const AccountsCard = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const {token} = useToken();
    const {externalId} = useParams();
    const cv = proposal?.p_bucket_cur?.calculated_values;
    const portfolios = proposal?.current_portfolios;
    const full_name = externalId ? proposal?.full_name : "My portfolio";

    return <Card
        hoverable
        style={{
            background: token.colorBgGrey,
            borderColor: "rgb(220, 220, 220)",
            cursor: "default"
        }}
    >
        <NestedCard background={"white"}>
            <CardHeader
                title={full_name}
                icon={<CreditCardFilled/>}
                controls={externalId ? null : <SendToAdvisor/>}
            />
            <Flex gap="small" align={"stretch"} style={{margin: "0 0 16px 0"}}
                  wrap={"wrap"}>
                <StatsCard
                    title="market value"
                    value={is_null(cv?.market_value) ? "-" : ValueFormatter.currency(cv.market_value)}
                />
                <StatsCard
                    title="positions"
                    value={is_null(cv?.unique_pos_count) ? "-" : ValueFormatter.int_number(cv.unique_pos_count)}
                />
                <StatsCard
                    title="distribution rate"
                    value={is_null(cv?.annual_dividend) ? "-" : ValueFormatter.int_percent(cv.annual_dividend)}
                />
                <StatsCard
                    title="expense ratio"
                    value={is_null(cv?.expense_ratio) ? "-" : ValueFormatter.int_percent(cv.expense_ratio)}
                />

            </Flex>
        </NestedCard>
        <Typography.Title level={5}>
            Accounts
        </Typography.Title>
        <Flex vertical gap={"small"} style={{marginBottom: 16}}>
            {portfolios &&
                portfolios.map((portfolio) => {
                    return <AccountCard key={portfolio._id} account={portfolio}/>
                })
            }
        </Flex>
        <AddAccountLink/>
    </Card>
}


export default AccountsCard;

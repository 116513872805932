import React, {createContext, useContext, useEffect, useState} from "react";
import {useDynamicList, useUpdateEffect} from "ahooks";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {sendChatMessage} from "@API/ai";
import {message} from "antd";


const getDefaultMessageList = (messageList, proposalId, type) => {
    if (messageList && messageList.length > 0) {
        return messageList
    } else if (!proposalId && type === "enrollment") {
        return [{
            index: 0,
            role: "assistant",
            status: "info",
            content: (
                "Add client portfolio information on the " +
                "left so we have something to talk about."
            )
        }]
    } else {
        return [{
            index: 0,
            role: "assistant",
            status: "info",
            content: (
                "I'm ready to answer you questions!"
            )
        }]
    }
}


const predefinedMessages = [
    {
        key: "prosandcons",
        label: "Pros & cons",
        message: "Write 5 pros and 5 cons for the client portfolio",
    },
    {
        key: "suggestions",
        label: "Suggestions",
        message: "What changes can enhance the portfolio?",
    },
    {
        key: "points",
        label: "Talking points",
        message: "Give me 5 points to discuss with the client",
    },
    {
        key: "impression",
        label: "Overall impression",
        message: "What's your overall client impression?",
    },
    {
        key: "vs_macro",
        label: "In market context",
        message: "What you can say about the client's portfolio in the context of the current economic situation?"
    },
]


const getPredefinedMessageByKey = (key) => {
    return predefinedMessages.filter(obj => {return obj.key === key})[0]?.message
}


const AiChatContext = createContext(null);


const AiChatContextContextProvider = ({
                                          proposalId,
                                          type = "proposal",
                                          autoMessage = false,
                                          children
                                      }) => {
    const [waitingForResponse, setWaitingForResponse] = useState(false)
    const [newMsgTrigger, setNewMsgTrigger] = useState(0)

    let chatHistory = null
    if (type === "proposal") {
        const {conversation} = useHolisticoProposalContext()
        chatHistory = conversation
    }

    const {
        list: messageList,
        pop,
        push,
        resetList
    } = useDynamicList(getDefaultMessageList(chatHistory, proposalId, type));

    const sendMessage = (msg) => {
        if (!msg || msg === "" || /^\s*$/.test(msg)) {
            return
        }

        push({"content": msg, "role": "user"})
        setNewMsgTrigger((cur) => cur + 1)

        if (type === "enrollment" && !proposalId) {
            setTimeout(() => {
                push({
                    role: 'assistant',
                    content: "Client's portfolio information not ready. " +
                        "Nothing to talk about yet..."
                })
                setNewMsgTrigger((cur) => cur + 1)
            }, 500)

            setTimeout(() => {
                push({
                    role: 'assistant',
                    content: "Try uploading the portfolio. " +
                        "Or, if you've already done it, wait a little " +
                        "and ask me again"
                })
                setNewMsgTrigger((cur) => cur + 1)
            }, 1500)
            return
        }

        push({
            role: 'assistant-thinking',
            content: "AI is thinking..."
        })
        setNewMsgTrigger((cur) => cur + 1)

        try {
            setWaitingForResponse(true)
            sendChatMessage(proposalId, msg, "user", (data, err) => {
                setWaitingForResponse(false)
                pop()
                if (err) {
                    console.error(err)
                    message.error("ai communication error")
                } else {
                    console.debug(data)
                    push(data)
                    setNewMsgTrigger((cur) => cur + 1)
                }

            })

        } catch (e) {
            console.error(e)
            message.error("ai communication error")
            setWaitingForResponse(false)
            pop()
        }
    }

    useEffect(() => {
        if (type === "proposal") {
            resetList(getDefaultMessageList(chatHistory, proposalId, type))
        } else if (type === "enrollment") {
            resetList(getDefaultMessageList(chatHistory, proposalId, type))
        }
    }, [proposalId, chatHistory])
    //
    useUpdateEffect(() => {
        if (autoMessage) {
            let userMessage = getPredefinedMessageByKey('impression')
            sendMessage(userMessage)
        }
    }, [proposalId])

    return <AiChatContext.Provider
        value={{
            messageList,
            sendMessage,
            waitingForResponse,
            newMsgTrigger,
            predefinedMessages,
            getPredefinedMessageByKey
        }}
    >
        {children}
    </AiChatContext.Provider>
}


const useAiChatContext = () => {
    const context = useContext(AiChatContext)
    if (!context) {
        throw new Error(
            "useAiChatContext must be used within AiChatContextContextProvider"
        )
    }
    return context;
}


export {AiChatContextContextProvider, useAiChatContext}

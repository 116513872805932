import React from "react";
import {
    Checkbox,
    Form,
    Input,
    InputNumber,
    Rate,
    Segmented
} from "antd";
import {HeartOutlined,} from '@ant-design/icons';


const QuestionnaireForm = ({
                               withRules = false,
                               itemProps = {}
                           }) => {
    const requiredItemProps = withRules ? {
        rules: [{
            required: true,
            warningOnly: true,
            message: "Required for the risk ability estimation"
        }],
        required: true
    } : {}

    return <>
        <Form.Item label="Phone" name="phone" {...itemProps}>
            <Input placeholder={"+1"}/>
        </Form.Item>
        <Form.Item label="Email" name="email" {...itemProps}>
            <Input placeholder={"@"}/>
        </Form.Item>
        <Form.Item name={["questionnaire", "goals"]}
                   label={"Goals"} {...itemProps}>
            <Checkbox.Group style={{width: "100%", marginTop: "4px"}}>
                <Checkbox value="Retirement">
                    Save for retirement
                </Checkbox>
                <Checkbox value="College Savings">
                    Save for college
                </Checkbox>
                <Checkbox value="Income">
                    Generate income
                </Checkbox>
                <Checkbox value="Debt">
                    Pay off debt
                </Checkbox>
                <Checkbox value="Wealth Accumulation">
                    Accumulate wealth
                </Checkbox>
                <Checkbox value="Other">
                    Other
                </Checkbox>
            </Checkbox.Group>
        </Form.Item>
        <Form.Item
            label="Investment horizon"
            name={["questionnaire", "investment_time_horizon"]}
            {...requiredItemProps}
            {...itemProps}
        >
            <Segmented
                options={[
                    {label: "10 years or more", value: "long"},
                    {label: "6 - 9 years", value: "intermediate"},
                    {label: "5 years or less", value: "short"},
                ]}
                block
                style={{}}
                defaultValue={null}
            />
        </Form.Item>
        <Form.Item
            label="Non-investment income"
            name={["questionnaire", "non_investment_income"]}
            {...requiredItemProps}
            {...itemProps}
        >
            <Segmented
                options={[
                    {label: "Above spending", value: "above_spending"},
                    {label: "Below spending", value: "below_spending"},
                ]}
                defaultValue={null}
            />
        </Form.Item>
        <Form.Item
            label="Working status"
            name={["questionnaire", "wstatus"]}
            {...requiredItemProps}
            {...itemProps}
        >
            <Segmented
                options={[
                    {label: "Working", value: "working"},
                    {label: "Sabbatical", value: "not_working"},
                    {label: "Retired", value: "retired"},
                ]}
                defaultValue={null}
            />
        </Form.Item>
        <Form.Item
            label="Marital status"
            name={["questionnaire", "mstatus"]}
            {...requiredItemProps}
            {...itemProps}
        >
            <Segmented
                options={[
                    {label: "Married", value: "married"},
                    {label: "Single", value: "single"},
                    {label: "Divorced", value: "divorced"},
                ]}
                defaultValue={null}
            />
        </Form.Item>
        <Form.Item
            label="Health status"
            name={["questionnaire", "hstatus"]}
            required={withRules}
            rules={!withRules ? [] : [
                {
                    // Without custom validator it works weird
                    warningOnly: true,
                    validator: (_, value) =>
                        value
                            ?
                            Promise.resolve()
                            :
                            Promise.reject(new Error("Required for the risk ability estimation")),
                },
            ]}
            {...itemProps}
        >
            <Rate
                style={{fontSize: 24, color: '#F86767FF'}}
                character={<HeartOutlined/>}
                defaultValue={null}
            />
        </Form.Item>
        <Form.Item
            label="Birth year"
            name={["questionnaire", "birth_year"]}
            required={withRules}
            rules={!withRules ? [] : [
                {
                    // Without custom validator it works weird
                    warningOnly: true,
                    validator: (_, value) =>
                        value
                            ?
                            Promise.resolve()
                            :
                            Promise.reject(new Error("Required for the risk ability estimation")),
                },
            ]}
            {...itemProps}
        >
            <InputNumber
                placeholder={"Year"}
                style={{width: "100%"}}
            />
        </Form.Item>
        <Form.Item
            label="Retirement year"
            name={["questionnaire", "retirement_year"]}
            {...itemProps}
        >
            <InputNumber
                placeholder={"Year"}
                style={{width: "100%"}}
            />
        </Form.Item>
        <Form.Item
            label="Life expectancy"
            name={["questionnaire", "life_expectancy"]}
            {...itemProps}
        >
            <InputNumber placeholder={"95"} style={{width: "100%"}}/>
        </Form.Item>
        <Form.Item
            label="Current fee, %"
            name={["questionnaire", "current_fee"]}
            {...itemProps}
        >
            <InputNumber step={0.1} placeholder={"1.0"}
                         style={{width: "100%"}}/>
        </Form.Item>
    </>
}

export default QuestionnaireForm;

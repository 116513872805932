import {getAssetAllocation} from "@holistico/proposal/new/preview/StatsList";
import {Badge, Space, Tag, theme, Tooltip} from "antd";
import React from "react";
import {
    ShareAltOutlined,
    UserSwitchOutlined,
} from "@ant-design/icons";
import {useAuth} from "@hooks/AuthHook";


const {useToken} = theme;


const getAllocationTag = (calculatedValues) => {
    let allocationTag = null
    const assetAllocation = getAssetAllocation(calculatedValues)
    if (assetAllocation[0] > 0.9) {
        allocationTag = "Stocks"
    } else if (assetAllocation[1] > 0.9) {
        allocationTag = "Bonds"
    }
    return allocationTag
}

const getAdditionalTags = (calculated_values) => {
    if (!calculated_values || !calculated_values['asset_class']) {
        return <Tag color="#cccccc">
            No data
        </Tag>
    }
    const allocationTag = getAllocationTag(calculated_values)
    return <Space size={[0, 8]} wrap>
        {calculated_values.annual_dividend >= 2.0
            && <Tooltip title={"High dividends or coupons"}>
                <Tag color="#2db7f5">
                    Dividends
                </Tag>
            </Tooltip>
        }
        {calculated_values.expense_ratio >= 0.01
            && <Tooltip title={"Contains ETFs or mutual funds"}>
                <Tag color="#f50">
                    ETFs
                </Tag>
            </Tooltip>
        }
        {allocationTag === "Stocks"
            && <Tooltip title={"High share of equities"}>
                <Tag color="#3b5999">
                    Stocks
                </Tag>
            </Tooltip>
        }
        {allocationTag === "Bonds"
            && <Tooltip title={"High share of fixed-income assets"}>
                <Tag color="#bd893a">
                    Bonds
                </Tag>
            </Tooltip>
        }
    </Space>
}

const getProposalStatusKey = (fullProposal) => {
    let status = "success"
    if (fullProposal.p_bucket_prop?.calculated_values?.market_value) {

    } else if (fullProposal.p_bucket_cur?.calculated_values?.market_value) {
        if (fullProposal?.questionnaire?.risk_profile?.ability) {
            status = "processing"
        } else {
            status = "warning"
        }
    } else {
        status = "error"
    }
    return status
}

const getStatusBadge = (fullProposal, customText = null, textInTooltip) => {
    let status = "success"
    let text = "New prospect"
    if (fullProposal.p_bucket_prop?.calculated_values?.market_value) {
        text = "Proposal made"
    } else if (fullProposal.p_bucket_cur?.calculated_values?.market_value) {
        if (fullProposal?.questionnaire?.risk_profile?.ability) {
            status = "processing"
            text = "Awaiting proposal"
        } else {
            status = "warning"
            text = "Risk profile not set"
        }
    } else {
        status = "error"
        text = "New prospect"
    }

    if (customText != null) {
        text = customText
    }

    if (textInTooltip) {
        return <Tooltip title={text}>
            <Badge status={status}/>
        </Tooltip>
    } else {
        return <Badge status={status} text={text}/>
    }
}

const getStatus = (fullProposal) => {
    if (fullProposal.p_bucket_prop?.calculated_values?.market_value) {
        return "success"
    } else if (fullProposal.p_bucket_cur.calculated_values?.market_value) {
        if (fullProposal?.questionnaire?.risk_profile?.ability) {
            return "404"
        } else {
            return "403"
        }
    } else {
        return "500"
    }
}

const getRiskScore = (fullProposal, type = "prop") => {
    if (type === "prop") {
        if (fullProposal.p_bucket_prop?.calculated_values?.risk?.risk_score) {
            return fullProposal.p_bucket_prop.calculated_values.risk.risk_score
        }
    } else {
        if (fullProposal.p_bucket_cur?.calculated_values?.risk?.risk_score) {
            return fullProposal.p_bucket_cur.calculated_values.risk.risk_score
        }
    }
    return "?"
}


const ProposalStatusBadge = ({proposal, customText = null, children, offset=null}) => {
    const mkva_cur = proposal?.p_bucket_cur?.calculated_values?.market_value;
    const mkva_prop = proposal?.p_bucket_prop?.calculated_values?.market_value;
    const risk_ability = proposal?.questionnaire?.risk_profile?.ability;
    const risk_tolerance = proposal?.questionnaire?.risk_profile?.behavioral;

    let status;
    let text;
    if (mkva_prop) {
        status = "success";
        text = "Proposal made";
    } else if (mkva_cur && (risk_ability || risk_tolerance)) {
        status = "processing";
        text = "Awaiting proposal";
    } else if (mkva_cur && !risk_ability && !risk_tolerance) {
        status = "warning";
        text = "Risk profile not set";
    } else {
        status = "error";
        text = "New proposal";
    }

    if (customText !== null) {
        text = customText;
    }

    return (
        children
            ? <Tooltip title={text}>
                <Badge dot status={status} offset={offset}>
                    {children}
                </Badge>
            </Tooltip>
            : <Badge status={status} text={text}/>
    )
}

const ProposalCardRibbon = ({proposal, children}) => {
    const {user} = useAuth();
    const {token} = useToken();

    let icon = null;
    let text = null;
    let color = null;

    if (user.email !== proposal['owner'] && proposal['client_type'] !== 'retail_client') {
        icon = <UserSwitchOutlined/>;
        text = `Shared by ${proposal.owner ?? " your associate"}`;
        color = token.colorBorder;
    }
    // if (user.email !== proposal['owner'] && proposal['client_type'] === 'retail_client') {
    if (proposal['client_type'] === 'retail_client') {
         icon = <ShareAltOutlined />;
         text = `Shared by ${proposal.full_name ?? proposal.owner ?? proposal.email ?? " a client"}`;
         color = token.colorStats;
    }

    return (
        text
            ? <Badge.Ribbon
                text={<Tooltip title={text}>{icon}</Tooltip>}
                color={color}
                placement={"end"}
            >
                {children}
            </Badge.Ribbon>
            : children
    )

}

export {
    getStatus,
    getProposalStatusKey,
    getStatusBadge,
    getRiskScore,
    getAdditionalTags,
    getAllocationTag,
    ProposalStatusBadge,
    ProposalCardRibbon
}

import React from "react";
import {Flex, theme, Typography} from "antd";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
} from "@ant-design/icons";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import ValueFormatter from "@global/ValueFormatter";
import StaticInfo from "@global/StaticInfo";


const {useToken} = theme;


const PerformanceStatisticsItem = ({title, description, percent, amount, color, up=true}) => {
    const {token} = useToken();

    return <div style={{maxWidth: 150}}>
        <Flex gap={"middle"} align={"baseline"} style={{color: color}}>
            <Flex vertical>
                <Flex wrap={"wrap"} justify={"flex-end"} style={{
                    fontSize: token.fontSizeHeading1,
                    lineHeight: 1.2,
                }}>
                    {up
                        ? <ArrowUpOutlined style={{width: 50}}/>
                        : <ArrowDownOutlined style={{width: 50}}/>
                    }
                    {ValueFormatter.softPercent(percent)}
                </Flex>
                <div style={{
                    paddingLeft: 50,
                    fontSize: token.fontSizeHeading4,
                    lineHeight: 1.2
                }}>
                    {ValueFormatter.compactCurrency(amount)}
                </div>
            </Flex>
        </Flex>
        <Typography.Text style={{
            display: "block",
            marginTop: token.marginXL,
            fontSize: token.fontSizeXS,
            textTransform: "uppercase",
            lineHeight: 1,
            letterSpacing: 1,
        }}>
            {title}
        </Typography.Text>
        <Typography.Paragraph style={{
            color: token.colorTextDescription,
            fontSize: token.fontSizeXS,
            marginTop: token.marginSM
        }}>
            {description}
        </Typography.Paragraph>
    </div>
}


const PerformanceStatistics = ({riskScore}) => {
    const {proposal} = useHolisticoProposalContext();
    const {token} = useToken();

    const riskReturns = StaticInfo.riskReturns1Y[riskScore];
    const mkval = proposal?.p_bucket_cur?.calculated_values?.market_value ?? 0;
    const lowerBoundPercent = riskReturns["Lower Bound"] < 0 ? -riskReturns["Lower Bound"] : 0;
    const lowerBoundAmount = lowerBoundPercent * mkval;
    const upperBoundPercent = riskReturns["Upper Bound"];
    const upperBoundAmount = upperBoundPercent * mkval;

    return <>
        {proposal
            && <Flex gap={"large"}>
                <PerformanceStatisticsItem
                    title={"Possible loss"}
                    description={"Annual performance can fall below this level with 5% probability"}
                    percent={lowerBoundPercent}
                    amount={lowerBoundAmount}
                    color={token.colorError}
                    up={false}
                />
                <PerformanceStatisticsItem
                    title={"Possible gain"}
                    description={"Annual performance can rise above this level with 5% probability"}
                    percent={upperBoundPercent}
                    amount={upperBoundAmount}
                    color={token.colorSuccess}
                    up={true}
                />
            </Flex>
        }
    </>
}

export default PerformanceStatistics;

import React, {useEffect, useState} from "react";
import {Modal, Progress, theme, Typography} from "antd";
import ValueFormatter from "@global/ValueFormatter";


const {useToken} = theme;


const SimpleProgressModal = ({description, open}) => {
    const [loadPercent, setLoadPercent] = useState(0);
    const {token} = useToken();

    const modalProps = {
        className: "holistico",
        width: 750,
        closable: false,
        maskClosable: false,
        title: null,
        footer: null,
        style: {background: "none"}
    }

    useEffect(() => {
            if (open) {
                setLoadPercent(0)
                let progressInterval = progressIntervalCaller();
                return () => {
                    clearInterval(progressInterval);
                }
            }
        },
        [open]
    )

    const progressIntervalCaller = () => {
        let progress = 0;
        return setInterval(() => {
            if (progress < 95) {
                progress = progress + Math.random() * 2;
            }
            setLoadPercent(ValueFormatter.round(progress, 0))
        }, 700)
    }

    return <Modal {...modalProps} open={open} className={"holistico"}>
        <>
            <Typography.Text>{description}</Typography.Text>
            <Progress
                percent={loadPercent}
                status="active"
                strokeColor={token.colorPrimary}
            />
        </>
    </Modal>
}

export default SimpleProgressModal;
import React from 'react';
import {
    ConfigProvider,
    Flex,
    Slider,
    theme,
    Typography
} from "antd";


const {useToken} = theme;


const RiskToleranceSlider = ({defaultValue, onChange, style={}}) => {
    const {token} = useToken();

    const color = token.colorStats;
    const textStyle = {
        textTransform: 'uppercase',
        letterSpacing: '2px',
        color: token.colorTextDescription,
        fontSize: token.fontSizeSM
    }

    return <Flex
        align={"stretch"}
        justify={"center"}
        style={{
            paddingBottom: token.padding,
            ...style
        }}
    >
        <Flex
            vertical
            justify={"space-around"}
            align={"flex-start"}
        >
            <Typography.Text style={textStyle}>
                High risk
            </Typography.Text>
            <Typography.Text style={textStyle}>
                Moderate
            </Typography.Text>
            <Typography.Text style={textStyle}>
                Low risk
            </Typography.Text>
        </Flex>
        <ConfigProvider
            theme={{
                components: {
                    Slider: {
                        // handleColor: color,
                        // handleActiveColor: color,
                        // trackBg: color,
                        // trackHoverBg: color,
                        // dotActiveBorderColor: color,
                        // colorPrimaryBorderHover: color,
                        handleSize: 24,
                        handleSizeHover: 24,
                        railSize: 8,
                        dotSize: 16,
                        handleLineWidth: 2
                    }
                }
            }}>
            <Slider
                style={{whiteSpace: 'nowrap', width: 150}}
                vertical={true}
                defaultValue={defaultValue}
                min={0}
                max={99}
                included={true}
                marks={{
                    0: {label: 'Cash'},
                    25: {label: 'Treasuries'},
                    40: {label: 'Corporate Bonds'},
                    60: {label: 'S&P 500'},
                    88: {label: 'FAANG'},
                }}
                onChange={onChange}
                tooltip={{placement: "right"}}
            />
        </ConfigProvider>
    </Flex>
}

export default RiskToleranceSlider;

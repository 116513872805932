import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Carousel,
    Radio,
    Form,
    Typography,
    Tag,
    Flex,
    Card,
    theme
} from "antd";
import {
    LeftOutlined,
    RightOutlined
} from "@ant-design/icons";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import questions
    from "@holistico/proposal/view/client/behavioral_quiz/Questions";


const {useToken} = theme;


const BehavioralQuizForm = ({onFinish, saving=false}) => {
    const {proposal} = useHolisticoProposalContext();
    const [form] = Form.useForm();
    const [slide, setSlide] = useState(0);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [changed, setChanged] = useState(false);
    const carouselRef = useRef();
    const {token} = useToken();

    useEffect(() => {
        form.setFieldsValue(proposal?.questionnaire?.behavioral_quiz);
        setChanged(false);
        let last = 0;
        const answers = proposal?.questionnaire?.behavioral_quiz?.answers;
        if (answers) {
            const _max = Math.max(...Object.keys(answers).map(x => Number(x)));
            if (_max && _max > 0) {
                last = _max - 1;
            }
        }
        setSlide(last);
        if (carouselRef.current) {
            carouselRef.current.goTo(last);
        }
    }, [proposal])

    const updateNextButtonState = () => {
        setNextDisabled(!form.getFieldValue(["answers", `${slide + 1}`]) || slide >= questions.length - 1);
    }

    const onSlide = (index) => {
        setSlide(index);
    }

    useEffect(() => {
        updateNextButtonState();
    }, [slide])

    return <>
        <Form form={form}
              layout={'vertical'}
              initialValues={proposal?.questionnaire?.behavioral_quiz}
              onValuesChange={() => {
                  updateNextButtonState();
                  setChanged(true);
              }}
              onFinish={onFinish}
        >
            <Card
                type={"inner"}
                bordered={false}
                style={{background: token.colorBgGrey}}
            >
                <Carousel
                    dots={false}
                    swipe={false}
                    draggable={false}
                    infinite={false}
                    ref={carouselRef}
                    afterChange={onSlide}
                    initialSlide={slide}
                >
                    {questions.map((question) => {
                        return <div key={question.key}>
                            <Typography.Title
                                level={5}
                                style={{marginTop: 0, marginBottom: token.margin}}
                            >
                                <Tag color={"rgba(0,0,0,0.5)"}>
                                    {question.key} / {questions.length}
                                </Tag>
                                {question["title"]}
                            </Typography.Title>
                            <Form.Item
                                name={["answers", `${question.key}`]}
                            >
                                <Radio.Group size="large">
                                    <Flex vertical gap={"small"}>
                                        {question["options"].map((option) => {
                                            return <Radio
                                                value={option["value"]}
                                                key={option["value"]}>
                                                {option["label"]}
                                            </Radio>
                                        })}
                                    </Flex>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    })}
                </Carousel>
            </Card>
            <Flex gap={"small"} wrap={"wrap"} style={{marginTop: token.marginSM}}>
                <Button
                    size={"large"}
                    onClick={() => {
                        carouselRef.current.prev()
                    }}
                    disabled={slide === 0}
                    style={{
                        width: "30%",
                        maxWidth: 180
                    }}
                >
                    <LeftOutlined/>
                    Back
                </Button>
                <Button
                    size={"large"}
                    onClick={() => {
                        carouselRef.current.next()
                    }}
                    disabled={nextDisabled}
                    style={{
                        width: "30%",
                        maxWidth: 180
                    }}
                >
                    Next
                    <RightOutlined/>
                </Button>
                <Button
                    size={"large"}
                    htmlType="submit"
                    type="primary"
                    disabled={!changed}
                    loading={saving}
                    style={{
                        width: "30%",
                        maxWidth: 180
                    }}
                >
                    Save
                </Button>
            </Flex>
        </Form>
    </>
}

export default BehavioralQuizForm;

/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {List, Progress, theme, Flex} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import ChartStyles from "@global/ChartStyles";
import ChartContainer from "@holistico/chart/ChartContainer";


const {useToken} = theme;


const creditRatingSortOrder = {
    "AAA": 1,
    "AA": 2,
    "A": 3,
    "BBB": 4,
    "BB": 5,
    "B": 6,
    "Below B": 7
}


const CreditRatingChart = ({allocation}) => {
    const [data, setData] = useState([]);
    const {token} = useToken();

    const empty = !data || data.length === 0;

    useEffect(() => {
        if (!allocation) {
            return
        }
        let _data = Object.keys(allocation).map(key => {
            return {
                label: key,
                value: allocation[key]
            }
        })
        if (_data === data) {
            return
        }
        _data.sort(function (a, b) {
            return (creditRatingSortOrder[a.label] - creditRatingSortOrder[b.label])
                || a.label.localeCompare(b.label);
        });
        setData(_data)
    }, [allocation])


    return <ChartContainer
        legendDataSource={data}
        legendRenderItem={item =>
            <List.Item key={item['label']} style={{
                border: "none",
                padding: token.paddingXS,
            }}>
                <Flex style={{width: "100%"}}>
                    <div style={{minWidth: 80}}>
                        {item['label']}
                    </div>
                    <Progress
                        size="small"
                        percent={item["value"] * 100}
                        strokeColor={ChartStyles.get_rating_color(item["label"])}
                        format={percent => {
                            return ValueFormatter.oneDigitPercent(percent / 100)
                        }}
                        showInfo={false}
                        style={{flexGrow: 1}}
                    />
                    <div className={"value"}
                         style={{minWidth: "60px", marginLeft: "8px"}}>
                        {ValueFormatter.oneDigitPercent(item["value"])}
                    </div>
                </Flex>
            </List.Item>
        }
        empty={empty}
        emptyText={"No credit rating information"}
    />
}

export default CreditRatingChart;

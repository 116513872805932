import React, {useRef, useState} from "react";
import {useSize} from "ahooks";
import {Card, theme, Typography} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import CardHeader from "@components/retail/cards/CardHeader";
import HRadioGroup from "@holistico/aux/HRadioGroup";
import {
    DescriptionPopover, FundamentalsItemJoint
} from "@holistico/proposal/view/fundamentals/FundamentalsItem";
import AssetClassPie from "@holistico/chart/AssetClassPie";


const {useToken} = theme;


export const assetAllocationDescription = <>
    <Typography.Paragraph>
        Asset allocation is of paramount importance for a number of
        reasons. Firstly, it's the strategy that enables us to
        distribute investments across various asset classes such as
        stocks, bonds, and cash. This allocation is crucial in
        determining the risk and return of your investment
        portfolio. Essentially, it's what allows us to balance the
        risk-reward tradeoff in accordance with our individual
        financial goals and risk tolerance.
    </Typography.Paragraph>
    <Typography.Paragraph>
        Furthermore, not all asset classes perform in the same way
        at the same time. Some may rise while others may fall. By
        diversifying our investments across different asset classes,
        we are able to mitigate the potential for substantial
        losses. This diversification reduces the portfolio's
        susceptibility to market volatility and provides us with
        several avenues for potential profit.
    </Typography.Paragraph>
    <Typography.Paragraph>
        Lastly, the asset allocation is a reflection of our
        investment time horizon and financial goals. For instance, a
        younger investor with a long-term goal might have a greater
        proportion of riskier assets, like equities, in his or her
        portfolio. On the other hand, an investor nearing retirement
        may choose to invest more heavily in lower-risk assets, like
        bonds or cash equivalents, to safeguard their accumulated
        wealth.
    </Typography.Paragraph>
    <Typography.Paragraph>
        In a nutshell, asset allocation is the cornerstone of
        successful investing. It's a strategic approach that allows
        us to manage risk while maximizing returns. Without it, we'd
        simply be gambling with our hard-earned money.
    </Typography.Paragraph>
</>


const AssetAllocation = () => {
    const {
        proposal,
        loadingAiAnalyticalComments,
        aiAnalyticalComments
    } = useHolisticoProposalContext();
    const [show, setShow] = useState("both");
    const ref = useRef(null);
    const size = useSize(ref);
    const {token} = useToken();

    const vertical = size?.width < 800;

    return <Card hoverable ref={ref} style={{
        cursor: "default",
        borderColor: "rgb(220, 220, 220)",
        overflow: "hidden",
    }}>
        <CardHeader title={<>
            Asset allocation
            <DescriptionPopover>
                {assetAllocationDescription}
            </DescriptionPopover>
        </>}/>
        <HRadioGroup
            options={[
                {label: "All", value: "both"},
                {label: "Current", value: "current"},
                {label: "Proposed", value: "proposed"},
            ]}
            value={show}
            onChange={(value) => setShow(value)}
        />
        <FundamentalsItemJoint
            chartCurent={
               <AssetClassPie
                        allocation={proposal?.p_bucket_cur?.calculated_values?.asset_class}
                        height={250}
                    />
            }
            chartPropposed={
                <AssetClassPie
                        allocation={proposal?.p_bucket_prop?.calculated_values?.asset_class}
                        height={250}
                    />
            }
            commentCurrent={aiAnalyticalComments?.asset_class ?? "No asset class information"}
            commentProposed={"No asset class information"}
            aiCommentLoading={loadingAiAnalyticalComments}
            vertical={vertical}
            commonChart={false}
            show={show}
            style={{marginTop: token.margin}}
        />
    </Card>
}


export default AssetAllocation

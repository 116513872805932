import React from "react";
import {
    Card,
    Button,
    Typography,
    Tooltip,
    Badge,
    ConfigProvider,
    theme,
    Flex,
    List
} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import CustomLegendItem from "@holistico/chart/CustomLegend";
import {getSScore, getAssetAllocation, getAssetAllocationLabel} from "@holistico/proposal/new/preview/StatsList";
import NestedCard from "@components/retail/cards/NestedCard";


const {useToken} = theme;


const ModelCardHeader = ({model}) => {
    const {token} = useToken();

    return <Flex>
        <Typography.Title
            ellipsis={{rows: 2}}
            level={5}
            style={{
                margin: 0,
                lineHeight: 1.2,
                marginBottom: token.marginXS
            }}
        >
            {model.name}
            {model.ticker && `, ${model.ticker}`}
        </Typography.Title>
    </Flex>
}


const ModelCard = ({model, onAdd, onRemove, idInProposal=[]}) => {
    const {token} = useToken();

    const assetAllocation = getAssetAllocation(model.calculated_values)
    let allocationTag = null
    if (assetAllocation[0] > 0.9) {
        allocationTag = "Stocks"
    } else if (assetAllocation[1] > 0.9) {
        allocationTag = "Bonds"
    }

    return <ConfigProvider theme={{
        token: {
            fontSize: token.fontSizeSM,
        }
    }}>
        <Card
            // hoverable
            style={{
                marginLeft: token.marginXXS,
                marginRight: token.marginXXS,
                background: token.colorBgGrey,
                // cursor: "default",
                // marginTop: 4,
                // marginBottom: 4
            }}
        >
            <NestedCard background={"white"} style={{
                minHeight: 100,
                paddingTop: token.padding,
                paddingBottom: token.padding,
                marginBottom: token.padding,
            }}>
                <ModelCardHeader
                    model={model}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    idInProposal={idInProposal}
                />

                <Flex wrap={"wrap"}>
                    {model.calculated_values.expense_ratio >= 0.01
                        && <Tooltip title={"Contains ETFs or mutual funds"}>
                            <Flex
                                gap={token.paddingXXS}
                                style={{
                                    fontSize: token.fontSizeSM,
                                    background: "white",
                                    // background: token.colorBgGrey,
                                    borderRadius: token.borderRadius,
                                    // padding: `0 ${token.paddingXS}px`,
                                    marginRight: token.marginSM,
                                     whiteSpace: "nowrap"
                                }}
                            >
                                <Badge color="#f50"/>
                                ETFs
                            </Flex>
                        </Tooltip>
                    }
                    {allocationTag === "Stocks"
                        && <Tooltip title={"High share of equities"}>
                            <Flex
                                gap={token.paddingXXS}
                                style={{
                                    fontSize: token.fontSizeSM,
                                    borderRadius: token.borderRadius,
                                    marginRight: token.marginSM,
                                     whiteSpace: "nowrap"
                                }}
                            >
                                <Badge color="#3b5999"/>
                                Stocks
                            </Flex>
                        </Tooltip>
                    }
                    {allocationTag === "Bonds"
                        && <Tooltip title={"High share of fixed-income assets"}>
                            <Flex
                                gap={token.paddingXXS}
                                style={{
                                    fontSize: token.fontSizeSM,
                                    borderRadius: token.borderRadius,
                                    marginRight: token.marginSM,
                                     whiteSpace: "nowrap"
                                }}
                            >
                                <Badge color="#bd893a"/>
                                Bonds
                            </Flex>
                        </Tooltip>
                    }
                    {model.calculated_values.annual_dividend >= 2.0
                        && <Tooltip title={"High dividends or coupons"}>
                            <Flex
                                gap={token.paddingXXS}
                                style={{
                                    fontSize: token.fontSizeSM,
                                    borderRadius: token.borderRadius,
                                    marginRight: token.marginSM,
                                     whiteSpace: "nowrap"
                                }}
                            >
                                <Badge color="#2db7f5"/>
                                Dividends
                            </Flex>
                        </Tooltip>
                    }
                    {idInProposal.length > 0
                         && <Tooltip title={"This model is in the proposed portfolio"}>
                            <Flex
                                gap={token.paddingXXS}
                                style={{
                                    fontSize: token.fontSizeSM,
                                    borderRadius: token.borderRadius,
                                    marginRight: token.marginSM,
                                    whiteSpace: "nowrap"
                                }}
                            >
                                <Badge color={token.colorStats}/>
                                In proposal
                            </Flex>
                        </Tooltip>
                     }
                </Flex>

            </NestedCard>
            <List style={{
                marginLeft: -token.paddingXS,
                marginRight: -token.paddingXS,
            }}>
                <CustomLegendItem
                    label={'Risk score'}
                    value={model.calculated_values?.risk?.risk_score}
                />
                <CustomLegendItem
                    label={'Composite rating'}
                    value={getSScore(model.calculated_values)}
                />
                <CustomLegendItem
                    label={'Stocks / Bonds'}
                    value={getAssetAllocationLabel(model.calculated_values)}
                />
                <CustomLegendItem
                    label={'Distribution rate'}
                    value={ValueFormatter.percent(model.calculated_values['annual_dividend'] / 100)}
                />
                <CustomLegendItem
                    label={'Expense Ratio'}
                    value={ValueFormatter.percent(model.calculated_values['expense_ratio'] / 100)}
                />
            </List>
            {idInProposal.length > 0
                ?
                <Button
                    type={"link"}
                    style={{padding: 0, marginTop: token.padding}}
                    onClick={() => {
                        onRemove(idInProposal.slice(-1))
                    }}
                    size="small"
                >
                    Remove from proposal
                </Button>
                :
                <Button
                    type={"link"}
                    style={{padding: 0, marginTop: token.padding}}
                    onClick={() => {
                        onAdd(model._id)
                    }}
                    size="small"
                >
                    Add to proposal
                </Button>
            }
        </Card>
    </ConfigProvider>
}

export default ModelCard;

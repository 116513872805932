import React, {useEffect, useState} from "react";
import {
    Badge,
    Button,
    Card,
    Flex,
    theme,
    Typography
} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import RiskResult from "@holistico/proposal/view/client/RiskResult";
import {
    getToleranceLevelByRiskScore
} from "@holistico/proposal/view/client/utils";
import ModelsCarousel
    from "@holistico/proposal/view/client/ModelsCarousel";
import RiskToleranceSlider
    from "@holistico/proposal/view/client/risk_tolerance/RiskToleranceSlider";
import PerformanceStatistics
    from "@holistico/proposal/view/client/risk_tolerance/PerformanceStatistics";
import RiskProfilePanel from "@holistico/proposal/view/client/RiskProfilePanel";
import NestedCard from "@components/retail/cards/NestedCard";
import CardHeader from "@components/retail/cards/CardHeader";


const {useToken} = theme;


const RiskToleranceSliderPanel = ({}) => {
    const {proposal, updateQuestionnaire} = useHolisticoProposalContext();
    const [saving, setSaving] = useState(false);
    const [sliderValue, setSliderValue] = useState(60);
    const [sliderDefaultValue, setSliderDefaultValue] = useState(null);
    const {token} = useToken();

    const willingnessScore = proposal?.questionnaire?.risk_profile?.willingness;

    useEffect(() => {
        if (proposal) {
            const current = proposal?.p_bucket_cur?.calculated_values?.risk?.risk_score;
            const ability = proposal?.questionnaire?.risk_profile?.ability;
            const behavioral = proposal?.questionnaire?.risk_profile?.behavioral;
            setSliderValue(willingnessScore ?? behavioral ?? ability ?? current ?? 60);
            setSliderDefaultValue(willingnessScore ?? behavioral ?? ability ?? current ?? 60);
        }
    }, [proposal])

    const onChange = (value) => {
        setSliderValue(value);
    };

    const onSave = () => {
        if (sliderValue) {
            setSaving(true);
            updateQuestionnaire({risk_profile: {willingness: sliderValue}}, true, true, () => {
                setSaving(false);
            })
        }
    }

    return <>
        {sliderDefaultValue &&
            <Flex gap={36} wrap={"wrap"}>
                <div
                    style={{
                        background: token.colorBgGrey,
                        borderRadius: token.borderRadiusLG,
                        flexGrow: 1,
                        paddingTop: token.paddingSM,
                        paddingBottom: token.paddingSM,
                        overflow: "hidden"
                    }}
                >
                    <RiskToleranceSlider
                        defaultValue={sliderDefaultValue}
                        onChange={onChange}
                        style={{
                            height: 300,
                            width: 280,
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    />
                </div>
                <Flex gap={"small"} vertical style={{flexGrow: 5, marginTop: token.padding}}>
                    <PerformanceStatistics riskScore={sliderValue}/>
                    <Flex gap={"small"} style={{height: token.fontSize * 1.5}}>
                        {willingnessScore !== sliderValue
                            ? <>
                                <Badge status={"processing"} color={token.colorWarning}/>
                                Save to apply changes
                            </>
                            : <div/>
                        }
                    </Flex>
                    <Button
                        type="primary"
                        size="large"
                        style={{width: 150}}
                        onClick={onSave}
                        loading={saving}
                    >
                        Save
                    </Button>
                </Flex>
            </Flex>
        }
    </>
}


const RiskTolerance = () => {
    const {proposal, updateQuestionnaire} = useHolisticoProposalContext();
    const {token} = useToken();

    const willingnessScore = proposal?.questionnaire?.risk_profile?.willingness;
    const willingnessLevel = getToleranceLevelByRiskScore(willingnessScore);

    return <>
        {proposal &&
            <>
                <RiskProfilePanel selected={"willingness"}/>
                <Card
                    hoverable
                    style={{
                        cursor: "default",
                        background: token.colorBgGreyDark,
                        borderColor: "rgb(220, 220, 220)"
                    }}
                >
                    <NestedCard background={token.colorBgGrey}>
                        <NestedCard background={"white"}>
                            <CardHeader
                                title="Risk willingness"
                                style={{marginBottom: token.marginXXS}}
                            />
                            <Typography.Text style={{marginBottom: token.margin}}>
                                Use slider below to choose the risk that the client agrees to take
                            </Typography.Text>
                            <RiskToleranceSliderPanel/>
                        </NestedCard>
                        <RiskResult
                            title={willingnessScore
                                ? `Risk willingness score: ${willingnessScore}`
                                : "Risk willingness score not set"
                            }
                            description={willingnessScore
                                ? `${willingnessLevel} risk willingness`
                                : "Save results above to get risk willingness score"
                            }
                            success={!is_null(willingnessScore)}
                            style={{padding: `${token.paddingLG}px 0`}}
                        />
                    </NestedCard>
                    <Typography.Title level={5}>
                        Suitable models
                    </Typography.Title>
                    <ModelsCarousel
                        riskScore={willingnessScore}
                        active={!!willingnessScore}
                        inactiveMessage={"Models will become available after confirming risk willingness score"}
                        style={{
                            minHeight: 340,
                            marginRight: -token.margin,
                            marginLeft: -token.margin,
                        }}
                    />
                </Card>
            </>
        }
    </>
}


export default RiskTolerance;

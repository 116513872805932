import React, {useEffect, useRef, useState} from "react";
import {useDebounce, useSize, useUpdateEffect} from "ahooks";
import {useNavigate, useParams} from 'react-router-dom';
import {
    Button,
    Card,
    theme,
    Flex,
    Form,
    Input,
    Divider,
    Typography,
    message,
} from "antd";
import {EditOutlined, UserAddOutlined} from "@ant-design/icons";
import Constants from "@global/Constants";
import {createClient} from "@API/clients";
import {
    HolisticoProposalContextProvider,
    useHolisticoProposalContext
} from "@hooks/ProposalContext";
import AiSidebarContainer from "@holistico/ai/sidebar/AiSidebarContainer";
import CardHeader from "@components/retail/cards/CardHeader";
import ReportDownloadButton
    from "@holistico/proposal/view/report/ReportDownloadButton";
import {
    AccountCard,
    AddAccountLink
} from "@components/retail/portfolio/AccountsCard";
import NestedCard from "@components/retail/cards/NestedCard";
import PortfolioPreview from "@holistico/proposal/new/PortfolioPreview";
import {
    getFormLayoutPropsByWidth, getRiskScoreFromQuestionnaire
} from "@holistico/proposal/view/client/questionnaire/Questionnaire";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import QuestionnaireForm from "@holistico/proposal/new/QuestionnaireForm";


const {useToken} = theme;


const QuestionnaireDrawer = ({open, onClose}) => {
    const cardRef = useRef(null);
    const size = useSize(cardRef);
    const [props, auxProps]  = getFormLayoutPropsByWidth(size?.width);

    return <MobileDrawer
        open={open}
        onClose={onClose}
    >
        <Card ref={cardRef}>
            <CardHeader
                title={"Questionnaire"}
                controls={
                    <Button type="primary" onClick={onClose}>
                        Save
                    </Button>
                }
            />
            <Divider/>
            <QuestionnaireForm
                withRules={false}
                itemProps={props}
            />
        </Card>
    </MobileDrawer>
}


const PortfolioAccountsPanel = ({type}) => {
    const {proposal} = useHolisticoProposalContext();

    const portfolios = proposal?.current_portfolios;

    return <>
        <Typography.Title level={5} style={{marginTop: 0}}>
            Portfolio accounts
        </Typography.Title>
        <Flex vertical gap={"small"} style={{marginBottom: 16}}>
            {portfolios?.length > 0
                ? portfolios.map((portfolio) => {
                    return <AccountCard key={portfolio._id}
                                        account={portfolio}/>
                })
                : <Typography.Text type={"secondary"}>
                    Add client's portfolio accounts to
                    see portfolio analytics...
                </Typography.Text>
            }
        </Flex>
        <AddAccountLink type={type}/>
    </>
}


const ClientCard = () => {
    const {proposal, id, updateBaseClientInfo, updateClientInfo} = useHolisticoProposalContext();
    const [openQuestionnaire, setOpenQuestionnaire] = useState(false);
    const [form] = Form.useForm();
    const name = Form.useWatch("full_name", form);
    const nameDebounce = useDebounce(name, {wait: 500});
    const navigate = useNavigate();
    const {token} = useToken();

    useEffect(() => {
        form.setFieldsValue(proposal);
    }, [proposal])

    useUpdateEffect(() => {
        updateBaseClientInfo({full_name: nameDebounce}, false, false);
    }, [nameDebounce])

    const runQuestionnaireUpdate = () => {
        const values = form.getFieldsValue();
        const riskScore = getRiskScoreFromQuestionnaire(values);
        if (riskScore) {
            values["questionnaire"]["risk_profile"] = {"ability": riskScore};
        }
        updateClientInfo(values, false, false);
    };

    return <Card hoverable style={{
        cursor: "default",
        borderColor: "rgb(220, 220, 220)",
        overflow: "hidden",
        background: token.colorBgGrey,
    }}>
        <NestedCard background={"white"}>
            <CardHeader
                title="New client"
                icon={<UserAddOutlined/>}
                controls={<Flex gap={"small"} wrap={"wrap"}>
                    <ReportDownloadButton
                        strictPresets={['full_with_fixed_income', 'single']}
                        clientId={id}
                        reportClass="enrollment"
                        disabled={!proposal?.current_portfolios?.length > 0}
                    />
                    <Button
                        type={"primary"}
                        onClick={() => {
                            navigate(`/${Constants.RIA_URL}/proposals/${id}`)
                        }}
                    >
                        Go to proposal
                    </Button>
                </Flex>}
            />
            <Divider style={{margin: `0 0 ${token.marginXL}px 0`,}}/>
            <Form
                form={form}
                layout={"vertical"}
            >
                <Form.Item
                    name="full_name"
                    style={{marginBottom: token.marginSM}}
                >
                    <Input
                        addonBefore="Name"
                        placeholder={"Enter client name"}
                        style={{maxWidth: 500}}
                    />
                </Form.Item>
                <QuestionnaireDrawer
                    open={openQuestionnaire}
                    onClose={() => {
                        setOpenQuestionnaire(false);
                        runQuestionnaireUpdate();
                    }}
                />
            </Form>
            <Button
                type={"link"}
                style={{padding: 0, alignSelf: "flex-start"}}
                onClick={() => setOpenQuestionnaire(true)}
            >
                <EditOutlined/>
                Open questionnaire
            </Button>
        </NestedCard>
        <PortfolioAccountsPanel type={"cur"}/>
    </Card>
}


const Enrollment = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    if (!id) {
        createClient({}, (result, error) => {
            if (error) {
                message.error("Something went wrong while creating new client!");
                navigate(`/${Constants.RIA_URL}/proposals`);
            } else {
                navigate(`/${Constants.RIA_URL}/proposals/new/${result._id}`);
            }
        })
    }

    return <>{
        id && <HolisticoProposalContextProvider proposal_id={id}>
            <AiSidebarContainer proposalId={id} chatType={"proposal"}>
                <Flex vertical gap={"middle"}>
                    <ClientCard/>
                    <PortfolioPreview/>
                </Flex>
            </AiSidebarContainer>
        </HolisticoProposalContextProvider>
    }</>

}

export default Enrollment;

import React from "react";
import {Button, Drawer, FloatButton, theme, Typography} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {AiChat} from "@holistico/ai/chat/AiChatCard";
import MobileDrawer from "@components/retail/cards/MobileDrawer";


const {useToken} = theme;


const AiChatPanel = ({aiExpand, setAiExpand}) => {
    const {proposal} = useHolisticoProposalContext();
    const portfolios = proposal?.current_portfolios;
    const {token} = useToken();

    return <>{portfolios && portfolios.length > 0
        &&
        <>
            <FloatButton
                onClick={() => setAiExpand(true)}
                icon={<div style={{fontWeight: 500, fontSize: 16}}>AI</div>}
                type={"primary"}
                tooltip={"Open AI chat"}
                badge={{dot: true}}
                style={{
                    bottom: 8,
                    insetInlineEnd: 8
                }}
            />
            <MobileDrawer
                title={"AI chat"}
                open={aiExpand}
                onClose={() => setAiExpand(false)}
                closable={true}
                width={640}
                style={{background: token.colorBgGreyDark}}
            >
                <div style={{
                    marginLeft: -token.marginLG,
                    marginRight: -token.marginLG,
                }}>
                    <AiChat height={"calc(100vh - 200px)"}/>
                </div>
            </MobileDrawer>
        </>
    }</>
}


export default AiChatPanel;

import {
    Button,
    Col,
    ConfigProvider,
    Grid,
    Menu,
    Row,
    theme,
    Typography
} from "antd";
import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getHiddenHref} from "@global/Utils";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import ValueFormatter from "@global/ValueFormatter";
import {MenuOutlined} from "@ant-design/icons";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import Constants from "@global/Constants";


const {useBreakpoint} = Grid;
const {useToken} = theme;


const getMenuColumns = (proposal) => {
    const proposalId = proposal?._id;
    const urlBase = `/${Constants.RIA_URL}/proposals/${proposalId}/`;

    let qItem = {
        "label": "Questionnaire",
        "key": "client/general-info"
    }

    let bItem = {
        "label": "Behavioral quiz",
        "key": "client/behavioral-quiz"
    }

    let wItem = {
        "label": "Risk willingness",
        "key": "client/risk-tolerance"
    }

    if (proposal?.questionnaire?.risk_profile?.ability) {
        qItem['label'] = "Risk ability: " + proposal?.questionnaire?.risk_profile?.ability
    }

    if (proposal?.questionnaire?.risk_profile?.behavioral) {
        bItem['label'] = "Risk tolerance: " + proposal?.questionnaire?.risk_profile?.behavioral
    }

    if (proposal?.questionnaire?.risk_profile?.willingness) {
        wItem['label'] = "Risk willingness: " + proposal?.questionnaire?.risk_profile?.willingness
    }

    let cPort = {
        label: "No client portfolio",
        key: "portfolios/client-portfolio"
    }

    let individualViewLink = null

    if (proposal?.p_bucket_cur?.calculated_values?.market_value) {
        cPort['label'] = "Market value: " + ValueFormatter.compactCurrency(proposal?.p_bucket_cur?.calculated_values?.market_value)
        individualViewLink = {
            label: "Individual view",
            key: `/view/${proposalId}/portfolio`
        }
    }

    let pPort = {
        label: "No proposal made",
        key: "portfolios/proposed-portfolios"
    }

    if (proposal?.p_bucket_prop?.calculated_values?.market_value) {
        pPort['label'] = "Proposed portfolio"
    }

    let portfolioElements = [cPort, pPort]
    if (individualViewLink) {
        portfolioElements.push(individualViewLink)
    }

    let menuColumns = [
        {
            label: "Client",
            key: "client",
            children: [qItem, bItem, wItem]
        },
        {
            label: "Portfolio",
            key: "portfolio",
            children: portfolioElements
        },
        {
            label: "Fundamentals",
            key: "fundamentals",
            children: [{
                label: "Asset allocation",
                key: "fundamentals/asset-allocation"
            }, {
                label: "Equities",
                key: "fundamentals/equities"
            }, {
                label: "Fixed-income",
                key: "fundamentals/fixed-income"
            }]
        },
        {
            label: "Other",
            key: "other",
            children: [{
                label: "Retirement map",
                key: "other/retirement-map"
            }, {
                label: "Stress tests",
                key: "other/stress-tests"
            }, {
                label: "AI assistant",
                key: "ai/assistant"
            }]
        },
    ]


    for (let menuColumn of menuColumns) {
        for (let item of menuColumn["children"]) {
            if (item.key.startsWith("/")) {
                item.label = getHiddenHref(item.label, item.key, "", true)
            } else {
                item.label = getHiddenHref(item.label, item.key, urlBase, false)
            }
        }
    }

    return menuColumns
}


const getSelectedKeyFromPath = (path) => {
    let menuColumns = getMenuColumns()
    for (let menuColumn of menuColumns) {
        for (let item of menuColumn["children"]) {
            if (path.includes(item['key'])) {
                return item['key']
            }
        }
    }

    return null;
}


const ProposalMegaMenu = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);
    const location = useLocation();
    const selectedKey = getSelectedKeyFromPath(location.pathname);
    const navigate = useNavigate();
    const screens = useBreakpoint();
    const {token} = useToken();

    const handleClick = (e) => {
        if (!e.key.startsWith("/")) {
            console.log("navigate to", e.key)
            navigate({pathname: `${e.key}`, search: location.search})
        }
        setOpenDrawer(false);
    }

    return <>
        {screens.md
            ?
            <ConfigProvider
                theme={{
                    "token": {
                         // fontSize: token.fontSizeSM,
                    },
                    "components": {
                        "Menu": {
                            // itemSelectedBg: token.colorStats,
                            // itemSelectedBg: "black",
                            itemSelectedBg: token.colorRadioSelectedBg,
                            itemSelectedColor: "white",
                            itemHeight: 28,
                            borderRadiusLG: 60
                        }
                    }
                }}>
                <Row
                    className={"mega-menu"}
                    // style={{
                    //     marginLeft: -token.padding,
                    //     marginRight: -token.padding,
                    // }}
                >
                    {getMenuColumns(proposal).map((menuColumn) => {
                        return <Col span={6} key={menuColumn["key"]}>
                            <Typography.Title
                                level={5}
                                ellipsis
                                style={{
                                    paddingLeft: token.paddingMD,
                                    margin: 0,
                                    // marginBottom: token.marginSM,
                                    fontSize: token.fontSizeSM-1,
                                    textTransform: "uppercase",
                                    letterSpacing: 2,
                                    color: "black",
                                    fontWeight: 600,
                                }}
                            >
                                {menuColumn["label"]}
                            </Typography.Title>
                            {/*<Divider*/}
                            {/*    style={{margin: `${token.marginXXS}px 0`}}/>*/}
                            <Menu
                                size={'small'}
                                style={{
                                    background: "none",
                                    border: "none",
                                }}
                                items={menuColumn["children"]}
                                onClick={handleClick}
                                selectedKeys={[selectedKey]}
                            />
                        </Col>
                    })}
                </Row>
            </ConfigProvider>
            :
            <Button
                type={"text"}
                onClick={() => setOpenDrawer(true)}
            >
                <MenuOutlined/>
                Sections
            </Button>
        }
        <MobileDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            width={300}
            style={{background: "white"}}
        >
            <div style={{
                marginLeft: -token.paddingLG,
                marginRight: -token.paddingLG,
            }}>
                <Menu
                    mode="inline"
                    items={getMenuColumns(proposal)}
                    openKeys={['client', 'portfolio', 'fundamentals', 'other']}
                    onClick={handleClick}
                />
            </div>
        </MobileDrawer>
    </>
}

export default ProposalMegaMenu;

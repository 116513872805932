/* eslint-disable react/prop-types */
import React, {useRef} from 'react';
import {useSize} from "ahooks";
import {Flex, List, theme} from "antd";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";


const {useToken} = theme;


const ChartContainer = ({
                            legendDataSource,
                            legendRenderItem,
                            chartWidth,
                            emptyText = "No data",
                            empty = false,
                            maxWidth = 600,
                            children
                        }) => {
    const ref = useRef(null);
    const size = useSize(ref);
    const {token} = useToken();

    return <div ref={ref} className={"fade-in-200"}>
        {empty
            ?
            <NoDataPlaceholder description={emptyText}/>
            :
            <Flex
                gap={"middle"}
                align={"center"}
                justify={"space-around"}
                wrap={"wrap"}
                style={{maxWidth: maxWidth}}
            >
                {children
                    && <div style={chartWidth && size?.width < chartWidth
                        ? {marginLeft: "50%", transform: "translateX(-50%)"}
                        : (chartWidth ? {} : {width: "100%"})
                    }>
                        {children}
                    </div>
                }
                {legendDataSource
                    && <List
                        dataSource={legendDataSource}
                        renderItem={legendRenderItem}
                        style={{
                            flexGrow: 1,
                            marginLeft: -token.paddingXS,
                            marginRight: -token.paddingXS,
                        }}
                    />
                }
            </Flex>
        }
    </div>
}

export default ChartContainer;

import React, {useEffect, useRef, useState} from "react";
import {
    Typography,
    Button,
    Card,
    Row,
    Col,
    Divider,
    Flex,
    Form,
    InputNumber,
    Rate,
    theme, Tooltip, Segmented,
} from "antd";
import {
    CompassFilled,
    HeartOutlined,
} from "@ant-design/icons";
import ValueFormatter from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {
    getRiskScoreFromQuestionnaire
} from "@holistico/proposal/view/client/questionnaire/Questionnaire";
import {
    getQuizTotal, getRiskScoreByQuizScore
} from "@holistico/proposal/view/client/behavioral_quiz/BehavioralQuiz";
import BehavioralQuizForm
    from "@holistico/proposal/view/client/behavioral_quiz/BehavioralQuizForm";
import StatsCard, {
    StatsCardButton,
    StatsCardDescription
} from "@components/retail/cards/StatsCard";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import CardHeader from "@components/retail/cards/CardHeader";
import {ScreenerLink} from "@holistico/welcome/items/ScreenerCard";
import {useSize} from "ahooks";


const {useToken} = theme;


const QuestionnaireFormItems = ({}) => {
    return <Row gutter={[16, 0]}>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Birth year"
                name={["questionnaire", "birth_year"]}
                rules={[
                    {
                        // Without custom validator it works weird
                        warningOnly: true,
                        validator: (_, value) =>
                            value
                                ?
                                Promise.resolve()
                                :
                                Promise.reject(new Error("Required for the risk ability estimation")),
                    }
                ]}
            >
                <InputNumber placeholder={"Year"}/>
            </Form.Item>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Health status"
                name={["questionnaire", "hstatus"]}
                rules={[
                    {
                        // Without custom validator it works weird
                        warningOnly: true,
                        validator: (_, value) =>
                            value
                                ?
                                Promise.resolve()
                                :
                                Promise.reject(new Error("Required for the risk ability estimation")),
                    },
                ]}
            >
                <Rate
                    character={<HeartOutlined/>}
                    style={{fontSize: 24, color: '#F86767FF'}}
                    defaultValue={null}
                />
            </Form.Item>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Investment horizon"
                name={["questionnaire", "investment_time_horizon"]}
            >
                <Segmented
                    options={[
                        {label: "10 years or more", value: "long"},
                        {label: "6 - 9 years", value: "intermediate"},
                        {label: "5 years or less", value: "short"},
                    ]}
                    defaultValue={null}
                />
            </Form.Item>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Non-investment income"
                name={["questionnaire", "non_investment_income"]}
            >
                <Segmented
                    options={[
                        {label: "Above spending", value: "above_spending"},
                        {label: "Below spending", value: "below_spending"},
                    ]}
                    defaultValue={null}
                />
            </Form.Item>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Working status"
                name={["questionnaire", "wstatus"]}
            >
                <Segmented
                    options={[
                        {label: "Working", value: "working"},
                        {label: "Sabbatical", value: "not_working"},
                        {label: "Retired", value: "retired"},
                    ]}
                    defaultValue={null}
                />
            </Form.Item>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Marital status"
                name={["questionnaire", "mstatus"]}
            >
                 <Segmented
                    options={[
                        {label: "Married", value: "married"},
                        {label: "Single", value: "single"},
                        {label: "Divorced", value: "divorced"},
                    ]}
                    defaultValue={null}
                />
            </Form.Item>
        </Col>
    </Row>
}


const QuestionnaireForm = ({setOpen}) => {
    const {proposal, updateQuestionnaire} = useHolisticoProposalContext();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(proposal);
    }, [proposal])

    const onFinish = (values) => {
        console.log("Submit", values);
        const riskScore = getRiskScoreFromQuestionnaire(values);
        if (riskScore) {
            values["questionnaire"]["risk_profile"] = {"ability": riskScore};
        }
        setOpen(false);
        updateQuestionnaire(values["questionnaire"], true);
    };

    return <Form
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    >
        <Typography.Title level={4} style={{margin: 0}}>
            Risk ability questionnaire
        </Typography.Title>
        <Divider/>
        <QuestionnaireFormItems/>
        <Divider/>
        <Flex justify={"flex-start"} align={"center"} gap={"small"}>
            <Button htmlType="button" onClick={() => setOpen(false)}
                    style={{width: 180}}>
                Cancel
            </Button>
            <Button htmlType="submit" type={"primary"} style={{width: 180}}>
                Save
            </Button>
        </Flex>
    </Form>
}


const QuizForm = ({setOpen}) => {
    const { updateQuestionnaire } = useHolisticoProposalContext();
    const [saving, setSaving] = useState(false);

    const submitQuizResults = (values) => {
        if (!values) {
            return
        }
        const total = getQuizTotal(values["answers"])
        const riskScore = getRiskScoreByQuizScore(total)
        const questionnaire = {"behavioral_quiz": {...values, total: total}}
        if (riskScore) {
            questionnaire["risk_profile"] = {"behavioral": riskScore}
        }
        setSaving(true);
        updateQuestionnaire(questionnaire, true, true, () => {
            setSaving(false);
            if (riskScore) {
                setOpen(false);
            }
        })
    }

    return <>
        <Typography.Title level={4} style={{margin: 0}}>
            Risk tolerance quiz
        </Typography.Title>
        <Divider/>
        <BehavioralQuizForm onFinish={submitQuizResults} saving={saving}/>
    </>
}


export const QuestionnaireDrawer = ({open, setOpen}) => {
    return <MobileDrawer open={open} onClose={() => setOpen(false)}>
        <Card>
            <QuestionnaireForm setOpen={setOpen}/>
        </Card>
    </MobileDrawer>
}


export const QuizDrawer = ({open, setOpen}) => {
    const {token} = useToken();

    return <MobileDrawer open={open} onClose={() => setOpen(false)}>
        <Card>
            <QuizForm setOpen={setOpen}/>
        </Card>
    </MobileDrawer>
}


const RiskProfileCard = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const [openQuestionnaire, setOpenQuestionnaire] = useState(false);
    const [openQuiz, setOpenQuiz] = useState(false);
    const {token} = useToken();
    const ref = useRef(null);
    const size = useSize(ref);
    const vertical = size?.width < 600;

    const portfolioScore = proposal?.p_bucket_cur?.calculated_values?.risk?.risk_score;
    const abilityScore = proposal?.questionnaire?.risk_profile?.ability;
    const toleranceScore = proposal?.questionnaire?.risk_profile?.behavioral;

    return <Card hoverable ref={ref} style={{
        borderColor: "rgb(220, 220, 220)",
        cursor: "default"
    }}>
        <CardHeader title="Risk profile" icon={<CompassFilled />}/>
        <Flex gap="small" align={"stretch"} vertical={vertical}>
            <StatsCard
                title="portfolio risk score"
                value={portfolioScore ? ValueFormatter.int_number(portfolioScore) : null}
                style={vertical ? null : {maxWidth: "35%"}}
            >
                <StatsCardDescription>
                    Measures your portfolio risk level — from 0 (no risk)
                    to 99 (extremely high risk).
                </StatsCardDescription>
            </StatsCard>
            <StatsCard
                title={"risk ability score"}
                value={abilityScore ? ValueFormatter.int_number(abilityScore) : null}
                style={vertical ? null : {maxWidth: "35%"}}
            >
                <StatsCardDescription>
                    Reflects your financial ability to withstand declines in the
                    portfolio value.
                    Based on the investment profile questionnaire.
                </StatsCardDescription>
                <StatsCardButton onClick={() => setOpenQuestionnaire(true)}>
                    {abilityScore ? "Open questionnaire" : "Fill questionnaire"}
                </StatsCardButton>
                {abilityScore &&
                    <Tooltip
                        title={"Go to model portfolios with suitable risk score"}
                    >
                        <ScreenerLink
                            title={"Suitable models"}
                            href={`screener/portfolio?risk_score.from=${abilityScore - 10}&risk_score.to=${abilityScore}&order_by=risk_score+desc`}
                            style={{marginTop: token.paddingXS}}
                        />
                    </Tooltip>
                }
            </StatsCard>
            <StatsCard
                title="risk tolerance score"
                value={toleranceScore ? ValueFormatter.int_number(toleranceScore) : null}
                style={vertical ? null : {maxWidth: "35%"}}
            >
                <StatsCardDescription>
                    Measures your subconsciousness tolerance to risk.
                    Based on the behavioral quiz.
                </StatsCardDescription>
                <StatsCardButton onClick={() => setOpenQuiz(true)}>
                    {toleranceScore ? "Open quiz" : "Take quiz"}
                </StatsCardButton>
                {toleranceScore &&
                    <Tooltip
                        title={"Go to model portfolios with suitable risk score"}
                    >
                        <ScreenerLink
                            title={"Suitable models"}
                            href={`screener/portfolio?risk_score.from=${toleranceScore - 10}&risk_score.to=${toleranceScore}&order_by=risk_score+desc`}
                            style={{marginTop: token.paddingXS}}
                        />
                    </Tooltip>
                }
            </StatsCard>
        </Flex>
        <QuestionnaireDrawer
            open={openQuestionnaire}
            setOpen={setOpenQuestionnaire}
        />
        <QuizDrawer
            open={openQuiz}
            setOpen={setOpenQuiz}
        />
    </Card>
}

export default RiskProfileCard;

import React, {useEffect, useRef, useState} from "react";
import { useSize } from 'ahooks';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {Checkbox, Flex, Space, theme} from "antd";
import {HolderOutlined} from "@ant-design/icons";
// https://codesandbox.io/s/react-beautiful-dnd-multi-column-rv76j?file=/src/index.js


const {useToken} = theme;


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};


function Widget({widget, index}) {
    const [hover, setHover] = useState(false);
    const {token} = useToken();


    return (
        <Draggable draggableId={widget.key} index={index}>
            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Flex
                        gap={"small"}
                        align={"center"}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        style={{
                            padding: token.marginXS,
                            borderRadius: token.borderRadius,
                            background: hover ? "rgba(0, 0, 0, 0.03)" : "none",
                        }}
                    >
                        <HolderOutlined/>
                        <Checkbox value={widget.key} style={{
                            lineHeight: 1.2,
                            fontSize: token.fontSizeSM,
                        }}>
                            {widget.title}
                        </Checkbox>
                    </Flex>

                </div>
            )}
        </Draggable>
    );
}

const WidgetList = React.memo(function WidgetList({widgets}) {
    return <>{
        widgets?.length > 0 &&
            widgets.map((widget, index) => (
                <Widget widget={widget} index={index} key={widget.key}/>
            ))
    }</>
});


function Column({ droppableId, widgets }) {
    return (
        <Droppable droppableId={droppableId}  className={'report-sections-column'}>
            {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <WidgetList widgets={widgets} />
                  {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
}


const MultiColumnDnD = ({sections, selected, setSelected, setOrder}) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        let sectionsByColumn = null
        if (sections?.length > 0) {
            const n = Math.ceil(sections.length / 4)
            sectionsByColumn = [...Array(4)].map((_, i) => sections.slice(i * n, (i + 1) * n))
        }
        const stateInitial = sectionsByColumn
            ?
            {
               widgets: sectionsByColumn.reduce((a, item, index) => {
                const column = "column-" + (index + 1)
                return {...a, [column]: item}
              }, {})
            }
            :
            null
        setState(stateInitial)
    }, [sections])

    const ref = useRef(null);
    const size = useSize(ref);

    let columnCount = Math.floor(size?.width / 300) ?? 4
    if (columnCount > 4) {
        columnCount = 4
    }
    if (columnCount < 1) {
        columnCount = 1
    }

    function onDragEnd(result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        let updatedState = null
        if (destination.droppableId === source.droppableId) {
            if (destination.index === source.index) {
                return;
            }
            const widgets = reorder(
                state.widgets[source.droppableId],
                source.index,
                destination.index
            );
            updatedState = {
                widgets: {
                  ...state.widgets,
                  [source.droppableId]: widgets
                }
            };
        } else {
            const startColumn = [...state.widgets[source.droppableId]];
            const finishColumn = [...state.widgets[destination.droppableId]];
            const [removed] = startColumn.splice(source.index, 1);
            finishColumn.splice(destination.index, 0, removed);
            updatedState = {
                widgets: {
                    ...state.widgets,
                    [source.droppableId]: startColumn,
                    [destination.droppableId]: finishColumn
                }
            };
        }
        setState(updatedState);
        setOrder(Object.values(updatedState["widgets"]).flat().map(section => section.key))
    }

    return <>
        {state &&
            <Checkbox.Group
                ref={ref}
                style={{width: "100%"}}
                value={selected}
                onChange={values => setSelected(values)}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <div style={{
                        display: columnCount > 2 ? "flex" : "block",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: columnCount > 3 ? "flex" : "block",
                            width: columnCount > 3 ? "50%" : "100%",
                        }}>
                            <div style={{width: columnCount > 3 ? "50%" : "100%"}}>
                                <Column widgets={state.widgets["column-1"]} droppableId="column-1"/>
                            </div>
                            <div style={{width: columnCount > 3 ? "50%" : "100%"}}>
                                <Column widgets={state.widgets["column-2"]} droppableId="column-2"/>
                            </div>
                        </div>
                        <div style={{
                              display: columnCount > 3 ? "flex" : "block",
                              width: columnCount > 3 ? "50%" : "100%",
                        }}>
                            <div style={{width: columnCount > 3 ? "50%" : "100%"}}>
                                <Column widgets={state.widgets["column-3"]} droppableId="column-3"/>
                            </div>
                            <div style={{width: columnCount > 3 ? "50%" : "100%"}}>
                                <Column widgets={state.widgets["column-4"]} droppableId="column-4"/>
                            </div>
                        </div>
                    </div>
                </DragDropContext>
            </Checkbox.Group>
        }
    </>
};


export default MultiColumnDnD;

import React, {useEffect, useRef, useState} from "react";
import {useSize} from "ahooks";
import {Carousel, message, Spin} from "antd";
import {getModelsOnly} from "@API/portfolio";
import {clientPortfolioClone, deletePortfolio} from "@API/clientPortfolio";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import ModelCard from "@holistico/proposal/view/client/ModelCard";
import SimpleProgressModal from "@holistico/aux/SimpleProgressModal";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import "./ModelsCarousel.less"


const findModelIndex = (risk_score, models) => {
    if (!models || models.length === 0) {
        return null
    }

    let index = 0;
    for (let model of models) {
        if (index >= models.length - 3) {
            return index
        }
        let model_risk_score = model?.calculated_values?.risk?.risk_score
        let next_model_risk_score = models[index + 1]?.calculated_values?.risk?.risk_score ?? 100
        if (risk_score <= model_risk_score) {
            return index
        }
        if (model_risk_score <= risk_score && next_model_risk_score >= risk_score) {
            return index
        }
        ++index;
    }
    return 0
}


const ModelsCarousel = ({
                            riskScore,
                            active = true,
                            inactiveMessage = null,
                            ...props
                        }) => {
    const {proposal, fetchProposal} = useHolisticoProposalContext()
    const [loading, setLoading] = useState([])
    const [models, setModels] = useState([])
    const [addingInProgress, setAddingInProgress] = useState(false);
    const [removingInProgress, setRemovingInProgress] = useState(false);
    const carouselRef = useRef();
    const divRef = useRef();
    const size = useSize(divRef);

    let slidesToShow;
    if (size?.width < 500) {
        slidesToShow = 1;
    } else if (size?.width < 800) {
        slidesToShow = 2;
    } else if (size?.width < 1200) {
        slidesToShow = 3;
    } else {
        slidesToShow = 4;
    }

    const modelsInProposal = proposal.proposed_portfolios?.filter((p) => {
        return !!p?.auxiliary_data?.cloned_from
    }).map((p) => {
        return p?.auxiliary_data?.cloned_from
    })

    const addModelToProposal = (id) => {
        setAddingInProgress(true)
        const mkval = proposal.p_bucket_cur?.calculated_values?.market_value ?? null
        clientPortfolioClone(proposal._id, id, "prop", false, mkval, (result, error) => {
            if (!error) {
                message.success('Portfolio was added successfully!');
                fetchProposal()
            } else {
                result && message.error(result.data.message);
            }
            setAddingInProgress(false)
        })
    }


    const removeModelFromProposal = (id) => {
        setRemovingInProgress(true)
        deletePortfolio(proposal._id, "prop", id, (result, error) => {
            if (!error) {
                message.success("Portfolio was deleted successfully");
                fetchProposal();
            } else {
                console.log(result)
                result && message.error(result.data.message);
            }
            setRemovingInProgress(false);
        })
    }

    useEffect(() => {
        const handler = setTimeout(() => {
            if ((riskScore || riskScore === 0) && models.length > 0 && carouselRef.current) {
                const index = findModelIndex(riskScore, models)
                carouselRef.current.goTo(index)
            }
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [riskScore, models])


    useEffect(() => {
        setLoading(true)
        getModelsOnly(true, null, (data, error = false) => {
            if (error) {
                message.error("Some problems with loading models")
            } else {
                setModels(data.sort((a, b) => {
                    let a_risk = a?.calculated_values?.risk?.risk_score ?? 100
                    let b_risk = b?.calculated_values?.risk?.risk_score ?? 100
                    if (a_risk === b_risk) {
                        return a.name.localeCompare(b.name)
                    } else {
                        return a_risk - b_risk
                    }
                }))
            }
            setLoading(false)
        })
    }, []);


    return <div ref={divRef} {...props}>
        <SimpleProgressModal
            description={"Adding model to proposal..."}
            open={addingInProgress}
        />
        <SimpleProgressModal
            description={"Removing model from proposal..."}
            open={removingInProgress}
        />
        {active
            ?
            <Spin
                spinning={loading}
                tip="Loading models..."
                size={"large"}
                style={{height: 200}}
            >
                <Carousel
                    dots={false}
                    slidesToShow={slidesToShow}
                    arrows={true}
                    ref={carouselRef}
                    className={"models-carousel"}
                    infinite={false}
                >
                    {models
                        && models.map((model) => {
                            return <ModelCard
                                key={model._id}
                                model={model}
                                onAdd={addModelToProposal}
                                onRemove={removeModelFromProposal}
                                idInProposal={modelsInProposal.filter((x) => x === model._id)}
                            />
                        })
                    }
                </Carousel>
            </Spin>
            :
            <NoDataPlaceholder description={inactiveMessage}/>
        }
    </div>
}

export default ModelsCarousel;
